import React, { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../../Contexts/User";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import Tabs from "./Tabs";
import { useTranslation } from "../../common/models/Dictionary";
import { capitalize } from "lodash";
import { follow, Leader, unfollow } from "../../Contexts/CoinsContext";
import UserCard from "../Users/UserCard";
import { toFollow } from "../../common/models/User";
import { texts } from "../LoginComponent/texts";
import AppContext from "Contexts/AppContext";

export type Follower = {
  username: string;
  id: string;
};

const getLeaderUsersByIds = httpsCallable<{}, Leader[]>(
  functions,
  "getLeaderUsersByIds"
);

export const getUsers = ({
  users,
  setUsers,
  setIsLoading,
}: {
  users?: string[];
  setIsLoading?: any;
  setUsers: (newUsers: Leader[]) => void;
}) => {
  try {
    // setIsLoading(true)
    getLeaderUsersByIds({ userIds: users }).then((u) => {
      console.log(u?.data, "checkdata")
      setUsers(u?.data);
      if (setIsLoading) {
        setIsLoading(false)
      }
    });
  } catch (e) {
    setUsers([] as Leader[]);
    if (setIsLoading) {
      setIsLoading(false)
    }
  }
};

const Follow = () => {
  const { user, userInfo } = useContext(UserContext);
  const translate = useTranslation();
  const [leaders, setLeaders] = useState<Leader[] | undefined>();
  const [subscribers, setSubscribers] = useState<Leader[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userLeadersData, userSubscribersData } = useContext(AppContext);

  // useEffect(() => {
  //   if (userInfo?.leader && userInfo?.leader?.length > 0) {
  //     getUsers({ users: userInfo?.leader, setUsers: setLeaders,setIsLoading })
  //     setIsLoading(true)
  //   }else{
  //     setLeaders([]);
  //   }
  // }, [userInfo?.leader]);
  // useEffect(() => {
  //   if (userInfo?.subscribers && userInfo?.subscribers?.length > 0) {
  //     getUsers({ users: userInfo?.subscribers, setUsers: setSubscribers,setIsLoading });
  //     setIsLoading(true)
  //   }else{
  //     setSubscribers([])
  //   }
  // }, [userInfo?.subscribers]);

console.log(isLoading,"loadingdata")

  return (
    <Tabs
      defaultActiveKey="following"
      id="profile-follow"
      onSelect={() => { }}
      tabs={[
        {
          eventKey: "following",
          title: capitalize(translate(`${texts.Following}`)),
          pane: (
            <div>
              {isLoading && <div style={{
                position: 'fixed',
                height: '100%',
                // border: "2px solid red",
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                // top: '0px',
                backgroundColor: "rgba(0,0,0,0.4)",
                right: '0px',
                bottom: '0px',
                zIndex: '9999',
                overflow: 'hidden',
                width: '100%',
                alignItems: 'center',

              }}>
                <span className="loading position-fixed" style={{
                  color: "white", zIndex: "2220px", fontSize: '1.5em',
                  top: `${window.scrollY + 300}px`,

                  // marginTop: `${window.screen.width > 767 ? "50px" : "240px"}`
                }}>
                  {texts?.waitForIt}
                </span>
              </div>}
              {userLeadersData && userLeadersData.length > 0 ? userLeadersData.map((u: any, i: number) => {
                return (
                  <div className="mb-2" style={{ maxWidth: '85vw', margin: 'auto' }}>

                    <UserCard
                      key={i}
                      leader={u}
                      checked={!!userInfo?.leader?.includes(u?.userId)}
                      setChecked={() => {
                        setIsLoading(true)
                        user &&
                        follow(
                          u,
                          user,
                          toFollow(userInfo?.leader || [], u?.userId),
                          setIsLoading
                        )                        
                      }
                      }
                    />
                  </div>
                );
              }) : !isLoading && userLeadersData?.length === 0 ? (
                <div className="text-white" style={{ display: "flex", justifyContent: "center", justifyItems: "center", textTransform: "uppercase" }}>No record found</div>
              ) : null}
            </div>


          ),
        },
        {
          eventKey: "followers",
          title: capitalize(translate(`${texts.Followers}`)),
          pane: (
            <>
              {isLoading && <div style={{
                position: 'fixed',
                height: '100%',
                // border: "2px solid red",
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                // top: '0px',
                backgroundColor: "rgba(0,0,0,0.4)",
                right: '0px',
                bottom: '0px',
                zIndex: '9999',
                overflow: 'hidden',
                width: '100%',
                alignItems: 'center',

              }}>
                <span className="loading position-fixed" style={{
                  color: "white", zIndex: "2220px", fontSize: '1.5em',
                  top: `${window.scrollY + 300}px`,

                  // marginTop: `${window.screen.width > 767 ? "50px" : "240px"}`
                }}>
                  {texts?.waitForIt}
                </span>
              </div>}
              
              {userSubscribersData && userSubscribersData.length > 0 ? userSubscribersData.map((s: any, i: number) => {
                return (
                  <div className="mb-2" style={{ maxWidth: '85vw', margin: 'auto' }}>
                    <UserCard
                      key={i}
                      leader={s}
                      checked={!!userInfo?.subscribers?.includes(s?.userId)}
                      setChecked={() =>                                                                      
                      {
                        setIsLoading(true)
                        user &&
                        unfollow(
                          s,
                          user,
                          toFollow(userInfo?.subscribers || [], s?.userId),
                          setIsLoading
                          )
                      }
                        
                      }
                    />
                  </div>
                );
              }) : !isLoading && userSubscribersData.length === 0 ? (
                  <div className="text-white" style={{ display: "flex", justifyContent: "center", justifyItems: "center", textTransform: "uppercase" }}>No record found</div>
              ) : null}
            </>
          ),
        },
      ]}


    />


  );
};

export default Follow;
