import AppContext from 'Contexts/AppContext';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Other } from "Pages/SingleCoin";
import { VoteContext } from 'Contexts/VoteProvider';
import UserContext from 'Contexts/User';
import { CurrentCMPContext, CurrentCMPDispatchContext } from 'Contexts/CurrentCMP';
import axios from 'axios';
import Countdown from 'react-countdown';
import { httpsCallable } from 'firebase/functions';
import { Buttons } from './Atoms/Button/Button';
import { functions } from "../firebase";
import Swal from 'sweetalert2';
import WaitForResponse from './Profile/CardTrade/WaitForResponse';

const Complete100CMPModal: React.FC<{
    setCurrentCMP: React.Dispatch<React.SetStateAction<number>>,
    showComplete100CMP: boolean,
    setShowComplete100CMP: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setCurrentCMP, showComplete100CMP, setShowComplete100CMP }) => {
    const { setShowBack, upgraeShowOpen, setUpgraeShowOpen } = useContext(AppContext);
    const navigate = useNavigate();
    const [PaxToUserOnUpdateAddress, setPaxToUserOnUpdateAddress] = useState<any>();
    const { userInfo } = useContext(UserContext);
    const [isLoadingPax, setIsLoadingPax] = useState(false);
    const [upgraeShow, setUpgraeShow] = React.useState(false);

    useEffect(() => {
        if (window.location.pathname == "/profile/mine") {
            setShowComplete100CMP(false)
            setUpgraeShow(false)
        }
    }, [window.location.pathname])

    useEffect(() => {
        if (showComplete100CMP) {
            setUpgraeShowOpen(false)
        }
    }, [showComplete100CMP])

    // useEffect(() => {
    //     if (showComplete100CMP == false && PaxToUserOnUpdateAddress == null) {
    //         setIsLoadingPax(true);
    //     }else {
    //         setIsLoadingPax(false);
    //     }
    // }, [PaxToUserOnUpdateAddress, showComplete100CMP])

    const skipHandler = async () => {
        const mintPaxToAdminOnGiveUp = httpsCallable(
            functions,
            "mintPaxToAdminOnGiveUp"
        );
        const data = {
                userId: userInfo?.uid,
        };
        mintPaxToAdminOnGiveUp(data)
            .then((res) => {
                console.log(res.data, "mintPaxToAdminOnGiveUp");
            })
            .catch((error) => {
                console.log(error, "mintPaxToAdminOnGiveUp");
            });
    }

    const sortDataByTimestamp = (data: any) => {
        return data.sort((a: any, b: any) => {            
                if (a?.timestamp?._seconds !== b?.timestamp?._seconds) {
                    return b?.timestamp?._seconds - a?.timestamp?._seconds;
                }
                return b?.timestamp?._nanoseconds - a?.timestamp?._nanoseconds;            
        });
    };

    const ShowPendingAmount = () => {
        axios.post("payment/getAllPendingPaxByUserId", {              
                userId: userInfo?.uid            
        }).then((res: any) => {
            if (res?.data?.data?.result) {
                const sortedData = sortDataByTimestamp(res?.data?.data?.result?.paxList);
                const timestamp = sortedData[0];
                    const date = new Date(timestamp.timestamp._seconds * 1000);
                    const endTime = date.getTime() + (24 * 60 * 60 * 1000);
                    // const endTime = date.getTime() + (5 * 61 * 1000);
                    setPaxToUserOnUpdateAddress({
                    ...timestamp,
                    formattedDate: date.toLocaleString(),
                    endTime: endTime,
                    pendingPaxValue: res?.data?.data?.result?.pendingPaxTotal
                });
            }
        }).catch((err: any) => {
            console.log(err, "resultdata")
        })
    }

    useEffect(() => {
        if (!userInfo?.isUserUpgraded && userInfo?.uid) {
            ShowPendingAmount();
        }        
    }, [userInfo?.uid, !userInfo?.isUserUpgraded,showComplete100CMP])

    const handleUpgraeClose = () => {
        if (!userInfo?.isUserUpgraded) {
            setUpgraeShow(true);
        } else {
            setUpgraeShow(false)
        }
    };

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });

    return (
        <>

            <Modal show={showComplete100CMP} onHide={() => {
                setShowComplete100CMP(false);
                handleUpgraeClose()
                setCurrentCMP(0);
            }}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn-close " aria-label="Close" onClick={() => {
                        setShowComplete100CMP(false)   
                        handleUpgraeClose()
                        // setUpgraeShow(!userInfo?.isUserUpgraded && userInfo?.uid ? true : false)
                    }}></button>
                </div>
                <Modal.Body>
                    <p className="text-center">Congratulations 🎉</p>
                    <p className="text-center"> You've reached your  goal! </p>
                    <div className='py-2  d-flex  justify-content-center'>
                        <span style={{ textDecoration: 'none', cursor: 'pointer' }}
                            onClick={() => {
                                setShowComplete100CMP(false)
                                navigate('/profile/mine');
                                setShowBack(true);
                            }}
                        >
                            <Other>{("CHECK IT OUT")}</Other>
                        </span>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                show={upgraeShow}
                onHide={() => {
                    setUpgraeShow(false);
                    setUpgraeShowOpen(true);
                 }
                    // handleUpgraeClose
                }
                backdrop="static"
                contentClassName=""
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="d-flex justify-content-end" style={{ zIndex: 100 }}>
                    <button type="button" className="btn-close " aria-label="Close" onClick={() => {
                        // handleUpgraeClose();
                        setUpgraeShow(false);
                        // setUpgraeShow(false);
                        setShowComplete100CMP(false);
                        setUpgraeShowOpen(true);
                    }
                    }></button>
                </div>
                {isLoadingPax && (
            <>
              <WaitForResponse />
            </>
          )}
                <Modal.Body className="d-flex  justify-content-center align-items-center">
                    <div
                        className="py-2 d-flex flex-column  justify-content-center align-items-center"
                        style={{ zIndex: "101" }}
                    >
                        <strong
                            className="py-2"
                            style={{
                                fontSize: "20px",
                                textAlign: "center",
                                fontWeight: "bold",
                            }}
                        >
                            Secure Your PAX Tokens Before Time Runs Out!
                        </strong>
                            {PaxToUserOnUpdateAddress && <Countdown
                                date={PaxToUserOnUpdateAddress?.endTime}
                                renderer={({ hours, minutes, seconds, completed }) => {
                                    if (completed) {
                                        setUpgraeShow(false)
                                        setUpgraeShowOpen(true);
                                        return null;
                                    }
                                    return (
                                        <p style={{ textAlign: "center", fontSize: "20px" }}>
                                            {/* You have received {PaxToUserOnUpdateAddress?.currentPaxValue} PAX. Upgrade your account to claim it : */}
                                            You’ve minted PAX tokens, but without the MVM, they'll go to the treasury. Get your MVM now and collect your rewards!
                                        <br/>
                                            {hours < 1 ? null : `${hours}:`}
                                            {minutes < 10 ? `0${minutes}` : minutes}
                                            :{seconds < 10 ? `0${seconds}` : seconds}
                                        </p>
                                    );
                                }}
                            />}
                    </div>
                </Modal.Body>
                <div
                    className="d-flex justify-content-center pb-3 flex-column align-items-center "
                    style={{ zIndex: "101" }}
                >
                    <Buttons.Primary
                        className="mx-2"
                        style={{
                            width: "180px",
                            background: "#ff9700",
                            color: "black",
                        }}
                        onClick={async () => {
                            // handleUpgraeClose();
                            navigate("/upgrade");
                            setUpgraeShow(false);
                            setUpgraeShowOpen(true);
                        }}
                    >
                        {" "}
                        Unlock MVM
                    </Buttons.Primary>
                    <div
                        style={{
                            cursor: "pointer",
                            fontSize: "13px",
                            marginTop: 10,
                        }}
                        onClick={async () => {
                            // handleUpgraeClose();
                            setUpgraeShow(false);
                            setUpgraeShowOpen(true);
                            swalWithBootstrapButtons.fire({
                                title: "Give up PAX",
                                text: "Are you sure you want to give up pax ?",
                                icon: "warning",
                                showConfirmButton: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                showCancelButton: true,
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'No',
                                customClass: {
                                    actions: 'swal2-actions',
                                    confirmButton: 'btn btn-success swal2-confirm',
                                    cancelButton: 'btn btn-danger swal2-cancel',
                                }
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    skipHandler(); 
                                }
                                return result; 
                            });
                        }}
                    >
                        <p>Skip</p>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default React.memo(Complete100CMPModal);