import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../Contexts/User";
import Notification from "./Notification";
import {useTranslation} from "../../common/models/Dictionary";
import styled from "styled-components";
import {Container} from "react-bootstrap";
import { NotificationWait } from "../../common/utils/SoundClick";
import { getAllNotifications } from "common/utils/helper";
import AppContext from "Contexts/AppContext";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";

const P = styled.p`
  text-align: center;
  padding: 10px;

  &:first-letter {
    text-transform: capitalize;
  }
`
const Notifications = () => {
  const { notifications, setNotifications, userInfo } = useContext(UserContext)
  const {setNotificationCount} = useContext(AppContext)
  
  // const [dataLodaing, setDataLodaing] = useState(false)
  const [limitValue, setLimitValue] = useState<number>(10)
  
  // useEffect(() => {
    
  //   if (notifications.length) {
  //   NotificationWait()
  // }
 
  // }, [notifications])
  const updateNotificationSeenForCardTrade = httpsCallable(functions, "updateNotificationSeenForCardTrade")  
  const getMoreDataLoda = (limitNumber:number) => {    
    if (userInfo?.uid) {      
      getAllNotifications(userInfo?.uid, setNotifications, limitNumber)
    }
  }
  useEffect(() => {
    if (userInfo?.uid) {      
      NotificationSeen()    
    }
  }, [userInfo?.uid])
  
  const NotificationSeen = () => {
    const data = {
      userId: userInfo?.uid,      
    }    
    updateNotificationSeenForCardTrade(data).then((result) => {
      // setNotificationCount(0)
      console.log(result)
    }).catch((err) => {
      console.log(err)
    });
  }

  const translate = useTranslation()
  return (
    <Container className="py-4">
      {!notifications.length && <P className="text-white">{translate("you have no notifications")}</P>}
      {
        notifications.sort((a:any, b:any) => b.time - a.time)
        .map((notification, index) => {
          const date = (notification.time && notification.time.toDate()) || undefined;
          // const dataTime = new Date(date).toString()
          return <Notification date={date} title={notification.message.title} body={notification.message.body} key={index} />;
        })}
      {limitValue == notifications?.length && <>
        <div
        className="text-center"
        >
          <p
            
            style={{
              color: "#6352e8",              
              cursor: "pointer",
              fontSize: "15px",
              padding: "10px",
            }}
            // className={`${dataLodaing ?"loading" :""}`}
            onClick={() => {
              getMoreDataLoda(limitValue + 10)
              setLimitValue(limitValue + 10)
              // setDataLodaing(true)
            }}
          >
            {/* {!dataLodaing ? "View more" : "Wait for it..."} */}
            View more
          </p>
        </div>
      </>}
    </Container>

  );
};

export default Notifications;
