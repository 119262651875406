import { texts } from 'Components/LoginComponent/texts'
import React from 'react'

function WaitForResponse() {
  return (
    <>      
        <div
          style={{
            position: "fixed",
            height: "100%",
            // border: "2px solid red",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            // top: '0px',
            backgroundColor: "rgba(0,0,0,0.4)",
            right: "0px",
            bottom: "0px",
            zIndex: 9999,
            overflow: "hidden",
            width: "100%",
            alignItems: "center",
          }}
        >
          <span
            className="loading position-fixed"
            style={{
              color: "white",
              zIndex: "2220px",
              fontSize: "1.5em",
              top: `${window.scrollY + 300}px`,

              // marginTop: `${window.screen.width > 767 ? "50px" : "240px"}`
            }}
          >
            {texts?.waitForIt}
          </span>
        </div>
      
    </>
  )
}

export default WaitForResponse