import React, { useContext, useEffect, useState } from 'react'
import "./UpgradePage.css";
import UGBG from "../../../assets/images/UGBG.png";
import UGBGM from "../../../assets/images/UGBGM.png";
import DesktopScree from '../../../assets/images/DesktopScree.png';
import manyCoinImage from '../../../assets/images/many-coin-image.png';
import UpgradeCard from '../../../assets/images/UpgradeCard.png';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import UserContext from 'Contexts/User';
import { divide } from 'lodash';
import FullcircleProgressbar from '../FullcircleProgressbar';
import { useNavigate } from "react-router-dom";
import { showToast } from 'App';
import { ToastType } from 'Contexts/Notification';
import styled from 'styled-components';
import ShareBlue from "../../../assets/svg/ShareBlue.svg"
import ShareGold from "../../../assets/svg/ShareGold.svg"
import whatsApp from "../../../assets/images/whatsapp.svg"
import facebook from "../../../assets/images/facebook.svg"
import XTwitter from "../../../assets/images/x-twitter.svg"
import copy from "copy-to-clipboard";
import classes from "../../../Pages/Ambassador/Ambassador.module.css"
import AppContext from 'Contexts/AppContext';
import axios from 'axios';

const ButttonDiv = styled.div`
width:200px;
border:3px solid white;
 display: flex;
justify-content: center;
border-radius:50px;
background: linear-gradient(180deg, rgba(82,99,184,1) 0%, rgba(178,102,245,1) 100%);
  animation: zoom-in-zoom-out 1s infinite ;
transition: background 1s;

@keyframes zoom-in-zoom-out {
  0% {
    background: linear-gradient(180deg, rgba(82,99,184,1) 0%, rgba(178,102,245,1) 100%);
    color: #B869FC;
  }
  100% {
   background: linear-gradient(180deg, rgba(212,176,92,1) 0%, rgba(243,236,60,1) 100%);
   color:#DAA636;
  }  
}

  button {
    background:white;
    border:1px solid white;
    border-radius:50px;
    padding:5px;    
    margin:7px 0px;
    font-size:20px;
    color: red;
    width:180px;
    color: #B869FC;
font-family:"Lilita One";
font-family: Rounded Mplus 1c;
font-size: 20px;
transition: color 1s;

 & img{
  width:20px;
  margin-left:10px;
  color:red;
 }

animation: colorText 1s infinite ;

    @keyframes colorText {
  0% {    
    color: #B869FC;
  }
  100% {
   
   color:#DAA636;
  }  
}

  }


`;

function UpgradePage() {
  const [upgradeSetting, setUpgradeSetting] = useState([])
  const { user, userInfo } = useContext(UserContext);
  const [showShare, setShowShare] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const referralUrl = `${document.location.protocol}//${document.location.host}/?refer=${userInfo?.userName}`;
  const url = referralUrl
  const shareText = `Hey,%0ajoin me on Coin Parliament and earn rewards for your opinion!%0aLet's vote together!`
  const { setLogin} = useContext(AppContext)
  const imageSources = [
    ShareGold, ShareBlue,
    // Add more image sources as needed
  ];

  useEffect(() => {
    const fetchUpgradeSettingData = async () => {
      try {
        // Access the 'uservalue' document in the 'user' collection
        const docRef = doc(db, 'settings', 'upgradeSettings');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // Retrieve the 'userData' field
          setUpgradeSetting(docSnap.data().PRICE_OPTIONS);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUpgradeSettingData();
  }, [userInfo?.uid]);



  const [dailyVotes, setDailyVotes] = useState(100);
  const [weeklyReferrals, setWeeklyReferrals] = useState(50);
  const [earnings, setEarnings] = useState({
    dailyPAX: 0,
    dailyVTECoins: 0,
    dailyCollectibles: 0,
    weeklyPAX: 0,
    weeklyVTECoins: 0,
    weeklyCollectibles: 0,
    monthlyPAX: 0,
    monthlyVTECoins: 0,
    monthlyCollectibles: 0,
  });

  const calculateEarnings = () => {
    const votesPer100 = 100;
    const paxPer100Votes = 100;
    const vteCoinsPer100Votes = 50;
    const collectibleCardPer100Votes = 1;

    // Daily Calculations
    const dailyPAX = Math.floor(dailyVotes / votesPer100) * paxPer100Votes;
    const dailyVTECoins = Math.floor(dailyVotes / votesPer100) * vteCoinsPer100Votes;
    const dailyCollectibles = Math.floor(dailyVotes / votesPer100) * collectibleCardPer100Votes;

    // Weekly Calculations
    const weeklyVotes = dailyVotes * 7 + weeklyReferrals * dailyVotes; // Including referral impact
    const weeklyPAX = Math.floor(weeklyVotes / votesPer100) * paxPer100Votes;
    const weeklyVTECoins = Math.floor(weeklyVotes / votesPer100) * vteCoinsPer100Votes;
    const weeklyCollectibles = Math.floor(weeklyVotes / votesPer100) * collectibleCardPer100Votes;

    // Monthly Calculations
    const monthlyVotes = dailyVotes * 30 + weeklyReferrals * dailyVotes * 4; // Including referral impact
    const monthlyPAX = Math.floor(monthlyVotes / votesPer100) * paxPer100Votes;
    const monthlyVTECoins = Math.floor(monthlyVotes / votesPer100) * vteCoinsPer100Votes;
    const monthlyCollectibles = Math.floor(monthlyVotes / votesPer100) * collectibleCardPer100Votes;

    setEarnings({
      dailyPAX,
      dailyVTECoins,
      dailyCollectibles,
      weeklyPAX,
      weeklyVTECoins,
      weeklyCollectibles,
      monthlyPAX,
      monthlyVTECoins,
      monthlyCollectibles,
    });
  };

  useEffect(() => {
    // Call calculateEarnings when the component mounts
    calculateEarnings();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  let navigate = useNavigate();
  const upgradeProfile = (amount: any, VTEAmount: any) => {
    const diamonds = userInfo?.rewardStatistics?.diamonds || 0
    if (VTEAmount > diamonds) {
      return showToast(`You don't have ${VTEAmount} VTE`, ToastType.ERROR);
    }
    const data = {
      userId: userInfo?.uid,
      VTEAmount: VTEAmount,
    }    
      axios.post(`payment/validateUserVTEPurchase`, data).then((result) => {
        const response = result?.data?.data
        if (response.eligibleForPurchase) {          
        // if (VTEAmount > diamonds) {
        //     showToast(`You don't have ${VTEAmount} VTE`, ToastType.ERROR);
        //   } else {
        //   }
          let payvalue = [amount, "UPGRADE", 0, VTEAmount]
          let PayValuestring = JSON.stringify(payvalue)
          localStorage.setItem("PayAmount", PayValuestring);
          navigate("/votepayment")
        } else {
          showToast("You don’t have enough VTE as your VTE is already blocked in card trade offer", ToastType.ERROR);
        }
        console.log(result.data.data.eligibleForPurchase, "getvote")
      }).catch((err) => {
        console.log(err)
      });    
    // handleSoundClick()
    
  }

    return (
      <>
        <div className='updatePageMain'
          style={{
            backgroundImage: `${window.screen.width > 767 ? `url(${UGBG})` : `url(${UGBGM})`}`,
            backgroundRepeat: `${window.screen.width > 767 ? "repeat" : "repeat"}`,
            backgroundPosition: "0 0",
            // backgroundSize: "100% 100%",
            backgroundSize: `${window.screen.width > 767 ? "100%" : "100%"}`,
            // backgroundSize: "cover",
            backgroundAttachment: "fixed",
            minHeight: "150vh",
            // overflow:"scroll"
            overflow: "hidden"
          }}
        >
          <div className='upgradepagesec'>
            {!userInfo?.isUserUpgraded && <>            
          <div className='Homeimage'>
            <img src={DesktopScree} alt='Home Image' className='homeinimage'/>
            <h2 className='text-center my-3'>Unlock Unlimited Earnings</h2>
            <p className='text-center my-3'>Get Your Mining Virtual Machine (MVM) and Watch the Rewards Roll In </p>
            <div className='text-center'>            
            <span className='spantextDiv'>
              The Mining Virtual Machine (MVM) is a virtual machine that turns the act of voting into PAX BEP20 token mining.</span>
            <br />
              <span className='spantextDiv'>Every vote you or your friends cast is part of your mining progress.
            </span>
            <br />
            <span className='spantextDiv'>In addition, you will get 100% ownership of the collectibles you earn on any of the Vote To Earn platforms.</span>
            <br />
            <span className='spantextDiv'>The best part? You only pay once, and it's yours for life!</span>
            <br />
            <span className='spantextDiv'>Get your MVM now to start earning more. Invite your friends to boost your rewards even faster.</span>
              <br />
              </div>
              
              <div className='cmpboxIframe'>
                <div className='cmpboxIframeInside'>
                  <FullcircleProgressbar/>
                  <FullcircleProgressbar/>
                  <FullcircleProgressbar/>                
                </div>
              </div>
            </div>              
          <div className='calculatorBox'>
                <p>Buy your MVM now and get 100 VTE & 100 Extra Votes</p>
                <div className='payButtonDiv'>
                {upgradeSetting.length > 0 && upgradeSetting.map((item:any ,index:number) => {
                  return (
                    <div className='d-flex justify-content-center mt-4'
                    style={{width: '50%'}}
                    >
                      <button className='PayButton'
                        onClick={() => {
                          upgradeProfile(item?.amount, item?.VTE)
                      }}
                      >
                        <span className='amounttext'>${item?.amount}</span>
                        {item?.VTE > 0 &&
                          <span className='vtetext'>                          
                            {" "} + {item?.VTE} VTE
                        </span>  
                        }                    
                      </button>
                    </div>
                  )
                })}
                </div>
          </div>
            <div className="calculatorTabal">
              <h4>Earning Calculator</h4>

              <label htmlFor="dailyVotes">Daily Votes:</label>
              <input
                type="number"
                id="dailyVotes"
                value={dailyVotes}
                max={1001}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (Number(value) <= 1000 && Number(value) >= 0)) {                   
                    setDailyVotes(parseInt(value, 10))
                  }
                }}
                placeholder="Enter daily votes"
                required
              />

              <label htmlFor="weeklyReferrals">Weekly Referrals:</label>
              <input
                type="number"
                id="weeklyReferrals"
                value={weeklyReferrals}
                max={1001}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (Number(value) <= 1000 && Number(value) >= 0)) {
                    setWeeklyReferrals(parseInt(e.target.value, 10))
                  }                  
                }}
                placeholder="Enter weekly referrals"
                required
              />              
              <button onClick={calculateEarnings}>Calculate</button>

              <table>
                <thead>
                  <tr>
                    <th>Period</th>
                    <th>PAX Tokens</th>
                    <th>VTE Coins</th>
                    <th>Collectibles Card</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Daily</td>
                    <td>{earnings.dailyPAX || 0}</td>
                    <td>{earnings.dailyVTECoins || 0}</td>
                    <td>{earnings.dailyCollectibles || 0}</td>
                  </tr>
                  <tr>
                    <td>Weekly</td>
                    <td>{earnings.weeklyPAX || 0}</td>
                    <td>{earnings.weeklyVTECoins || 0}</td>
                    <td>{earnings.weeklyCollectibles || 0}</td>
                  </tr>
                  <tr>
                    <td>Monthly</td>
                    <td>{earnings.monthlyPAX || 0}</td>
                    <td>{earnings.monthlyVTECoins || 0}</td>
                    <td>{earnings.monthlyCollectibles || 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          <div className='imageBoxDiv'>
              <div className='TextStroeDiv'>
                <p>PAX Token (BEP-20) Overview​</p>
                <span>PAX Token is a BEP-20 token on the Binance Smart Chain and is a key part of the Vote to Earn system. PAX Tokens are created through the Coin Mining Process (CMP), which is powered by your votes. These votes are validated and recorded using the Proof of Vote (PoV) mechanism, ensuring everything is transparent and secure.​</span>
                <br />
                <br />
                <span>Tokenomics</span>
                <br />
                <span>Total Supply:  21 million tokens .</span>
                <br />
                <span>Distribution: PAX is distributed automatically to the wallet of a MVM owner when block is completed
                  Utility: You can use the PAX Tokens you earn for in-game transactions, trade them on exchanges, and add them to your crypto portfolio.</span>
                <br />
                <span>Smart contract: LINK</span>
              </div>
              <div className='ImgStroeDiv'>
                <img src={manyCoinImage} alt="" />
              </div>
          </div>
          <div className='imageBoxDiv'>
            <div className='TextStroeDiv'>
              <p>Unlock the True Value of Your Collectibles​</p>
              <span>Transform your in-game collectibles into assets with real market value by purchasing the Mining Virtual Machine (MVM). With the MVM, you get 100% ownership of your cards and their potential market value.​</span>
              <br />                
              <span>Here’s why you should get the MVM:</span>
              <br />
              <span>100% Ownership: Your collectibles become entirely yours, no strings attached.</span>
              <br />
              <span>Market Value: Convert your digital assets into items you can trade or sell, potentially earning real money.
                Invest in the MVM today and unlock the full potential of your collectibles with a one-time purchase that delivers lifetime rewards!</span>
              <br />                
            </div>
            <div className='ImgStroeDiv'>
              <img src={UpgradeCard} alt="" />
            </div>
          </div>
          <div className='upgradeLastDiv'>
              <p>Why Do People Buy the MVM?​</p>
              <span>Every vote you cast and every friend you bring into the game is mining PAX tokens for you. With an MVM, these tokens go directly into your pocket. No MVM? You’re leaving money on the table.</span>
              <br />
              <br />
              <span>With every block you complete, you earn collectible cards. These aren’t just game pieces; they’re assets. Your MVM ensures these cards are 100% yours, ready to trade, sell, or hold as long-term investments.</span>
              <br />
              <br />
              <span>Effortless Earnings, Anytime, Anywhere: Set up your MVM, invite friends to join, and let it do the work. Whether you’re chilling at the beach or sleeping, your MVM keeps the income flowing.</span>
              <br />
              <br />
              <span>
                No Extra Fees, Just Pure Profit: Pay once, profit forever. Your MVM is a one-time purchase that upgrades your account across all Vote To Earn platforms. No hidden fees, no strings attached just continuous rewards.
              </span>
              <br /><br />
              <span>
                Maximize Your Profits Don’t Wait! The sooner you get your MVM, the sooner you start earning. Early adopters stand to gain the most, so don’t let this opportunity slip by. Turn your votes into a money-making machine today
              </span>
              </div>  
            </>}
            {userInfo?.isUserUpgraded && <>
              <div className='Homeimage'>
                <img src={DesktopScree} alt='Home Image' className='homeinimage'/>
                <p className='text-center my-5'>Your are officially MVM owner</p>
                <div className='text-left'
                  style={{
                  padding:`${window.screen.width < 767 ?"0px 15px":"0px"}`
                }}
                >
                  <span className='spantextDiv'>
                    You’ve made a smart move by purchasing the Mining Virtual Machine (MVM). This upgrade unlocks exclusive benefits for you, like the ability to mint PAX tokens, earn fully-owned collectibles, and boost your rewards in Vote To Earn.</span>
                  <br />
                  <br />
                  <span className='spantextDiv'>What Happens Next:</span>
                  <br />
                  <br />
                  <span className='spantextDiv'>1. Minting PAX Tokens: Your MVM is your gateway to minting PAX tokens through the Coin Mining Process (CMP). Each vote you cast moves you closer to completing 100 CMPs, which creates a block and mints PAX tokens. These tokens are automatically sent to your wallet, adding value to both your game and your crypto portfolio.</span>
                  <br />
                  <br />
                  <span className='spantextDiv'>2. Earn Collectibles: Every completed block doesn’t just give you PAX tokens—it also unlocks exclusive digital collectibles. And they’re all yours, meaning you have full control over how you use, trade, or sell them.</span>
                  <br />
                  <br />

                  <span className='spantextDiv'>3. Daily Voting: Voting daily is key to making the most out of your MVM. The more you vote, the quicker you progress through CMPs, complete blocks, and earn rewards. Keep up a good voting streak to advance in the game and boost your status.</span>
                  <br />
                  <br />

                  <span className='spantextDiv'>Use the Ambassador Plan:</span>
                  <br />
                  <br />

                  <span className='spantextDiv'>As an MVM owner, inviting friends through the Ambassador Plan is a great way to speed up your progress. Every vote they cast helps you complete CMPs faster, so the more friends you bring in, the quicker you can mint PAX and unlock rewards.</span>
                  <br />
                  <br />
                  <span className='spantextDiv'>With your MVM, you’re all set to level up in Vote To Earn. Stay active, keep voting, and invite others to maximize your rewards and boost your rank!</span>
                  <br />
                  <br />
                  <br />                  
                  <div className="d-flex justify-content-center my-3" style={{ position: 'relative' }}>
                    <ButttonDiv className="mt-1 m-auto">
                      <button
                        onClick={() => {
                          if (!user?.uid) {
                            setLogin(true)
                            return
                          } else {
                            setShowShare(prev => !prev);
                          }
                        }}
                      >EARN NOW !

                        <img src={imageSources[currentImageIndex]} alt="" width={"20px"} className="" />
                      </button>
                    </ButttonDiv>
                    {showShare && <div className={classes.shareBox} style={window.screen.width < 300 ? {
                      marginLeft: '2em',
                      top: '5em',
                    } : (window.screen.width <= 425 ? {
                      marginLeft: '8em',
                      top: '4em',
                    } : {
                      marginLeft: '10em',
                      top: '4em',
                    })}>
                      <span className="material-symbols-outlined text-secondary me-2"
                        onClick={() => {
                          copy(url);
                          showToast(
                            'Your referral link is copied to the clipboard.',
                            ToastType.SUCCESS
                          );
                        }}>
                        content_copy
                      </span>
                      <a href={`https://api.whatsapp.com/send/?phone&text=${`${shareText} ${url}`.replace(" ", "+")}&app_absent=0`} target="_blank" onClick={() => setShowShare(false)}>
                        <img src={whatsApp} className="me-2" width={'25px'} height={'25px'} />
                      </a>
                      <a href={`https://twitter.com/intent/tweet?url=${url}?check_suite_focus=true&text=${shareText}`} target="_blank" onClick={() => setShowShare(false)}>
                        <img src={XTwitter} width={'25px'} height={'25px'} className="me-2" />
                      </a>
                      <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}&t=${shareText}`} target="_blank" onClick={() => setShowShare(false)}>
                        <img src={facebook} className="me-2" width={'25px'} height={'25px'} />
                      </a>
                    </div>
                    }
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>

                <div className='cmpboxIframe'>
                  <div className='cmpboxIframeInside'>
                    <FullcircleProgressbar />
                    <FullcircleProgressbar />
                    <FullcircleProgressbar />
                  </div>
                </div>
              </div>  
            </>}
            </div>
          
        </div>
      </>
  )
}

export default UpgradePage