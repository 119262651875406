import React, { useContext, useEffect, useState } from "react";
import SignupForm from "./SignupForm";
import { texts, urls } from "./texts";
import { useTranslation } from "../../common/models/Dictionary";
import { AuthProvider, getAuth, signInWithCustomToken, User } from "firebase/auth";
import { LoginProviders, providers, SignupPayload } from "../../common/models/Login";
import { Callback } from "../../common/models/utils";
import { User as AuthUser } from "@firebase/auth";
import NotificationContext, { ToastType } from "../../Contexts/Notification";
import styled from "styled-components";
import { PoppinsBoldBlueViolet14px, PoppinsMediumBlack12px, PoppinsNormalBlueViolet12px } from "../../styledMixins";
import LoginWith from "./LoginWith";
import { ToastContent, ToastOptions } from "react-toastify";
import { Form, Image } from "react-bootstrap";
import InputField from "../Atoms/Input/InputField";
import { capitalize } from "lodash";
import { Buttons } from "../Atoms/Button/Button";
import Checkbox from "../Atoms/Checkbox/Checkbox";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { firestore, functions } from "../../firebase";
import UserContext from "../../Contexts/User";
import AppContext from "../../Contexts/AppContext";
import firebase from "firebase/compat/app";
import copy from "copy-to-clipboard";
import googleLogo from "../../assets/svg/google_Logo.svg"
import telegram from "../../assets/svg/telegram.png";
import { collection, getDocs, query, where } from "firebase/firestore";
import { LoginButton } from '@telegram-auth/react';
import axios from "axios";
import { telegramLoginData } from "common/utils/helper";

const LoginButton2 = styled.div`
  border: none;
  border-radius: 5px;
  background: var(--white);
  color: var(--black);
  width: 100%;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #d4d0f3;
    border-color: #d4d0f3;
  }
`;

const ContinueWith = styled.div`
  ${PoppinsNormalBlueViolet12px};
  min-height: 19px;
  // align-self: flex-end;
  margin-left: 32px;
  min-width: 131px;
  letter-spacing: 0;
  white-space: nowrap;
`;
const Login = styled.div`
  margin-left:5px;
  margin-right:7px;
  cursor:pointer;
  font-weight:600 !important;
  // text-decoration:underline;
  ${PoppinsBoldBlueViolet14px};
`;
const OR = styled.div`
  ${PoppinsMediumBlack12px};
  min-height: 19px;
  align-self: center;
  letter-spacing: 0;
  line-height: 11px;
  white-space: nowrap;
  text-transform: uppercase;
`;
const HaveAccountText = styled.div`
 color:black;
`;


export type SignupProps = {
  setUser: (user?: User | undefined) => void;
  setSignup: (s: boolean) => void;
  signup: (
    payload: SignupPayload,
    callback: Callback<AuthUser>
  ) => Promise<boolean>;
  authProvider: (
    setUser: (user: AuthUser) => void,
    provider: AuthProvider,
    showToast: (
      content: ToastContent,
      type?: ToastType,
      options?: ToastOptions | undefined
    ) => void
  ) => Promise<void>;
};
const assign = httpsCallable(functions, "assignReferrer");
const Signup = ({ setUser, setSignup, signup, authProvider }: SignupProps) => {
  const translate = useTranslation();
  const { showToast } = useContext(NotificationContext);
  const { setLogin } = useContext(AppContext);
  const [signupWithProviders, setSignupWithProviders] = useState(true)
  const [email, setEmail] = useState('')
  const [agree, setAgree] = useState(true);
  const { user, userInfo } = useContext(UserContext);
  
  const [smsVerification, setSmsVerification] = useState('')
  const [signupLoading, setSignupLoading] = useState(false)
  let navigate = useNavigate();
  const search = useLocation().search;
  const refer = new URLSearchParams(search).get("refer") || "VoteToEarn";
  const registerType = new URLSearchParams(search).get("refer") ? "REFERRED" :"SELF";
  const [preantId, setPreantId] = useState(null)

  // const getUserId = async () => {    
  //   const uidValue = refer?.slice(-6);
  //   const emailValue = refer?.slice(0, 2);

  //   var userdata = { uid: '' };
  //   if (refer) {
  //     try {
  //       const referUser = await firebase
  //         .firestore()
  //         .collection('users').where("userName", '==', refer).get();
  //       if (!referUser.empty) {
  //         referUser.forEach((doc: any) => {
  //           userdata = doc.data();
  //           setPreantId(doc.data().uid)
  //         });
  //       }
  //       else if (referUser.empty) {
  //         const referUser2 = await firebase
  //           .firestore()
  //           .collection('users');
  //         await referUser2.get().then((snapshot) => {
  //           let data: any = []
  //           snapshot.forEach((doc) => {
  //             data.push({ ...doc.data() });
  //           });
  //           console.log(data,"alldat")
  //           data?.map((item: any, index: number) => {
  //             if (item.uid?.slice(-6) == uidValue && item.email?.slice(0, 2) == emailValue) {
  //               setPreantId(item.uid)
  //               // setParentEmailId(item.email)
  //             }
  //           })
  //         })
  //       }
  //     } catch (err) {
  //       console.log( err, 'email');
  //     }
  //     console.log(userdata,"userdata")
  //   }
  // }

  const getUserId = async () => {
    const uidValue = refer?.slice(-6);
    const emailValue = refer?.slice(0, 2);

    var userdata = { uid: '' };
    if (refer) {
      try {
        const usersCollectionRef = collection(firestore, 'users');
        const referUserQuery = query(usersCollectionRef, where('userName', '==', refer));
        const referUserSnapshot = await getDocs(referUserQuery);

        if (!referUserSnapshot.empty) {
          referUserSnapshot.forEach((doc:any) => {
            userdata = doc.data();
            setPreantId(doc.data().uid);
          });
        } else {
          const allUsersQuery = query(usersCollectionRef);
          const allUsersSnapshot = await getDocs(allUsersQuery);

          let data: any = [];
          allUsersSnapshot.forEach((doc) => {
            data.push({ ...doc.data() });
          });

          data?.map((item: any, index: number) => {
            if (item.uid?.slice(-6) == uidValue && item.email?.slice(0, 2) == emailValue) {
              setPreantId(item.uid);
            }
          });
        }
      } catch (err) {
        console.log(err, 'email');
      }
      console.log(userdata, "userdata");
    }
  };

  useEffect(() => {
    if (refer) {
      getUserId()
    }
  }, [])  

  console.log(preantId,"PreantId")
  var userAgent = navigator.userAgent.toLowerCase(); 
  const getReferrer = document.referrer;
  const isInstagramAvailable = /instagram/.test(userAgent) || /fb_iab/.test(userAgent) || /tiktok/.test(userAgent) || /musically/.test(userAgent) || getReferrer == "https://t.co/";
  const strings = {
    email: capitalize(translate(texts.email)),
    confirmPassword: capitalize(translate(texts.confirmPassword.toUpperCase())),
    password: capitalize(translate(texts.password.toUpperCase())),
    continue: capitalize(translate(texts.continue.toUpperCase())),
    agree: capitalize(translate(texts.agree.toUpperCase())),
  };
  // const assignReferrer =  (params: User) => {
  //   const assign = httpsCallable(functions, "assignReferrer");
  //    assign({parent: refer, child: params.uid});
  //   setLogin(true);
  //   // navigate("/");
  // };
  // https://us-central1-coinparliament-51ae1.cloudfunctions.net/assignReferrer


  const addRegisterType = async ({ parent, child }: { parent: string, child: string,}) => {
    await assign({ parent, child, registerType })
  }  

  const [isTelegram, setIsTelegram] = useState(false);

  useEffect(() => {
    const isTelegramWebView = () => {
      // @ts-ignore
      // const urlParams = new URLSearchParams(window.location.search);
      // const source = urlParams.get('source');
      // if (source === 'telegram') {
      //   setIsTelegram(true);
      //   localStorage.setItem('source', 'telegram');
      // } else {
      //   const storedSource = localStorage.getItem('source');
      //   if (storedSource === 'telegram') {
      //     setIsTelegram(true);
      //   }
      // }
      window.Telegram.WebApp.ready();
      // @ts-ignore
      const initData = window.Telegram.WebApp.initData;
      if (initData) {
        setIsTelegram(true)
      }
    };
    isTelegramWebView();
    // @ts-ignore
  }, [window.Telegram.WebApp.initData]);
  

  const telegramLoginFuncation = httpsCallable(functions, "telegramLogin");
  const createUser = httpsCallable(functions, "createUser");
  

  const LoginWithTelegram = async () => {
    // @ts-ignore
    window.Telegram.WebApp.ready();
    // @ts-ignore
    const initData = window.Telegram.WebApp.initData;
    if (initData) {
      const payloadData = await telegramLoginData(initData)                       
      const data = await telegramLoginFuncation(payloadData);      
      // @ts-ignore                                       
      const auth = getAuth();      
      // @ts-ignore
      signInWithCustomToken(auth, data?.data?.result)
        .then((userCredential: any) => {
          console.log(userCredential, "userCredential")
          // User is signed in                         
          const data = {
            uid: userCredential?.user?.uid,
          }
          // @ts-ignore
          if (userCredential?._tokenResponse?.isNewUser) {
            createUser(data).then((result: any) => {
              console.log(result, "telegram:6687516119")
            }).catch((err: any) => {
              console.log(err)
            });
          }
        })
        .catch((error: any) => {
          // Handle error          
          console.error(error);
        });
    }
  }
  return (
    <>
      {signupWithProviders ? <>{!isInstagramAvailable  ? Object.values(LoginProviders).map((provider, i) => {
        return (
          <div key={i} className="mb-2 w-100">
            {!isTelegram &&  <LoginWith
              provider={provider}
              onClick={() =>
              // @ts-ignore
              { agree ? preantId ? authProvider(setUser, providers[provider], showToast, setSmsVerification, addRegisterType, preantId) : authProvider(setUser, providers[provider], showToast, setSmsVerification) : showToast(texts.AgreetNc, ToastType.ERROR) }
              }
            /> } 
            {/* {isTelegram && <div className="mt-2 telegramLoginButton">
            <LoginButton              
              botUsername={'coinptestBot'}
              cornerRadius={5}
              showAvatar={false}
                onAuthCallback={async (response:any) => {
                  console.log('telegram', response);                                      
                  const data = await telegramLoginFuncation(response);                  

                  const auth = getAuth();
                  // @ts-ignore
                  signInWithCustomToken(auth, data?.data?.result)
                    .then((userCredential) => {
                      console.log(userCredential,"userCredential")
                            // User is signed in                         
                         const data = {
                           uid: userCredential?.user?.uid,                             
                         }
                      // @ts-ignore
                      if (userCredential?._tokenResponse?.isNewUser) {                        
                        createUser(data).then((result) => {
                          console.log(result,"telegram:6687516119")
                        }).catch((err) => {
                          console.log(err)
                        });
                      }
                            })
                        .catch((error) => {
                          // Handle error
                                console.error(error);
                        });                  
                }}
              />
            </div>} */}
            {isTelegram && <div className="mb-2 w-100 ">
              <LoginButton2
                style={{
                  boxShadow: window.screen.width > 979 ? "0px 3px 6px #00000029" : "",
                  background: "white",
                }}
                onClick={() => {
                  LoginWithTelegram()
                }}
              >

                
                  <div
                    className="d-flex py-1 justify-content-start align-items-center"
                    style={{
                      marginLeft: "25px",
                    }}
                  >
                    <Image
                      src={telegram}
                      alt=""
                      width={"35px"}
                      height={"35px"}
                      className="pl-3"
                    />
                    <ContinueWith>Continue with Telegram</ContinueWith>
                  </div>
                
              </LoginButton2>
            </div>}
            </div>          
        );
      }):<div><img src={googleLogo} alt="" style={{width:"50px",marginLeft:"38%"}}/>
      
      <div style={{width:'239px', color:'black'}}>To signup with google, copy this link and open in another browser, <span style={{textAlign:'center',color: 'var(--blue-violet)',fontSize:"9px"}}  onClick={() => {
        copy(window.location.href);
        showToast(
          'Your link is copied to the clipboard.',
          ToastType.SUCCESS
        );
      }}>Copy link</span></div></div>}
        <div className="my-3 align-self-center">
          <OR className="mx-auto">{translate("or")}</OR>
        </div>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            setEmail(((e.target as HTMLFormElement).elements.namedItem('email') as HTMLInputElement).value)
            setSignupWithProviders(false)
          }}
          className="w-100"
        >
          <Form.Group className="mb-3 w-100" controlId="login-email">
            <InputField
              style={{ color: 'var(--blue-violet)', boxShadow: window.screen.width > 979 ? '0px 3px 6px #00000029' : '' }}
              fullWidth={true}
              type="email"
              placeholder={strings.email}
              name="email"
              required
            />
          </Form.Group>
          <div className="my-1">
            <Buttons.Primary fullWidth={true} type="submit"  >
              {strings.continue.toUpperCase()}
            </Buttons.Primary>
          </div>
          <Form.Group className="mb-2 mt-3 text-center" controlId="agree" >
            <Checkbox name="agree" checked={agree} onClick={() => setAgree(!agree)} >
              <p className='mb-1'> I agree to <Link to={urls.termsConditions} style={{ color: 'var(--blue-violet)' }}>
                {translate('terms & conditions')}
              </Link>  and
              </p>
              <p><Link to={'/privacy'} style={{ color: 'var(--blue-violet)' }}>
                privacy policy
              </Link> of the site</p>
              {/* {translate(strings.agree)
            .split("{terms & conditions}")
            .map((t, i) => (
              <React.Fragment key={i}>
                {t.toUpperCase()}{" "}
                {!i && (
                  <Link to={urls.termsConditions} style={{color: 'var(--blue-violet)'}}>
                    {translate(texts.termsConditions.toUpperCase())}
                  </Link>
                )}
              </React.Fragment>
            ))} */}
            </Checkbox>
          </Form.Group>
        </Form>

      </> :
        <SignupForm
          signupLoading={signupLoading}
          setSignupLoading={setSignupLoading}
          emailValue={email}
          signup={signup}
          callback={{
            successFunc: async (params) => {
              console.log('params', params.uid)
              if (preantId) await addRegisterType({ parent: preantId, child: params.uid });
              setSignup(false)
              setLogin(true)
              setSignupLoading(false)
            },

            errorFunc: (e) => {
              showToast(e.message, ToastType.ERROR)
              setSignupLoading(false)
            },
          }}
        />}
      <div className='d-flex'>
        <HaveAccountText className="mr-5"> {`${translate(texts.haveAccount)} `}</HaveAccountText>
        <Login onClick={() => setSignup(false)}>{`${translate(texts.login)}`}</Login>
      </div>
    </>
  );
};

export default Signup;
