import React from "react";
import { Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import coinGif from "../../assets/images/coinGif.gif";
import { Buttons } from "Components/Atoms/Button/Button";
import styled, { css } from "styled-components";
import lottie from "lottie-web";
import { useNavigate } from "react-router-dom";

const Popuphead = styled.p`
  font-size: 25px;
  font-weight: 600;
  background: linear-gradient(180deg, #fff8a6 29.44%, #ffc00b 73.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // text-shadow: 0px 1px 3px #FFCB35;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const NFTTradeCoin = ({
  modalShow,
  approvedCard,
  handleCoinClose,
}: {
  modalShow?: any;
  approvedCard?: any;
  handleCoinClose?: any;
}) => {
  console.log("coinModelShow NFTTradeCoin", modalShow);
  const navigate = useNavigate();
  return (
    <div>
      {/* reward modal 1 */}
      <Modal
        show={modalShow == true ? true : false}
        onHide={handleCoinClose}
        backdrop="static"
        contentClassName={"modulebackground ForBigDiv"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
        style={{ backgroundColor: "rgba(0,0,0,0.8)", zIndex: "2200" }}
        id="popupid"
      >
        <Modal.Body
          className="d-flex  flex-column  justify-content-between align-items-center"
          style={{
            width: `${window.screen.width > 767 ? "500px" : "100%"}`,
            height: "400px",
          }}
        >
          <Popuphead> Congrats!</Popuphead>
          {/* @ts-ignore*/}
          <div className="">
            <div className="d-flex justify-content-center align-items-center m-auto">
              <img src={coinGif} alt="" width={"200px"} />
            </div>
            <p
              style={{
                fontSize: "24px",
                color: "white",
                fontWeight: "600",
              }}
            >
              You Earned
              {
                // @ts-ignore
               " " + approvedCard?.coin
              }{" "}
              VTE{" "}
            </p>
          </div>

          <div className="d-flex justify-content-center ">
            <Buttons.Primary className="mx-2" onClick={() => {              
              navigate(`/profile/mine`);
              handleCoinClose()
            }}>
              Check Balance
            </Buttons.Primary>
          </div>
        </Modal.Body>
        {/* </Modal.Footer>       */}
      </Modal>
    </div>
  );
};

export default NFTTradeCoin;
