import { useContext, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "../Style.css";
import Button, { Buttons } from "Components/Atoms/Button/Button";
import styled from "styled-components";
import axios from "axios";
import { DESTRUCTION } from "dns";
import { useParams } from "react-router-dom";
import UserContext from "Contexts/User";
import { getCurrentDateTime, getUserData } from "common/utils/helper";
import WaitForResponse from "./WaitForResponse";

type opentradetype = {
  openModal: string;
  setOpenModal: any;
  tradeCrad?: any;
  leader?: any;
  EditRequest?: any;
};

const TradeButtons = styled.button`
  color: white;
  font-size: 12px;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
`;
function BuyerSellerPopup({
  openModal,
  setOpenModal,
  tradeCrad,
  leader,
  EditRequest,
}: opentradetype) {
  const [BuyerCardShow, setBuyerCardShow] = useState(false);
  const [BuyerDetails, setBuyerDetails] = useState(false);
  const [waitRespons, setWaitRespons] = useState(false);
  const [sellerDetails, setSellerDetails] = useState(false);
  const [freeCard, setFreeCard] = useState(false);
  const [giftCard, setGiftCard] = useState(false);
  const { userInfo, user } = useContext(UserContext);
  const paramsUrl = window.location.pathname;
  let params = useParams();
  const { type, id } = params;
  const [radiobuttonType, setRadiobuttonType] = useState<any>([
    // { lable: "Ask for free", value: "FREE" },
    {
      lable: `${paramsUrl.includes("/profile") ? "Sale" : "Buy"}`,
      value: "COIN",
    },
  ]);
  const [buyType, setBuyType] = useState<any>("FREE");
  const [selectTimer, setSelectTimer] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState('');
  const [discription, setDiscription] = useState<any>();
  const [days, setDays] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [coinVal, setCoinVal] = useState<number>();
  const [getChatData, setGetChatData] = useState<any>();
  const [askForVTE, setAskForVTE] = useState<any>(false);
  const [selectStatus, setSelectStatus] = useState<any>("");
  const [isEdit, setIsEdit] = useState(false);
  const [sellerName, setSellerName] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  

  const [dropdwonMsg, setDropdwonMsg] = useState<any>({
    APPROVED:
      [
      "Deal sealed! Let's celebrate!",
      "Approved! Card is yours!",
      "Great news! Card sold!",
        "Deal accepted! Enjoy the card!"
      ],
    NEGOTIATE: [
      "How about a discount for a fellow collector?",
      "Let's make a deal and both do a happy dance!",
      "Can we sweeten the pot with a lower price?",
      "Let's sprinkle some magic on the price tag, shall we?",
    ],
    REJECTED: [
      "Not interested, but appreciate.",
      "I must decline, thank you.",
      "Thanks, but not interested.",
    ]
  });

  const [dropdwonMsgAsk, setDropdwonMsgAsk] = useState<any>({
    APPROVED:
      [
      "Deal accepted! Let's celebrate!",
      "Accepted! Excited to add it to my collection!",
      "Deal locked in! Thanks a bunch!",
      "Approved! Can't wait to enjoy this card!"
      ],
    NEGOTIATE: [
      "How about we meet halfway on the price?",
      "I'm interested, but can we discuss the price a bit?",
      "I'd love to make a deal, but could you lower the price a bit?",
      "Any chance we can work out a better price?",
    ],
    REJECTED: [
      "Not interested, but appreciate.",
      "I must decline, thank you.",
      "Thanks, but not interested.",
    ]
  });

  const handleBuyerCardClose = () => {
    setBuyerCardShow(false);
    setOpenModal("");
    setIsEdit(false);
    setErrors({});
    setBuyerDetails(false);
  };
  
  const handleChange = (event: any) => {
    setErrors((prev: any) => ({ ...prev, selectedDate: "" }));
    setSelectedDate(event.target.value);
  };


  const handleBuyerDetailsShow = () => setBuyerDetails(true);
  const handleBuyerDetailsClose = () => {
    setBuyerDetails(false);
    setOpenModal("");
  };
  

  const handleSellerDetailsShow = () => setSellerDetails(true);
  const handleSellerDetailsClose = () => {
    setSellerDetails(false);
    setOpenModal("");
  };

  const handleFreeCardShow = () => setFreeCard(true);

  const handleFreeClose = () => {
    setFreeCard(false);
    setOpenModal("");
  };  

  const handleGiftCardShow = () => setGiftCard(true);

  const handleGiftCardClose = () => {
    setGiftCard(false);
    setOpenModal("");
  };  
  
  useEffect(() => {
    if (openModal == "askforcard") {
      handleBuyerDetailsShow();
    }
    if (openModal == "freecard") {        
      console.log(leader,"getleader")
      let hour: any = {
        1: "Hour",
        24: "Day",
        168: "Week",
      };

      setBuyType(leader?.tradeType);
      setDiscription(leader?.message)
      
      // setSelectTimer(
      //   leader?.durationType !== "CUSTOM"
      //     ? hour[leader?.duration]
      //     : "Custom"
      // );
      setSelectTimer(
        leader?.durationType == "CUSTOM" ? "Custom"
          : leader?.durationType == "LIMIT"
            ? hour[leader?.duration]
            : leader?.durationType == "NO_LIMIT" ? "NO_LIMIT" : ""
      );
      if (leader?.durationType == "CUSTOM") {
        setDays(leader?.duration);
      }      
      setSelectStatus("APPROVED")
      if (leader?.tradeType != "FREE") {
        setCoinVal(leader?.coin);
      }
      console.log(leader?.accountId, "leader.accountId ")
      setIsEdit(leader?.accountId && leader?.accountId != "" ? true : false)
      handleFreeCardShow();
    }   
    
    if (openModal == "giftcard") {    
      console.log(leader, "getleader")
      getUserFullData(leader?.sellerUserId);
      let hour: any = {
        1: "Hour",
        24: "Day",
        168: "Week",
      };

      setBuyType(leader?.tradeType == "FREE" ? "GIFT" : leader?.tradeType);
      setDiscription(leader?.message)

      setSelectTimer(
        leader?.durationType == "CUSTOM" ? "Custom"
          : leader?.durationType == "LIMIT"
            ? hour[leader?.duration]
            : leader?.durationType == "NO_LIMIT" ? "NO_LIMIT" : ""
      );
      if (leader?.durationType == "CUSTOM") {
        setDays(leader?.duration);
      }
      // setSelectStatus("APPROVED")
      if (leader?.tradeType != "FREE") {
        setCoinVal(leader?.coin);
      }
      console.log(leader?.accountId, "leader.accountId ")
      setIsEdit(leader?.accountId && leader?.accountId != "" ? true : false)
      handleGiftCardShow();
    }        
    

    if (openModal == "buyerDetails") {     
      handleBuyerDetailsShow();
    }
    if (openModal == "sellerDetails") {

      console.log(leader,"allleaderdata")
      let hour: any = {
        1: "Hour",
        24: "Day",
        168: "Week",
      };

      setBuyType(leader?.tradeType);
      setSelectStatus(leader?.status);

      if (EditRequest) {        
        let hour: any = {
          1: "Hour",
          24: "Day",
          168: "Week",
        };        
        setSelectTimer(
          leader?.durationType == "CUSTOM"
            ? "Custom"
            : leader?.durationType == "LIMIT"
              ? hour[leader?.duration]
              : leader?.durationType == "NO_LIMIT" ? "NO_LIMIT" : ""
        );
        if (leader?.durationType == "CUSTOM") {          
          let getTimeValue = getCurrentDateTime(leader?.endTimeDuration)
          setSelectedDate(getTimeValue);
        }
        if (leader?.tradeType != "FREE") {
          setCoinVal(leader?.coin);
        }
        if (leader?.message != "") {
          setDiscription(leader?.message);
        }
      }      
      console.log(leader?.accountId,"leader.accountId ")
      setIsEdit(leader?.accountId && leader?.accountId != "" ? true : false)
      handleSellerDetailsShow();
    }  
    return () => {};
  }, []);
  
  const getUserFullData = async(sellerID:string) => {
    const setUserName = await getUserData(leader?.sellerUserId)
    setSellerName(setUserName?.userName)
    // console.log(setUserName,"setUserName")
  }
  
  const onHabdleSendRequast = async () => {
    console.log("updatetradeChat onHabdleSendRequast");
    setWaitRespons(true)
    let hour: any = {
      Hour: 1,
      Day: 24,
      Week: 168,
    };
    const endTimeDuration: any = new Date(selectedDate).toUTCString();
    const data = {
      transitionType: leader?.transitionType || "BID",
      tradeType: buyType,
      coin: coinVal && coinVal > 0 ? Number(coinVal) : 0,
      // durationType: selectTimer !== "Custom" ? "LIMIT" : "CUSTOM",
      durationType: selectTimer == "Custom"
        ? "CUSTOM"
        : selectTimer == "NO_LIMIT"
          ? "NO_LIMIT"
          : "LIMIT",
      duration: selectTimer == "Custom" ? `${0}` : selectTimer == "NO_LIMIT" ? `${0}` : `${hour[selectTimer]}`,
      endTimeDuration: selectTimer == "Custom" ? endTimeDuration : "",
      status: selectStatus,
      message: discription,      
    };
    console.log(data, "submitdata")
    console.log(isEdit, "checkIsaccou")
    await tradeCrad(
      data,     
      leader,
      () => {
        handleBuyerCardClose()
        setWaitRespons(false);
      },
      isEdit,
      (message: any) => {
        console.log("ErrorMessageData yes i am calling")
        setErrorMessage(message)
        setWaitRespons(false);
      },
      // () => handleBuyerCardClose(),
      // getChatData
    );
  };

  const handleFormSubmit = () => {
    setErrorMessage("");
    const newErrors: any = {};
    
      if (!buyType) {
        newErrors.buyType = "Please select a buy type.";
      }
    if (buyType !== "FREE" && (!coinVal || coinVal<1)) {
      newErrors.coinVal = "Please enter VTE value.";
      }
      if (!selectTimer) {
        newErrors.selectTimer = "Please select a timer.";
      }
      // if (selectTimer === "Custom" && !days) {
      //   newErrors.days = "Please enter a days.";
    // }
    
    if (selectTimer === "Custom") {
      if (selectedDate == "") {
        newErrors.selectedDate = "Please select date and time";
      }
      const selectedDateTime = new Date(selectedDate);
      const currentDateTime = new Date();
      const oneHourLater = new Date(currentDateTime.getTime() + 60 * 60 * 1000);
      if (selectedDateTime < oneHourLater) {
        newErrors.selectedDate = "Please select more then 1 hour";
      }
    } 
      if (selectStatus === "") {
        newErrors.selectStatus = "Please select status";
      }
      if (!discription) {
        newErrors.discription = "Please select a message.";
      }  
    console.log("newErrors updatetradeChat", newErrors);
    if (Object.keys(newErrors).length === 0) {
      // All validations passed, handle form submission
      onHabdleSendRequast();
      // handleBuyerCardClose();
    } else {
      setErrors(newErrors);
    }
    console.log(newErrors, buyType, coinVal, "i am calling formSubmit");
  };

  const onhandleRejectAndApproved = async () => {
    console.log("updatetradeChat onHabdleSendRequast");
    setWaitRespons(true);
    const data = {
      transitionType: leader?.transitionType,
      tradeType: leader?.tradeType,
      coin: leader?.coin,
      durationType: leader?.durationType,
      duration:leader?.duration,
      status: selectStatus,
      message: discription,
    };
    console.log(data, "submitdata")
    await tradeCrad(
      data,
      leader,
      () => {
        handleBuyerCardClose()
        setWaitRespons(false);
      }, 
      isEdit,
      (message: any) => {
        console.log("ErrorMessageData yes i am calling")
        setWaitRespons(false);
        setErrorMessage(message)
      },
    );
  };


  const handleRejectAndApproved = () => {
    const newErrors: any = {};
                              
      if (!discription) {
        newErrors.discription = "Please select a message.";
      }      
    if (Object.keys(newErrors).length === 0) {      
      onhandleRejectAndApproved();      
    } else {
      setErrors(newErrors);
    }
    console.log(newErrors, buyType, coinVal, "i am calling formSubmit");
  };

  



  // const onHandleOtherRequast = async () => {
  //   console.log(leader, selectStatus,"selectStatusleader");
  //   await axios
  //     .put(`/cardTrading/updateTradeStatus/${leader?.id}`, {
  //       status:selectStatus,
  //     })
  //     .then((res: any) => {
  //       if (res.status === 200) {
  //         handleBuyerCardClose();
  //       }
  //       console.log("updateTradeStatus REJeCT", res);
  //     })
  //     .catch((error: any) => {
  //       console.log("updateTradeStatus error REJeCT", error);
  //       handleBuyerCardClose();
  //     });
  // };


  console.log(dropdwonMsg[selectStatus],"dropdwonMsg[selectStatus]")  
  console.log((leader?.status == "PENDING" || selectStatus != ""), "cehckbothvalue")
  

  console.log(errors,"errorsget all errors")
  return (
    <div>
      <div>
        <Modal
          dialogClassName="modal-35w"
          show={BuyerDetails}
          onHide={handleBuyerDetailsClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1, zIndex: 9999 }}
          className="borderColor"
          // animation={false}
          backdrop="static"
          contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
        >
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleBuyerDetailsClose}
            ></button>
          </div>
          <Modal.Body>
            <>
              
              <div className="w-100 text-center"><strong>{openModal !== "buyerDetails" ? "Ask for VTE" :"Buy card offer"}</strong></div>            
              <div className="d-flex justify-content-around flex-wrap">

                <select
                  className="SetInputSize"
                  aria-label="Default select example"
                  value={selectStatus}
                  onChange={(e) => {                    
                    if (e.target.value == "NEGOTIATE") {
                      setSelectTimer("");
                      setSelectStatus(e.target.value);
                      setDiscription("");
                      setCoinVal(0);                   
                      setErrors((prev: any) => ({ ...prev, selectStatus: "" }));
                    } else {                      
                      setSelectStatus(e.target.value);
                      setDiscription("");
                      setErrors((prev: any) => ({ ...prev, selectStatus: "" }));                    
                    }
                  }}
                >
                  <option value="">Select Status</option>
                  {/* <option value="FREE">FREE</option> */}
                  <option value="NEGOTIATE">NEGOTIATE</option>
                  {leader?.status == "NEGOTIATE" &&<option value="REJECTED">REJECT</option>}
                  {leader?.lastUpdatedBy != userInfo?.uid && <option value="APPROVED">APPROVE</option>}
                </select>
                {errors?.selectStatus && (
                  <p className="text-danger" style={{ fontSize: "12px" }}>
                    {errors?.selectStatus}
                  </p>
                )}
                
                  <>
                    <input
                      className="SetInputSize"
                      type="text"
                      name=""
                      placeholder="Enter VTE amount"
                      value={coinVal}
                    onChange={(e: any) => {
                      const newValue = e.target.value;
                      const sanitizedValue = newValue.replace(/[^0-9]/g, "");   
                      if (sanitizedValue?.length < 16) {
                        setCoinVal(sanitizedValue);
                      }                       
                        setErrors((prev: any) => ({ ...prev, coinVal: "" }));
                      }}
                      id=""
                    />
                    {errors?.coinVal && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors?.coinVal}
                      </p>
                    )}
                  </>                                
                
                <select
                  className="SetInputSize"
                  aria-label="Default select example"
                  value={selectTimer}
                  onChange={(e) => {
                    setSelectTimer(e.target.value);
                    setErrors((prev: any) => ({ ...prev, selectTimer: "" }));
                  }}
                >
                  <option value="">Select time</option>
                  <option value="Hour">1 Hour</option>
                  <option value="Day">1 Day</option>
                  <option value="Week">1 Week</option>
                  <option value="NO_LIMIT">No Limit</option>
                  <option value="Custom">Custom</option>
                </select>
                {errors?.selectTimer && (
                  <p className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.selectTimer}
                  </p>
                )}
                {/* </div> */}
                {selectTimer == "Custom" && (
                  <>

                    <input
                      className="SetInputSize"
                      type="datetime-local"
                      id="datetime"
                      value={selectedDate}
                      onChange={handleChange}
                      min={getCurrentDateTime()}
                    // onBlur={(event)=>handleValidation(event)}
                    />
                    {errors.selectedDate && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors.selectedDate}
                      </p>
                    )}
                  </>
                )}
                <select
                  className="SetInputSize"
                  aria-label="Default select example" 
                  name=""
                  id=""                  
                  value={discription}
                  onChange={(e) => {
                    setDiscription(e.target.value);
                    setErrors((prev: any) => ({ ...prev, discription: "" }));
                  }}
                >
                  <option value="">Select message</option>
                  {dropdwonMsg&& selectStatus &&dropdwonMsg[selectStatus]?.map((item:any,index:number) => {
                    return (<>
                      <option value={item} key={index}>{item}</option>                  
                    </>)
                  })}
                </select>
                {errors.discription && (
                  <p className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.discription}
                  </p>
                )}
              </div>
              {errorMessage != "" && (
                <div className="text-center">
                  <p className="text-danger" style={{ fontSize: "12px" }}>
                    {errorMessage}
                  </p>
                </div>
              )}
              <div className="w-100 d-flex justify-content-center mt-4">
                <Buttons.Primary onClick={handleFormSubmit}>
                  {buyType == "COIN" ? "Create offer" : "Send Request"}
                </Buttons.Primary>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </div>

{/* Free card popup */}

      {/* <div>
        <Modal
          dialogClassName="modal-35w"
          show={freeCard}
          onHide={handleFreeClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1, zIndex: 9999 }}
          className="borderColor"
          // animation={false}
          backdrop="static"
          contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
        >
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleFreeClose}
            ></button>
          </div>
          <Modal.Body>
            <div className="w-100 mb-4 text-center">
            <strong className="">Free card request</strong>
            </div>
            <p className="text-center">{discription}</p>
            <div className="w-100 d-flex justify-content-around mt-4">
              <TradeButtons
                style={{
                  backgroundColor: "#6458e5",
                }}
                onClick={() => {
                  setFreeCard(false);
                  setBuyerDetails(true);
                  setOpenModal("askforcard");  
                  setSelectStatus(leader?.status);
                }}
              >
                Ask for VTE
              </TradeButtons>
              <TradeButtons
                style={{
                  backgroundColor: "#6458e5",
                }}
                onClick={() => {                  
                  handleFormSubmit()
                }}
              >
                Approve
              </TradeButtons>
            </div>
          </Modal.Body>
        </Modal>
      </div> */}

      {/* Gift card Popup */}

      <div>
        <Modal
          dialogClassName="modal-35w"
          show={giftCard}
          onHide={handleGiftCardClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1, zIndex: 9999 }}
          className="borderColor"
          // animation={false}
          backdrop="static"
          contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
        >
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleGiftCardClose}
            ></button>
          </div>
          <Modal.Body>
            <div className="w-100 mb-4 text-center">
              <strong className="">{sellerName} sent you gift</strong>
            </div>
            <p className="text-center">Congratulations! You've got a card as a gift!</p>
            <div className="w-100 d-flex justify-content-around mt-4">
              <TradeButtons
                style={{
                  backgroundColor: "#6458e5",
                }}
                onClick={() => {
                  tradeCrad(
                    "APPROVED",
                    () => handleGiftCardClose()                    
                  )
                }}
              >
                Approve gift
              </TradeButtons>
              <TradeButtons
                style={{
                  backgroundColor: "#6458e5",
                }}
                onClick={() => {
                  tradeCrad(
                    "REJECTED",
                    () => handleGiftCardClose()                    
                  )
                }}
              >
                Reject gift
              </TradeButtons>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Seller tab popup */}
      
      <div>
        <Modal
          dialogClassName="modal-35w"
          show={sellerDetails}
          onHide={handleSellerDetailsClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1, zIndex: 9999 }}
          className="borderColor"
          // animation={false}
          backdrop="static"
          contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
        >
          {waitRespons && (
            <>
              <WaitForResponse />
            </>
          )}
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleSellerDetailsClose}
            ></button>
          </div>
          <Modal.Body>
            <>

              <div className="w-100 text-center">{selectStatus == "NEGOTIATE" ? <strong>Negotiate Offer</strong> : leader?.status == "APPROVED" ? <strong>Approve buy offer</strong> :leader?.status == "REJECT" ? <strong>Reject buy offer</strong>:<strong>{leader?.transitionType == "BID" ? "Buy" : "Sell"} card offer</strong>}</div>

              <div className="w-100 text-center">                
                {leader?.lastUpdatedBy == userInfo?.uid ?
                  <p>current offer {userInfo?.uid == leader?.sellerUserId ? leader?.buyerRequest?.coin || leader?.coin : leader?.sellerRequest?.coin || leader?.coin} VTE</p> :
                  <p>current offer {leader?.coin} VTE</p>}
                {(leader?.status != "APPROVED" || leader?.status != "REJECT") &&
                  <p>
                    {/* {leader?.message} */}
                    {
                      leader?.lastUpdatedBy == userInfo?.uid ?
                      userInfo?.uid == leader?.sellerUserId ? leader?.buyerRequest?.message || leader?.message : leader?.sellerRequest?.message || leader?.message:
                      leader?.message
                    }
                  </p>
                }
              </div>

              <div className="d-flex justify-content-around flex-wrap">

                {
                  leader && leader?.status == "PENDING" &&
                  <select
                  className="SetInputSize"
                  aria-label="Default select example"
                  value={selectStatus}
                  onChange={(e) => {                    
                    if (e.target.value == "NEGOTIATE") {
                      setSelectTimer("");
                      setSelectStatus(e.target.value);
                      setDiscription("");
                      setCoinVal(0);
                      setErrors((prev: any) => ({ ...prev, selectStatus: "" }));
                    } else {
                      setSelectStatus(e.target.value);
                      setDiscription("");
                      setErrors((prev: any) => ({ ...prev, selectStatus: "" }));
                    }
                    // setSelectStatus(e.target.value);
                    // setDiscription("");
                    //   setErrors((prev: any) => ({ ...prev, selectStatus: "" }));                    
                  }}
                >
                  <option value="">Select Status</option>
                  <option value="NEGOTIATE">NEGOTIATE</option>
                  {leader?.status == "NEGOTIATE" &&<option value="REJECTED">REJECT</option>}
                  {leader?.lastUpdatedBy != userInfo?.uid && <option value="APPROVED">APPROVE</option>}
                </select>}
                {errors?.selectStatus && (
                  <p className="text-danger" style={{ fontSize: "12px" }}>
                    {errors?.selectStatus}
                  </p>
                )}
                {leader && (leader?.status == "PENDING" || selectStatus != "") &&
                <>
                {selectStatus != "REJECTED" && selectStatus != "APPROVED" && <>
                  <input
                    className="SetInputSize"
                    type="text"
                    name=""
                    placeholder="Enter VTE amount"
                    value={coinVal}
                      onChange={(e: any) => {
                        setErrors((prev: any) => ({ ...prev, coinVal:""}));
                        const newValue = e.target.value;
                        const sanitizedValue = newValue.replace(/[^0-9]/g, "");   
                        if (sanitizedValue?.length < 16) {
                          setCoinVal(sanitizedValue);
                        } 
                        setErrors((prev: any) => ({ ...prev, coinVal: "" }));
                    }}
                    id=""
                  />
                  {errors?.coinVal && (
                    <p className="text-danger" style={{ fontSize: "12px" }}>
                      {errors?.coinVal}
                    </p>
                  )}
                </>}
                {selectStatus != "REJECTED" && selectStatus != "APPROVED" && <>                
                <select
                  className="SetInputSize"
                  aria-label="Default select example"
                  value={selectTimer}
                  onChange={(e) => {
                    setSelectTimer(e.target.value);
                    setErrors((prev: any) => ({ ...prev, selectTimer: "" }));
                  }}
                >
                      <option value="">Select time</option>
                  <option value="Hour">1 Hour</option>
                  <option value="Day">1 Day</option>
                      <option value="Week">1 Week</option>
                      <option value="NO_LIMIT">No Limit</option>
                  <option value="Custom">Custom</option>
                </select>
                {errors?.selectTimer && (
                  <p className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.selectTimer}
                  </p>
                  )}
                </>}
                {/* </div> */}
                {selectStatus != "REJECTED" && selectStatus != "APPROVED" &&  selectTimer == "Custom" && (
                    <>
                      <input
                        className="SetInputSize"
                        type="datetime-local"
                        id="datetime"
                        value={selectedDate}
                        onChange={handleChange}
                        min={getCurrentDateTime()}
                      // onBlur={(event)=>handleValidation(event)}
                      />
                      {errors.selectedDate && (
                        <p className="text-danger" style={{ fontSize: "12px" }}>
                          {errors.selectedDate}
                        </p>
                      )}
                    </>
                )}                
                {leader?.transitionType == "BID" &&
                  <>
                    <select
                      className="SetInputSize"
                      aria-label="Default select example"
                      name=""
                      id=""
                      value={discription}
                      onChange={(e) => {
                        setDiscription(e.target.value);
                        setErrors((prev: any) => ({ ...prev, discription: "" }));
                      }}
                    >
                      <option value="">Select message</option>
                      {dropdwonMsg && selectStatus && dropdwonMsg[selectStatus]?.map((item: any) => {
                        return (<>
                          <option value={item}>{item}</option>
                        </>)
                      })}
                    </select>
                    {errors.discription && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors.discription}
                      </p>
                    )}
                  </>}
                
                {leader?.transitionType == "ASK" &&
                  <>
                    <select
                      className="SetInputSize"
                      aria-label="Default select example"
                      name=""
                      id=""
                      value={discription}
                      onChange={(e) => {
                        setDiscription(e.target.value);
                        setErrors((prev: any) => ({ ...prev, discription: "" }));
                      }}
                    >
                      <option value="">Select message</option>
                      {dropdwonMsgAsk && selectStatus && dropdwonMsgAsk[selectStatus]?.map((item: any) => {
                        return (<>
                          <option value={item}>{item}</option>
                        </>)
                      })}
                    </select>
                    {errors.discription && (
                      <p className="text-danger" style={{ fontSize: "12px" }}>
                        {errors.discription}
                      </p>
                    )}
                    </>}
                </>}
              </div>

              {errorMessage != "" && (
                <div className="text-center">
                  <p className="text-danger" style={{ fontSize: "12px" }}>
                    {errorMessage}
                  </p>
                </div>
              )}
              <div className="w-100 d-flex justify-content-center mt-4">
                <Buttons.Primary onClick={() => {
                  if (selectStatus == "NEGOTIATE") {
                    handleFormSubmit()
                  } else {
                    handleRejectAndApproved()
                  }
                }}>
                  {selectStatus == "NEGOTIATE" ? "Send offer" : selectStatus == "APPROVED" ? "Approve" : selectStatus == "REJECTED" ? "Reject" :"Send offer"}                  
                </Buttons.Primary>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default BuyerSellerPopup;
