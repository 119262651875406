/** @format */

import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { css } from "styled-components";
import {
  Gradient1,
  Gradient2,
  Gradient3,
  Gradient4,
  PoppinsMediumWhite12px,
} from "../../styledMixins";
import {
  isAdmin,
  isCoinsPairs,
  isCommanPage,
  isProfile,
  isSinglePage,
} from "../../common/utils/title";

export const stage = 1;

// @ts-ignore
export const isV1 = () => stage === 0;
// @ts-ignore
export const isV2 = () => stage === 2;
// @ts-ignore
export const isV3 = () => stage === 3;

export type Pathname = { pathname: string; login?: string; width?: number };

export const getBackground = (props: Pathname) => {
  const purple = "var(--color-160133) 0% 0% no-repeat padding-box";
  const blueViolet = "var(--blue-violet) 0% 0% no-repeat padding-box";
  const light = "var(--light-purple) 0% 0% no-repeat padding-box";

  if (props.width && props.width > 979) {
    return purple;
  }

  if (props.login === "true") {
    return light;
  }

  if (isSinglePage(props.pathname, "coins")) {
    return purple;
  }

  if (isSinglePage(props.pathname, "pairs")) {
    return purple;
  }

  if (isProfile(props.pathname)) {
    return blueViolet;
  }

  return "";
};

const getHeight = (props: Pathname) => {
  if (props.login === "true" || isAdmin(props.pathname)) {
    return "100%";
  }

  if (
    isSinglePage(props.pathname, "coins") ||
    isProfile(props.pathname) ||
    isSinglePage(props.pathname, "pairs")
  ) {
    return "233px";
  }
  if (
    props.pathname.replace("/", "").toLowerCase() == "about" ||
    props.pathname.replace("/", "").toLowerCase() == "privacy" ||
    props.pathname.replace("/", "").toLowerCase() == "contact" ||
    props.pathname.replace("/", "").toLowerCase() == "knowledgehub" ||
    props.pathname.replace("/", "").toLowerCase() == "terms-and-condition" ||
    props.pathname.replace("/", "").toLowerCase() == "Ambassador" ||
    props.pathname.replace("/", "").toLowerCase() == "foundations"
  ) {
    return "auto";
  }
  return "100vh";
};

export const isHomeBg = (pathname: string) => {
  const pathArray = pathname.split("/");
  return [
    "/",
    "/influencers",
    `/influencers/${pathArray[2]}`,
    "/coins",
    "/pairs",
    "/WallOfFame",
    "/influencerUrl",
    // "/progress-bar",
  ].includes(pathname);
};

export const isProgressBar = (pathname: string) => {
  return ["/progress-bar"].includes(pathname);
};

export const AppContainer = styled(Container)`
  ${(props: Pathname) =>
    isProgressBar(props.pathname)
      ? Gradient4
      : isHomeBg(props.pathname)
      ? Gradient1
      : isCoinsPairs(props.pathname)
      ? Gradient3
      : isCommanPage(props.pathname)
      ? css`
          background: white;
        `
      : css`
          background: rgb(99, 82, 232);
          background: transparent
            linear-gradient(180deg, var(--color-6352e8) 0%, #3712b3 100%) 0% 0%
            no-repeat padding-box;
          background: ${window.screen.width < 767 ? "#d4d0f3" : "#d4d0f3"};
        `};
  ${PoppinsMediumWhite12px};
  opacity: 1;
  min-height: ${(props: Pathname) =>
    isSinglePage(props.pathname, "coins") ||
    isProfile(props.pathname) ||
    isSinglePage(props.pathname, "pairs")
      ? ""
      : "100vh"};
  height: ${(props: Pathname) => getHeight(props)};
  border-radius: ${(props: Pathname) =>
    isSinglePage(props.pathname, "coins") ||
    isProfile(props.pathname) ||
    isSinglePage(props.pathname, "pairs")
      ? "0px 0px 87px 0px"
      : ""};
  padding: 0;
  // width: 100vw;
`;

export const HomeContainer = styled(Container)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-style: normal;
  width: 100%;
  min-width: ${(props: { width?: number }) =>
    props.width && props.width > 979 ? "233px" : undefined};
  margin-top: ${(props: { width?: number }) =>
    props.width && props.width > 979 ? "170px" : undefined};

  @media (max-width: 768px) {
    height: 100px;
  }

  & h2 {
    font-size: var(--font-size-xxxl);
    line-height: 29px;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 8px;

    & span {
      display: ${(props: { width?: number }) =>
        `${props.width && props.width > 969 ? "inline" : "block"}`};
    }
  }

  & p {
    font-size: var(--font-size-xl);
    line-height: 20px;
    margin-bottom: 15px;
  }
`;

export const H1 = styled.h1`
  color: var(--color-6352e8);
  text-align: left;
  font: normal normal medium 23px/34px Poppins;
  font-size: ${(props: { desktop?: string }) =>
    `${props.desktop === "true" ? 20 : 23}px`};
  letter-spacing: 0;
  text-shadow: 0 3px 6px #00000053;
  opacity: 1;
`;

type PageContainerProps = {
  color?: string;
  radius?: number;
  shadow?: string;
};
export const PageContainer = styled(Container)`
  background: ${(props: PageContainerProps) =>
    `${props.color || "var(--color-ffffff)"} 0 0% no-repeat padding-box`};
  box-shadow: ${(props: PageContainerProps) =>
    props.shadow || "0 3px 6px #00000029"};
  border-radius: ${(props: PageContainerProps) =>
    `0 0 ${props.radius || 0}px 0`};
  opacity: 1;
  padding: 0;
  z-index: 20000;
`;

export const ZoomCss = css`
  // opacity: 1;
  // position: fixed;
  // top:0;
  // left:0;
  // right:0;
  // bottom:0;
  // width: 100%;
  // height: 150vh;
  // left: 50%;
  // transform: translate(-50%, -20%);
  // transition: opacity .2s;
  // background-color: rgba(0,0,0,0.8);

  // display: flex;
  // justify-content: center;
  // align-items: center;
  // transition:  opacity 1s;

  // border:1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardContainer = styled.div`
  background: var(--color-160133) 0 0% no-repeat padding-box;
  height: 190px;
  padding: 0;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 0 0 87px 0;
`;

export const getSubdomain = (admin?: boolean) => {
  if (admin) {
    return true;
  }
  const urlParts = new URL(window.location.href).hostname.split(".");
  return admin && urlParts.length === 3 ? urlParts[0] : undefined;
};
