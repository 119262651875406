import React from "react";

const Notificationsnew = () => (
  <svg width="22" height="29" viewBox="0 0 31 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1847_23387)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.61904 30.3999H15.6438L23.0582 30.3981C23.5779 30.3981 24.0977 30.3992 24.6175 30.4003C25.6571 30.4026 26.6967 30.4048 27.7362 30.3981C29.39 30.387 30.5382 29.1627 30.4086 27.5561C30.3438 26.7601 29.8938 26.1871 29.315 25.6694C27.3492 23.907 26.213 21.7089 26.0472 19.0788C25.9838 18.0792 25.9869 17.0758 25.99 16.0723C25.9917 15.5238 25.9934 14.9753 25.9843 14.4274C25.9886 13.853 25.9533 13.2789 25.8787 12.7093C25.1889 8.26525 21.8396 4.87047 17.381 4.08004C17.18 4.04872 17.1078 3.9741 17.1124 3.77235C17.1263 3.31173 17.1291 2.85111 17.1124 2.39049C17.0989 2.01244 16.9385 1.65437 16.6649 1.39167C16.3913 1.12898 16.0259 0.982178 15.6457 0.982178C15.2655 0.982178 14.9001 1.12898 14.6265 1.39167C14.3529 1.65437 14.1924 2.01244 14.1789 2.39049C14.1641 2.82623 14.1595 3.26382 14.1789 3.69957C14.19 3.95936 14.1067 4.04596 13.8419 4.09018C9.06381 4.88521 5.40433 9.09529 5.31173 13.9069C5.29153 14.9427 5.29484 15.979 5.29814 17.0154C5.29938 17.4041 5.30062 17.7928 5.30062 18.1815C5.29599 19.9991 4.86912 21.7052 3.94313 23.2797C3.39043 24.2251 2.68281 25.072 1.84949 25.7854C1.04574 26.4736 0.704977 27.3368 0.929065 28.3732C1.20038 29.6307 2.2384 30.3999 3.61904 30.3999ZM14.3828 36.0827C14.5973 36.145 14.8118 36.2074 15.0219 36.2776H16.2683C16.3185 36.2525 16.3713 36.2327 16.4257 36.2187C18.0971 35.9626 19.3888 35.1187 20.3148 33.7203C20.6862 33.1673 20.9402 32.5448 21.0612 31.8907H10.2364C10.2384 31.9451 10.2443 31.9993 10.254 32.0528C10.6707 33.6189 11.5606 34.8442 13.0125 35.5867C13.439 35.8084 13.911 35.9456 14.3828 36.0827Z" fill="url(#paint0_linear_1847_23387)"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_1847_23387" x1="114.158" y1="166.606" x2="-45.278" y2="110.593" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6318FF"/>
      <stop offset="1" stop-color="#FFF6FF"/>
    </linearGradient>
    <clipPath id="clip0_1847_23387">
      <rect width="31" height="37.0278" fill="white" transform="translate(0 0.972168)"/>
    </clipPath>
  </defs>
</svg>
);

export default Notificationsnew;
