import React from "react";

const Following = () => {
  return (
    <svg
      id="following"
      xmlns="http://www.w3.org/2000/svg"
      width="17.737"
      height="15.689"
      viewBox="0 0 17.737 15.689"
    >
      <path
        id="Path_3501"
        data-name="Path 3501"
        d="M9.547,3.371a2.8,2.8,0,1,1-2.8,2.8,2.8,2.8,0,0,1,2.8-2.8m0-1.121A3.922,3.922,0,1,0,13.47,6.172,3.922,3.922,0,0,0,9.547,2.25Z"
        transform="translate(-3.944 -2.25)"
        fill="#6352e8"
      />
      <path
        id="Path_3502"
        data-name="Path 3502"
        d="M13.457,26.974H12.336v-2.8a2.8,2.8,0,0,0-2.8-2.8H6.172a2.8,2.8,0,0,0-2.8,2.8v2.8H2.25v-2.8A3.922,3.922,0,0,1,6.172,20.25H9.534a3.922,3.922,0,0,1,3.922,3.922Z"
        transform="translate(-2.25 -11.285)"
        fill="#6352e8"
      />
      <g id="check" transform="translate(10.281 -0.715)" fill="#6352e8">
        <path
          d="M2.366,9.67,6.879,5.156l.577.6-3.3,3.3L2.366,10.843,0,8.477l.6-.6Z"
          stroke="none"
        />
        <path
          d="M 2.366183042526245 10.84252166748047 L 3.106536951236194e-06 8.47655200958252 L 0.5965831279754639 7.879961967468262 L 2.365972995758057 9.669502258300781 L 6.879483222961426 5.156002044677734 L 7.456122875213623 5.75258207321167 C 7.456122875213623 5.75258207321167 5.429403305053711 7.779311656951904 4.156913280487061 9.051792144775391 C 2.884433031082153 10.32428169250488 2.366183042526245 10.84252166748047 2.366183042526245 10.84252166748047 Z"
          stroke="none"
          fill="#6352e8"
        />
      </g>
      <g
        id="check-2"
        data-name="check"
        transform="translate(10.281 -0.958)"
        fill="#6352e8"
      >
        <path
          d="M2.283,9.451,6.637,5.156l.556.568L4.01,8.863l-1.728,1.7L.188,8.518.763,7.95Z"
          stroke="none"
        />
        <path
          d="M 2.282802104949951 10.56712341308594 L 0.1876221597194672 8.518003463745117 L 0.7631821632385254 7.950313568115234 L 2.282592058181763 9.450913429260254 L 6.637032032012939 5.156003475189209 L 7.193362236022949 5.72369384765625 C 7.193362236022949 5.72369384765625 5.238062381744385 7.652263641357422 4.010422229766846 8.863123893737793 C 2.782782077789307 10.07398414611816 2.282802104949951 10.56712341308594 2.282802104949951 10.56712341308594 Z"
          stroke="none"
          fill="#6352e8"
        />
      </g>
    </svg>
  );
};

export default Following;
