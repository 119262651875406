import React, { useContext, useEffect, useRef, useState } from "react";
import UserContext from "Contexts/User";
import AppContext from "Contexts/AppContext";
import styled, { css } from "styled-components";
import { Buttons } from "Components/Atoms/Button/Button";
// @ts-ignore
import VoteBoosterSound from '../../assets/sounds/VoteBooster.mp3';
import useSound from "use-sound";

import './BoosterVoteButton.css';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

export const colors = ["#6352e8", "white"];
const size = css`
  width: 150px;  
`;
const Option = css`
  border: 1px solid var(--blue-violet);  
  background:#6352e8;
  color:white;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  box-shadow: rgb(67 47 229) 0px 4px 1px, rgba(0, 0, 0, 0.22) 0px 6px 12px;
`;

const I = styled.i`
  border-radius: 50%;
  position: absolute;
  font-size: 11px;
  top: -10px;
  right: -25px;
  font-weight:300;
  color: #6352E8; 
  width: 16px;
  height: 16px;
  text-align: center;  
`;

const Option0 = styled(Buttons.RadiusTopRight)`
  ${size};
  ${Option};
  flex-direction: column;
  border-radius:5px;  
  font-size:15px;
  animation: bull_shake_modified 500ms infinite;
  // &:active {
  //   position: relative;
  //   top: 2px;
  //   box-shadow: rgb(67 47 229) 0px 3px 1px, rgba(0, 0, 0, 0.22) 0px 6px 12px;
  // }   
`;
const BoosterVoteButton = () => {
    const { userInfo } = useContext(UserContext);
  const { boosterVote, setBoosterVote, boosterButtonBlink, setBoosterButtonBlink, } = useContext(AppContext);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("X1 booster");
  const [voteNumber, setVoteNumber] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [addAnimation, setAddAnimation] = useState(false);
  const [debounceAnimation, setDebounceAnimation] = useState(false);
  const [boosterValueArray, setBoosterValueArray] = useState<any>([]);
  const [buttonCss, setButtonCss] = useState<any>(true);
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const [handleSoundClick] = useSound(VoteBoosterSound, { interrupt: true });
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
  const boosterValue = [
    {Limit: 1,value:1,},
    {Limit: 10,value: 2,},
    {Limit: 20,value: 3,},
    {Limit: 50,value: 10,},
    {Limit: 150,value: 15,},
    {Limit: 250,value: 25,},
    {Limit: 500,value: 50,},
    {Limit: 1000,value: 100,},
  ]
        
  useEffect(() => {
        setVoteNumber(
            Number(userInfo?.voteValue || 0) +
            Number(userInfo?.rewardStatistics?.extraVote || 0) 
    );   
    }, [userInfo?.voteValue, userInfo?.rewardStatistics?.extraVote])
  
    useEffect(() => {
      let value = voteNumber;
      // let boosterArray = []
      
      // if (value > 0) {
      //   boosterArray.push(1);
      // }
      // if (value > 9) {
      //   boosterArray.push(3);
      // }
      // if (value > 15) {
      //   boosterArray.push(5);
      // }
      // if (value > 25) {
      //   boosterArray.push(10);
      // }
      // if (value > 50) {
      //   boosterArray.push(15);
      // }
      // if (value > 75) {
      //   boosterArray.push(25);
      // }      
      // if (value > 125) {
      //   boosterArray.push(50);
      // }
      // if (value > 250) {
      //   boosterArray.push(100);
      // }
      // // if (value > 1000) {
      // //   boosterArray.push(100);
      // // }
      // const selectIndexValue = boosterArray.indexOf(boosterVote) == -1 ? 0 : boosterArray.indexOf(boosterVote)
      // setCurrentIndex(boosterArray.indexOf(boosterVote) == -1 ? 0 : boosterArray.indexOf(boosterVote));      
      // setBoosterValueArray(boosterArray);

      const getBoosterArray = async (value: number) => {

        const docRef = doc(db, "settings", "booster");    
          const boosterData = await getDoc(docRef);
          const boosterValue = boosterData.data();   
          console.log(boosterValue,"boosterValue")
          const boosterArray: number[] = [];
          if (boosterValue && boosterValue?.BOOSTERS) {
            boosterValue?.BOOSTERS.forEach((booster: any) => {
              console.log(booster,"boostercheck")
              if (value > booster?.minVotes) {
                boosterArray.push(booster?.multiplier);
              }
            });
          } 
          setCurrentIndex(boosterArray.indexOf(boosterVote) == -1 ? 0 : boosterArray.indexOf(boosterVote));      
          setBoosterValueArray(boosterArray);
          console.log(boosterArray,"boosterArray")     
        };
          
          // Usage Example:      
          getBoosterArray(value);
    
    }, [voteNumber])
      
  
  useEffect(() => {    
    if (boosterValueArray.length && !boosterValueArray.includes(boosterVote) && boosterVote > 1) {
      setBoosterVote(boosterValueArray[boosterValueArray.length - 1]);
      setCurrentIndex(boosterValueArray.length - 1);
    }
  }, [boosterValueArray])

  const aniRef = useRef<any>();

  useEffect(() => {
    if (debounceAnimation) {
      clearTimeout(aniRef.current);


      aniRef.current = setTimeout(() => {
        setAddAnimation(true)
        setDebounceAnimation(false)
      }, 300);

      // debounceAnimation, setDebounceAnimation

      return () => clearTimeout(aniRef.current);
    }

  }, [debounceAnimation])
  
  
    const handleSelect = () => {
      //   setSelectedValue(text);
      // setIsOpen(false); // Close the dropdown after selecting an option
      setAddAnimation(false)
      setBoosterButtonBlink(false)
      setDebounceAnimation(true)
      setCurrentIndex((prevIndex) => (prevIndex + 1) % boosterValueArray.length);      
      setBoosterVote(boosterValueArray[(currentIndex + 1) % boosterValueArray.length])
      handleSoundClick()
    
    };
    const closeDropdown = (e:any) => {
        if (!e.target.closest(".dropdown")) {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener("click", closeDropdown);
        return () => {
            window.removeEventListener("click", closeDropdown);
        };
    }, []);

    return (
        <div className="">            
        <div style={{ position: 'relative',zIndex:"99999" }}>
          {voteNumber > 0 &&  <I className="bi bi-info-circle"
            onMouseDown={(e) => { 
              e.stopPropagation();
              setTooltipShow(false)
            }}
            onMouseUp={(e) => {   
              e.stopPropagation();
              setTooltipShow(true)
            }}
            onMouseEnter={(e) => {
              e.stopPropagation();
              setTooltipShow(true)
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setTooltipShow(false)
            }}
          ></I>}

          {
            tooltipShow &&
            <div
              style={{
                display: "relative"
              }}
            >
              <div className="newtooltip"
                style={{

                  // marginLeft: "16%",
                  // marginTop:"0%",
                  width: `${window.screen.width > 767 ? "150%" : "150%"}`,
                  marginLeft: `${window.screen.width > 767 ? "20px" : "-20%"}`,
                  marginTop: `${window.screen.width > 767 ? "15%" : "15%"}`,
                }}
              >
                {/* <p>User type level</p> */}
                <p className="mt-3 lh-base"
                  style={{
                    textAlign: "left"
                  }}
                  >Boosters allow you to multiply your rewards by increasing your vote power, with a corresponding deduction in votes.</p>                
              </div>
            </div>
          }

        </div>
          {voteNumber > 0 &&
          <div className={`${boosterButtonBlink ? "blink-button" : ""} `}>
            <Option0
              className={`p-3  svg-button  ${addAnimation ? "confetti-button animate" : ""}`}              
              onClick={(e) => {  
                  e.stopPropagation();
                  handleSelect()
                }}            
            style={{
              top: `${addAnimation ? "2px":""}`,
              boxShadow: `${addAnimation ? "rgb(67 47 229) 0px 3px 1px, rgba(0, 0, 0, 0.22) 0px 6px 12px" :"rgb(67 47 229) 0px 4px 1px, rgba(0, 0, 0, 0.22) 0px 6px 12px"}`
            }}
            >
            {`Boost X ${boosterValueArray[currentIndex] > 1 ? boosterValueArray[currentIndex] : boosterVote} `}
          </Option0>
            </div>          
          }
            </div>
        
    );
};

export default BoosterVoteButton;
