import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../Contexts/User";
import { httpsCallable } from "firebase/functions";
import { firestore, functions } from "../../firebase";
import Tabs from "./Tabs";
import { useTranslation } from "../../common/models/Dictionary";
import { capitalize } from "lodash";
import { follow, Leader, unfollow } from "../../Contexts/CoinsContext";
import UserCard from "../Users/UserCard";
import { toFollow } from "../../common/models/User";
import firebase from "firebase/compat/app";
import AppContext from "../../Contexts/AppContext";
import { texts } from "../LoginComponent/texts";
import { collection, getDocs, query, where } from "firebase/firestore";
export type Follower = {
  username: string;
  id: string;
};

export const getLeaderUsersByIds = httpsCallable<{}, Leader[]>(
  functions,
  "getLeaderUsersByIds"
);

export const getUsers = ({
  users,
  setUsers,
  setIsLoading,
}: {
  users?: string[];
  setIsLoading?: any;
  setUsers: (newUsers: Leader[]) => void;
}) => {
  try {
    setIsLoading(true);
    users?.length &&
      getLeaderUsersByIds({ userIds: users }).then((u) => {
        setUsers(u.data);
        if (setIsLoading) {
          setIsLoading(false);
        }
        return u.data;
      });
  } catch (e) {
    setUsers([] as Leader[]);
    if (setIsLoading) {
      setIsLoading(false);
    }
  }
};

const FwFollow = () => {
  const { user, userInfo } = useContext(UserContext);
  const [fwUserInfo, setFwUserInfo] = useState<any>();
  // const { followerUserId } = useContext(AppContext)
  const followerUserId = localStorage.getItem("followerId");
  const translate = useTranslation();
  const [leaders, setLeaders] = useState<Leader[] | undefined>();
  const [subscribers, setSubscribers] = useState<Leader[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const getFollowerData = () => {
  //   const getCollectionType = firebase
  //     .firestore()
  //     .collection("users")
  //     .where("uid", "==", followerUserId)
  //   getCollectionType.get()
  //     .then((snapshot) => {
  //       snapshot.docs?.map(doc => setFwUserInfo(doc.data()))
  //     }).catch((error) => {
  //       console.log(error, "error");
  //     });
  // }
  const getFollowerData = async () => {
    const usersCollectionRef = collection(firestore, "users");
    const followerQuery = query(
      usersCollectionRef,
      where("uid", "==", followerUserId)
    );

    try {
      const snapshot = await getDocs(followerQuery);
      snapshot.docs?.forEach((doc: any) => setFwUserInfo(doc.data()));
    } catch (error) {
      console.error("Error fetching follower data:", error);
    }
  };
  useEffect(() => {
    if (fwUserInfo?.leader && fwUserInfo?.leader?.length > 0) {
      getUsers({
        users: fwUserInfo?.leader,
        setUsers: setLeaders,
        setIsLoading,
      });
    }
    if (fwUserInfo?.subscribers && fwUserInfo?.subscribers?.length > 0) {
      getUsers({
        users: fwUserInfo?.subscribers,
        setUsers: setSubscribers,
        setIsLoading,
      });
    }
    getFollowerData();
  }, [fwUserInfo?.leader?.length, fwUserInfo?.subscribers?.length]);

  return (
    <Tabs
      defaultActiveKey="following"
      id="profile-follow"
      onSelect={() => {}}
      tabs={[
        {
          eventKey: "following",
          title: capitalize(translate("following")),
          pane: (
            <div>
              {isLoading && (
                <div
                  style={{
                    position: "fixed",
                    height: "100%",
                    // border: "2px solid red",
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    // top: '0px',
                    backgroundColor: "rgba(0,0,0,0.4)",
                    right: "0px",
                    bottom: "0px",
                    zIndex: "9999",
                    overflow: "hidden",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <span
                    className="loading position-fixed"
                    style={{
                      color: "white",
                      zIndex: "2220px",
                      fontSize: "1.5em",
                      top: `${window.scrollY + 300}px`,

                      // marginTop: `${window.screen.width > 767 ? "50px" : "240px"}`
                    }}
                  >
                    {texts?.waitForIt}
                  </span>
                </div>
              )}

              {leaders && leaders?.length > 0 ? (
                leaders?.map((u, i) => {
                  return (
                    <div
                      className="mb-2"
                      style={{ maxWidth: "85vw", margin: "auto" }}
                      key={i}
                    >
                      <UserCard
                        leader={u}
                        checked={!!userInfo?.leader?.includes(u.userId)}
                        setChecked={() => {
                          setIsLoading(true);
                          user &&
                            follow(
                              u,
                              user,
                              toFollow(userInfo?.leader || [], u.userId),
                              setIsLoading
                            );
                        }}
                      />
                    </div>
                  );
                })
              ) : !isLoading && !leaders ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    justifyItems: "center",
                    textTransform: "uppercase",
                  }}
                >
                  No record found
                </div>
              ) : null}
            </div>
          ),
        },
        {
          eventKey: "followers",
          title: capitalize(translate("followers")),
          pane: (
            <>
              {isLoading && (
                <div
                  style={{
                    position: "fixed",
                    height: "100%",
                    // border: "2px solid red",
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    // top: '0px',
                    backgroundColor: "rgba(0,0,0,0.4)",
                    right: "0px",
                    bottom: "0px",
                    zIndex: "9999",
                    overflow: "hidden",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <span
                    className="loading position-fixed"
                    style={{
                      color: "white",
                      zIndex: "2220px",
                      fontSize: "1.5em",
                      top: `${window.scrollY + 300}px`,

                      // marginTop: `${window.screen.width > 767 ? "50px" : "240px"}`
                    }}
                  >
                    {texts?.waitForIt}
                  </span>
                </div>
              )}
              {subscribers && subscribers.length > 0 ? (
                subscribers.map((s, i) => {
                  return (
                    <>
                      <div
                        className="mb-2"
                        style={{ maxWidth: "85vw", margin: "auto" }}
                        key={i}
                      >
                        <UserCard
                          leader={s}
                          checked={!!userInfo?.leader?.includes(s.userId)}
                          setChecked={() => {
                            setIsLoading(true);
                            user &&
                              follow(
                                s,
                                user,
                                toFollow(userInfo?.leader || [], s.userId),
                                setIsLoading
                              );
                          }}
                        />
                      </div>
                    </>
                  );
                })
              ) : !isLoading && !subscribers.length ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    justifyItems: "center",
                    textTransform: "uppercase",
                  }}
                >
                  No record found
                </div>
              ) : null}
            </>
          ),
        },
      ]}
    />
  );
};

export default FwFollow;
