import Avatar from "Components/Users/Avatar";
import { TradeButton, TradeText } from "Components/Users/UserCard";
import UserContext from "Contexts/User";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SellerPopup from "./SellerPopup";
import axios from "axios";
import { texts } from "Components/LoginComponent/texts";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db, firestore } from "../../../firebase";
import BuyerSellerPopup from "./BuyerSellerPopup";
import { Modal } from "react-bootstrap";
import BuyerPopup from "./BuyerPopup";
import CardSalePopup from "./CardSalePopup";
import { showToast } from "App";
import { ToastType } from "Contexts/Notification";
import { confirmApproved, confirmApprovedGift, confirmDelete, confirmDeleteGift, confirmReject, confirmRejectGift, convertTime, getUserData } from "common/utils/helper";
import Swal from "sweetalert2";
import Avatars from "assets/avatars/Avatars";
import moment from "moment";
import NFTTradeCard from "common/NFTCard/NFTTradeCard";
import NFTTradeCoin from "common/NFTCard/NFTTradeCoin";
import ShareModal from "Components/shareModal";
import WaitForResponse from "./WaitForResponse";
import { useLocation, useNavigate } from "react-router-dom";

const TradeButtons = styled.button`
  color: white;
  font-size: 12px;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
`;

const TradeText2 = styled.p`
  color: #6352e8;
  font-size: 9px;
  // padding: 10px 10px;
  border: none;   
  cursor:pointer;
`;

const DeleteIcon = styled.div`
    font-size:16px;
    color:#d33;
`;

const InfoDiv = styled.div`
    font-size:12px;  
    font-weight:400;
    margin-left:15px;
`;
function TradeList() {
  const { user, userInfo } = useContext(UserContext);
  const [openModal, setOpenModal] = useState<any>("");
  const [addIndex, setAddIndex] = useState<any>("");
  const [allTradeList, setAllTradeList] = useState<any>([]);
  const [allNegotiateList, setAllNegotiateList] = useState<any>([]);
  // const [allBuyList, setAllBuyList] = useState<any>([]);
  // const [allSellList, setAllSellList] = useState<any>([]);
  // const [allGiftList, setAllGiftList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [waitRespons, setWaitRespons] = useState(false);
  const [tradeDetails, setTradeDetails] = useState<any>([]);  
  const [editRequest, setEditRequest] = useState<any>(false);  
  const [currentTrade, setCurrentTrade] = useState([]);
  const [popUpClose, setPopUpClose] = useState(false);
  const [requestsPopup, setRequestsPopup] = useState(false);
  const [winnerCard, setWinnerCard] = useState<any>([]);

  const getTabValue = new URLSearchParams(useLocation().search);  
  const tabID = Number(getTabValue.get('tabID'));
  const [activeTab, setActiveTab] = useState(0); 
  const [allRequestsList, setAllRequestsList] = useState <any>([]); 
  const winnerCardData = useRef<any>();
  const [cardModalShow, setCardModalShow] = useState<any>(false);
  const [approvedCard, setApprovedCard] = useState<any>();
  const [coinModelShow, setCoinModelShow] = useState<any>(false);
  const [shareModalShow, setShareModalShow] = React.useState(false);
  const [showListData, setShowListData] = React.useState("");
  const [error, setError] = React.useState(' ');
  
  const url = `${document.location.protocol}//${document.location.host
    }/profile/CollectiblesAlbum?collectionName=${approvedCard?.cardDetails?.winData?.firstRewardCardCollection?.replace(
      " ",
      "-"
    )}&cardName=${approvedCard?.cardDetails?.winData?.firstRewardCard?.replace(
      " ",
      "-"
    )}`;
  
  const ShowCount = styled.div`
    width: 15px;
    height: 15px;
    position: absolute;    
    top: -11px;
    right: -8px;
    font-size: 8px;
    background: #db3636;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
`;
  
  const shareText = "I won this unique card! Join the Parliament and win with me.";
  const handleCardClose = () => setCardModalShow(false);
  const handleCoinClose = () => setCoinModelShow(false);
  const navigate = useNavigate(); 
  
  console.log(activeTab,"checkActiveTab")

    const getCardNameById = async (cardId: any) => {
        const q = query(
            collection(db, 'cardsDetails'),
            where('cardId', '==', cardId),
            limit(1)
        );
        const usersSnapshot = await getDocs(q);
        const existingUsernames = usersSnapshot.docs.map((doc:any) => doc.data());
        const isUsernameTaken = await existingUsernames[0]?.cardName;
        console.log(isUsernameTaken,"isUsernameTaken")
        return isUsernameTaken
    };
  

  const fetchData = async (index:number) => {
    setActiveTab(index); 
    console.log(index,"indexcheck")
    if (index == 3) NegotiateData(index);
    if (index != 3)
    setAllTradeList([])
    const type = index==0?"BID":index==1?"ASK":index==2?"GIFT":"BID"
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/cardTrading/getOpenTradeOnTransition/${userInfo?.uid}/${type}`
      );      
      let data = response?.data?.result;
      let listdata: any = []; 
      setAllTradeList(response?.data?.result)      
    } catch (error) {      
      console.log(error);      
    } finally {      
      setIsLoading(false);      
    }
  };

  const NegotiateData = async (index:number) => {
    setActiveTab(index); 
    // setAllTradeList([])
    const type = ""
    setIsLoading(true);
    const NegotiateList:any=[]
    try {            
      const response = await axios.get(`/cardTrading/getAllNegotiatedTradeOnAccount/${userInfo?.uid}/${"ASK"}`);      
      let data = response?.data?.result;
      console.log(response?.data?.result,"response?.data?.result")
      
      const response2 = await axios.get(`/cardTrading/getAllNegotiatedTradeOnAccount/${userInfo?.uid}/${"BID"}`);
      let data2 = response2?.data?.result;
      console.log(response2?.data?.result, "response?.data?.result") 

      setAllNegotiateList([...data, ...data2]);      
      setIsLoading(false);      
    } catch (error) {      
      console.log(error);      
      setIsLoading(false);      
    }     
  };


  useEffect(() => {
    let isMounted = true;

    if (userInfo?.uid && isMounted) {      
      fetchData(tabID || 0);
    }
    return () => {
      isMounted = false;
    };
  }, [userInfo?.uid, popUpClose]);
 
  const handleAllRequestsShow = () => setRequestsPopup(true);
  const handleAllRequestsClose = () => {
    setRequestsPopup(false);    
  };

  const viewAllRequests = async (allItem: any) => {
    try {
      const response = await axios.get(`/cardTrading/getInitiatedTradeForOpenAccount/${allItem?.id}`)
      let data = response?.data?.result;
      let listdata: any = [];
      const updatedArray = await Promise.all(
        data?.map(async (item: any) => {
          const newKeyValue = await getCardNameById(item?.cardId);
          const userDataInfo = await getUserData(item.transitionType == "ASK" ? item?.buyerUserId : item?.sellerUserId);          
          listdata.push({ ...item, cardName: newKeyValue, userName: userDataInfo?.userName, userIamge: userDataInfo?.avatar});
        })
      );
      setTradeDetails(allItem)
      setAllRequestsList(listdata)
      handleAllRequestsShow()
      setOpenModal(""); 
    } catch (error) {
      console.log(error)
    }
    
  }  



  const submitTradeData = async (
    data: any,    
    callBack: any,
    isEdit?: any,
    leaderData?: any,
    getErrorMessage?: any,
  ) => {    
    let dataPayload = {
      ...data,      
      sellerUserId: leaderData?.sellerUserId,
      buyerUserId: leaderData?.buyerUserId,
      cardId: leaderData?.cardId,
      serialNumber: leaderData?.serialNumber,
      sellerUserName: leaderData?.sellerUserName || "",
      lastUpdatedBy: userInfo?.uid, 
    }
    
    console.log(dataPayload, "alldatadataPayload")
    try {
      await axios.put(`/cardTrading/updateOpenTrade/${tradeDetails?.id || tradeDetails?.accountId}`, dataPayload).then((res) => {
          if (res.status === 200) {
            console.log(res, "get full response")
            if (callBack) callBack();
            showToast(res?.data?.message, ToastType.SUCCESS);
            winnerCardData.current = ""
            fetchData(activeTab)            
          }
        }).catch(err => {
          console.log(err)
          setCardModalShow(false);
          console.log(openModal, "saleCardDetails")
          getErrorMessage(err?.response?.data?.message)   
          fetchData(activeTab)
        })
    } catch (error) {      
      setCardModalShow(false);
      // @ts-ignore
      getErrorMessage(error?.response?.data?.message)
      console.log(error, "err?.response?.data?.message")
      fetchData(activeTab)
    }   
  }

  const UpdateBuyerData = async (
    data: any,    
    callBack: any,
    isEdit?: any,
    getErrorMessage?: any,
    leaderData?: any,
  ) => {    
    let dataPayload = {
      ...data,      
      sellerUserId: leaderData?.sellerUserId,
      buyerUserId: leaderData?.buyerUserId,
      cardId: leaderData?.cardId,
      serialNumber: leaderData?.serialNumber,
      sellerUserName: leaderData?.sellerUserName || "",
      lastUpdatedBy: userInfo?.uid, 
    }
    
    console.log(dataPayload, "alldatadataPayload")
    try {
      await axios.put(`/cardTrading/updateOpenTrade/${tradeDetails?.id || tradeDetails?.accountId}`, dataPayload).then((res) => {
          if (res.status === 200) {
            console.log(res, "get full response")
            if (callBack) callBack();
            showToast(res?.data?.message, ToastType.SUCCESS);
            winnerCardData.current = ""
            fetchData(activeTab)            
          }
        }).catch(err => {
          console.log(err)
          setCardModalShow(false);
          console.log(openModal, "saleCardDetails")
          getErrorMessage(err?.response?.data?.message)   
          fetchData(activeTab)
        })
    } catch (error) {      
      setCardModalShow(false);
      // @ts-ignore
      getErrorMessage(error?.response?.data?.message)
      console.log(error, "err?.response?.data?.message")
      fetchData(activeTab)
    }   
  }

  const getAllRewardsOfUser = async (uid: any, tradeCardId: any) => {
    console.log(uid, tradeCardId,"gettradeCardId")
    try {
      const rewardsCollection = collection(firestore, "reward_transactions");
      const rewardsQuery = query(
        rewardsCollection,
        where("user", "==", uid),
        where("winData.firstRewardCardId", "==", tradeCardId)
      );
      const querySnapshot = await getDocs(rewardsQuery);

      var winCards: {
        firstRewardCard: string;
        firstRewardCardCollection: string;
        firstRewardCardId: number;
        firstRewardCardSerialNo: string;
        firstRewardCardType: string;
        secondRewardExtraVotes: number;
        thirdRewardDiamonds: number;
      }[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data?.winData?.firstRewardCardId) {
          winCards.push({ ...data.winData, ...data.transactionTime });
        }
      });

      console.log(winCards, "winCardsallwinCards")
      winnerCardData.current = winCards[0];
      setWinnerCard(winCards[0]);
    } catch (error) {
      console.log("getAllRewardsOfUser Error", error);
    }
  };
  
  console.log(winnerCardData.current,"winnerCard")

  const submitViewTradeData = async (
    data: any,
    leaderData: any,
    callBack: any,
    isEdit?: any,
    getErrorMessage?: any,

  ) => {
    console.log(winnerCardData.current,"winnerCarddata")   
    let dataPayload = {
      ...data,
      sellerUserId: leaderData?.sellerUserId || userInfo?.uid,
      buyerUserId: leaderData?.buyerUserId || userInfo?.uid,
      cardId: leaderData?.cardId,
      // serialNumber: getPriSerialNo(),
      // serialNumber: winnerCardData?.current?.firstRewardCardSerialNo,
      serialNumber: leaderData?.serialNumber,
      accountId: leaderData?.accountId || leaderData?.id,
      tradeId: leaderData?.id || "",
      lastUpdatedBy: userInfo?.uid,
    }

    const extraValues = {
      sellerRequest: {
        message: dataPayload?.sellerUserId == userInfo?.uid ? data?.message : leaderData?.sellerRequest?.message || "",
        coin: dataPayload?.sellerUserId == userInfo?.uid ? data?.coin : leaderData?.sellerRequest?.coin || 0,
        lastUpdateTime: Date.now(),
        userId: dataPayload?.sellerUserId,
      },
      buyerRequest: {
        message: dataPayload?.buyerUserId == userInfo?.uid ? data?.message : leaderData?.buyerRequest?.message || "",
        coin: dataPayload?.buyerUserId == userInfo?.uid ? data?.coin : leaderData?.buyerRequest?.coin || 0,
        lastUpdateTime: Date.now(),
        userId: dataPayload?.buyerUserId,
      },
    }
    const fullPayload = {
      ...dataPayload, ...extraValues
    }

    console.log(fullPayload, "alldatadataPayload")
    try {
      await axios.put(`/cardTrading/updateTrade/${leaderData?.id}`, fullPayload).then((res) => {
        if (res.status === 200) {
          console.log(res, "get full response")
          if (callBack) callBack();
          setWaitRespons(false);
          fetchData(activeTab)
          winnerCardData.current = ""
          showToast(res?.data?.message, ToastType.SUCCESS);

          if (res?.data?.result.status === "APPROVED") { 
            setApprovedCard(res?.data?.result);           
            setTimeout(() => {            
              if (res?.data?.result.sellerUserId == userInfo?.uid) {           
                setCoinModelShow(true);
              }
              if (res?.data?.result.buyerUserId == userInfo?.uid) {                        
                setCardModalShow(true);
              }            
            }, 300);
          }
        }
      }).catch(err => {
        console.log(err)
        setWaitRespons(false);
        if (data?.status =="NEGOTIATE") {          
          getErrorMessage(err?.response?.data?.message)
        } else {          
          showToast(err?.response?.data?.message, ToastType.ERROR);
        }
        setError(err?.response?.data?.message)
        fetchData(activeTab)
      })      
    } catch (error) {
      console.log(error)      
      setWaitRespons(false);
    }
  }
  

  const submitGiftTradeData = async (
    status: any, 
    leader:any,
    callBack: any,    
  ) => {
          
    let dataPayload = {
      ...leader,
      sellerUserId: leader?.sellerUserId || userInfo?.uid,
      buyerUserId: leader?.buyerUserId || userInfo?.uid,
      cardId: leader?.cardId,
      // serialNumber: getPriSerialNo(),
      // serialNumber: winnerCardData?.current?.firstRewardCardSerialNo,
      serialNumber: leader?.serialNumber,
      accountId: leader?.accountId || leader?.id,
      tradeId: leader?.id || "",
      status:status,
      
    }

    console.log(dataPayload, "alldatadataPayload")
    try {
      await axios.put(`/cardTrading/updateTrade/${leader?.id}`, dataPayload).then((res) => {
        if (res.status === 200) {
          console.log(res, "get full response")
          if (callBack) callBack();
          setWaitRespons(false);
          showToast(res?.data?.message, ToastType.SUCCESS);
          fetchData(activeTab)
          winnerCardData.current = ""
          
          if (res?.data?.result.status === "APPROVED") {
            setApprovedCard(res?.data?.result);            
            setTimeout(() => {
              if (res?.data?.result.sellerUserId == userInfo?.uid) {
                setCoinModelShow(true);
              }
              if (res?.data?.result.buyerUserId == userInfo?.uid) {
                setCardModalShow(true);
              }
            }, 300);
          }

          }
          }).catch(err => {
            console.log(err)            
            showToast(err?.response?.data?.message, ToastType.ERROR);
            setWaitRespons(false);
          })      
    } catch (error) {
      setWaitRespons(false);
      console.log(error)   
      fetchData(activeTab)
    }
  }

  const rejectAllRequests = async (accountID: any) => { 
    const dataPayload = {    
      accountId: accountID,    
    }
    try {
      await axios.put(`/cardTrading/rejectInitiatedTradeOnCard`, dataPayload).then((res) => {
        if (res.status === 200) {
          console.log(res, "get full response")
          // if (callBack) callBack();
          showToast(res?.data?.message, ToastType.SUCCESS);
          handleAllRequestsClose()
          }
          }).catch(err => {
        showToast(err?.response?.data?.message, ToastType.ERROR);
        console.log(err)
      })  
    } catch (error) {
      
    }
  }


  const getCardDetelist = (type: string, info: any) => {
    console.log(info,"getCardDetelistinfo")
    try {
      axios.get(`/cardTrading/getInitiatedTrade/${userInfo?.uid}/${info?.id}`).then((result: any) => {
        console.log(result?.data?.result, "getallresult");          
          if (type == "BID") {          
            setOpenModal("openCardDetails");
          }        
          else{
            setOpenModal("saleCardDetails");
          }        
        console.log(result?.data?.result[0], "alldatadataPayload12345")
        getAllRewardsOfUser(info?.sellerUserId || userInfo?.uid, info?.cardId)
        setTradeDetails(info)        
      }).catch((err: any) => {

      });
    } catch (error) {

    }
  }

  const getRejectAndAproved = (type: string, info: any , ActionType?:any) => {
    try {
      axios.get(`/cardTrading/getInitiatedTrade/${userInfo?.uid}/${info?.id}`).then((result: any) => {
        console.log(result?.data?.result, "getallresult");
        getAllRewardsOfUser(info?.sellerUserId || userInfo?.uid, info?.cardId)
        setTradeDetails(type == "GIFT" && info.userName == userInfo?.userName ? result?.data?.result[0] : info)        
        
        if (type == "GIFT" && info.userName==userInfo?.userName) {
          // setOpenModal("giftcard");
          ApproveAndRejectGift(ActionType, result?.data?.result[0])
        }        
          else{
            setOpenModal("saleCardDetails");
          }        
        console.log(result?.data?.result[0], "alldatadataPayload12345")
        
      }).catch((err: any) => {

      });
    } catch (error) {

    }
  }

  const ApproveAndRejectGift = async (type: any,leader:any) => {
    let result;

    if (type === 'APPROVED') {
      result = await confirmApprovedGift();

    } else if (type === 'REJECTED') {
      result = await confirmRejectGift();
    }
    // const result = await confirmDelete();
    if (result?.isConfirmed) {
      setWaitRespons(true);
      submitGiftTradeData(type, leader, () => { console.log("Done") })
    } else if (result?.dismiss === Swal.DismissReason.cancel) {     
      // handleAllRequestsClose()
      winnerCardData.current = ""
    }
  };



  
  const getViewDetelist = (type: string, info: any) => {
    try {
      axios.get(`/cardTrading/getInitiatedTrade/${userInfo?.uid}/${info?.id}`).then((result: any) => {
        console.log(result?.data?.result, "getallresult");
        if (type == "FREE") {

          setOpenModal("freecard");
        } else {
          
          setOpenModal("sellerDetails");
        }
        console.log(result?.data?.result[0], "alldatadataPayload12345")
        handleAllRequestsClose()
        getAllRewardsOfUser(info?.sellerUserId || userInfo?.uid,info?.cardId)
        setTradeDetails(result?.data?.result[0] || info)
      }).catch((err: any) => {

      });
    } catch (error) {

    }
  }

  const handleDelete = async (tradeInfo: any) => {
    let result;
    if (tradeInfo.transitionType == "GIFT") {
      result = await confirmDeleteGift();
    } else {
      result = await confirmDelete();
    }
    // const result = await confirmDelete();

    if (result.isConfirmed) {
      // Proceed with the delete action
      console.log('Item deleted', tradeInfo);
      const data = {
        accountId: tradeInfo?.id
      }
      axios.post(`/cardTrading/deleteOpenTrade`, data).then((res) => {
        fetchData(activeTab)
        showToast(res?.data?.message, ToastType.SUCCESS);    
      }).catch((err) => {
        fetchData(activeTab)
        showToast(err?.response?.data?.message, ToastType.ERROR);
      });
      // Your delete logic here, e.g., calling an API
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      console.log('Delete action canceled');
    }
  };


  const getTradeDetelist = (info: any, ConfirmType:string) => {
    try {
      axios.get(`/cardTrading/getInitiatedTrade/${userInfo?.uid}/${info?.id}`).then((result: any) => {
        getAllRewardsOfUser(info?.sellerUserId || userInfo?.uid, info?.cardId)
        console.log(result?.data?.result, "getallresult");
        setTradeDetails(result?.data?.result[0] || info)
        handleAllRequestsClose()
        ApproveAndRejectConfirm(ConfirmType, result?.data?.result[0] || info)
        // setTradeDetails(result?.data?.result[0] || info)
      }).catch((err: any) => {

      });
    } catch (error) {

    }
  }


  const ApproveAndRejectConfirm = async (type:any,leader:any) => {
    let result;

    if (type === 'APPROVED') {
      result = await confirmApproved();
    } else if (type === 'REJECTED') {
      result = await confirmReject();
    }
    // const result = await confirmDelete();
    if (result?.isConfirmed) {        
      const data = {
        transitionType: leader?.transitionType,
        tradeType: leader?.tradeType,
        coin: leader?.coin,
        durationType: leader?.durationType,
        duration: leader?.duration,
        status: type,
        message: leader?.message,
      };
      console.log(data, "submitdata")
      setWaitRespons(true);
      await submitViewTradeData(
        data,
        leader,
        () => handleAllRequestsClose(),
      );      
    } else if (result?.dismiss === Swal.DismissReason.cancel) {
      console.log('Delete action canceled');
      winnerCardData.current = ""
      handleAllRequestsClose()
    }
  };





  const conVertDays = (hours: any, type: any, endTime?: any) => {
    if (hours == 1) {
      return "1 Hour";
    }
    if (hours == 24) {
      return "1 day";
    }
    if (hours == 168) {
      return "1 Week";
    }
    if (hours > 168) {
      const days = hours / 24
      return `${days} days`;
    }
    if (hours == 0 && type == "NO_LIMIT") {
      return `No Limit`
    }
    if (hours == 0 && type == "CUSTOM") {
      return moment(endTime).format('DD/MM/YY HH:mm')
    }
  }

  console.log(openModal,"OpenModalvalue")
  console.log(allRequestsList,"OpenMoallRequestsListdalvalue")
  
  return (
    <div>            
      {isLoading && (
        <div
          style={{
            position: "fixed",
            height: "68%",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            // top: '0px',
            right: "0px",
            bottom: "0px",
            zIndex: "9999",
            overflow: "hidden",
            width: "100%",
            alignItems: "center",
          }}
        >
          <span
            className="loading"
            style={{
              color: "#fff",
              zIndex: "2220px",
              fontSize: "1.5em",
              // marginTop: `${window.screen.width > 767 ? "100px" : "240px"}`
            }}
          >
            {texts.waitForIt}
          </span>
        </div>
      )}

      {waitRespons && (
        <>
          <WaitForResponse />
        </>
      )}
      <div className="tradesdiv">
        <div className="trade-buttonsdiv">
          <button onClick={() => { fetchData(0);
            if (tabID)navigate(`/profile/trade`);
            
           }} className={activeTab === 0 ? 'active' : ''}>BUY</button>
          <button onClick={() => { fetchData(1);
            if (tabID) navigate(`/profile/trade`);
           }} className={activeTab === 1 ? 'active' : ''}>SELL</button>
          <button onClick={() => { fetchData(2);
            if (tabID) navigate(`/profile/trade`);
           }} className={activeTab === 2 ? 'active' : ''}>GIFT</button>
          <button onClick={() => {
            NegotiateData(3);
            if (tabID) navigate(`/profile/trade`);
          }} className={activeTab === 3 ? 'active' : ''}>NEGOTIATE</button>
        </div>
        <div className="trade-contentdiv">
          {activeTab === 0 &&
            <div>
              <div
                className="d-flex align-items-center flex-column w-100"
                style={{
                  color: "gray",
                }}
              >

                {userInfo?.uid && allTradeList?.length > 0 ? (
                  allTradeList?.map((item: any, index: number) => {

                    return (
                      <div
                        key={index + item?.id}
                        style={{
                          width: `${window.screen.width > 767 ? "400px" : "97%"}`,
                        }}
                      >
                        <div
                          className="py-2 px-2 my-2"
                          style={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            borderRadius: "5px",
                            background: "white",
                          }}
                        >
                        <div
                          className="d-flex justify-content-center align-items-center"                          
                        >
                          <div style={{
                            width: "18%"
                          }}>
                            <div
                              className=" d-flex justify-content-center align-items-center"
                              style={{
                                borderRadius: "50px",
                                width: "43px",
                                height: "43px",
                              }}
                            >
                              <Avatar url={""} />
                            </div>
                          </div>
                          <div className="d-flex flex-column"
                            style={{
                              width: "26%",
                              fontWeight: "400",                              
                            }}
                          >
                            {/* @ts-ignore*/}
                            <p
                              style={{
                                color: "#6352e8",
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                navigate(`/singalCard/${item?.cardData?.cardType}/${item?.cardData?.cardId}`)
                                localStorage.setItem("singalCardData", JSON.stringify({...item?.cardData}))                
                            }}
                            >{item?.cardName}</p>
                            <p>{item?.coin} VTE</p>                                                         
                            </div>
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              width: "26%"
                            }}
                          >
                            <div
                              style={{
                                borderBottom: "1px solid #6352e8",
                                position: "relative",
                                cursor: `${item?.initiatedCount > 0 ? "pointer" :"no-drop"}`
                              }}
                              onClick={(e) => {
                                // SendOtherDetails(item?.id);
                                // setOpenModal("buyerDetails");
                                // viewAllRequests(item);
                                // setActiveTab(3)
                                if (item?.initiatedCount > 0) {                                  
                                  fetchData(3)
                                }
                              }}
                            >
                              
                                <ShowCount>
                                  <p>{item?.initiatedCount}</p>
                                </ShowCount>
                              <TradeText2
                                style={{
                                  cursor: `${item?.initiatedCount > 0 ? "pointer" : "no-drop"}`,
                                  opacity: `${item?.initiatedCount < 1 ? 0.7 : 1}`
                                }}
                              >Negotiation offer</TradeText2>                              
                            </div>                            
                          </div>

                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              width: "20%"
                            }}
                          >
                            <TradeButton
                              onClick={(e) => {                                
                                getCardDetelist(item?.transitionType, item)
                              }}
                            >
                              <TradeText>Edit offer</TradeText>
                            </TradeButton>                            
                          </div>

                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              width: "10%"
                            }}
                          >
                            <DeleteIcon
                              onClick={(e) => {                                                                
                                handleDelete(item)
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </DeleteIcon>                            
                          </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: "100%",
                              fontWeight: "400",
                            }}>
                            <div
                              style={{ width: "18%" }}
                            >

                            </div>
                            <div style={{
                              width: "60%",
                              // top: "54px",
                            }}>
                              <span>Expire in : {conVertDays(Number(item?.duration), item?.durationType, item?.endTimeDuration)}</span>
                            </div>
                          </div>
                          {/* } */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    {!isLoading && <p className="text-white">Data not Found</p>}
                  </>
                )}
              </div>
            </div>
          }
          {activeTab === 1 &&
            <div>
              <div
                className="d-flex align-items-center flex-column w-100"
                style={{
                  color: "gray",
                }}
              >

                {userInfo?.uid && allTradeList?.length > 0 ? (
                  allTradeList?.map((item: any, index: number) => {

                    return (
                      <div
                        key={index + item?.id}
                        style={{
                          width: `${window.screen.width > 767 ? "400px" : "97%"}`,
                        }}
                      >
                        <div
                          className="py-2 px-2 my-2"
                          style={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            borderRadius: "5px",
                            background: "white",
                          }}
                        >
                        <div
                          className="d-flex justify-content-center align-items-center"                          
                        >
                          <div style={{
                            width: "18%"
                          }}>
                            <div
                              className=" d-flex justify-content-center align-items-center"
                              style={{
                                borderRadius: "50px",
                                width: "43px",
                                height: "43px",
                              }}
                            >
                              <Avatar url={""} />
                            </div>
                          </div>
                          <div className="d-flex flex-column"
                            style={{
                              width: "26%",
                              fontWeight: "400",
                            }}
                          >
                            {/* @ts-ignore*/}
                            <p
                            style={{
                                color: "#6352e8",
                                cursor: "pointer"
                                
                              }}
                              onClick={() => {                                                                  
                                  navigate(`/singlecarddetails/${item?.cardData?.cardType}/${item?.cardData?.cardId}/${"profile"}`)
                                  localStorage.setItem("singalCardData",JSON.stringify({ ...item?.cardData}));                                
                            }}
                            >{item?.cardName}</p>
                            <p>{item?.serialNumber}</p>
                            <p>{item?.coin} VTE</p>
                            {/* <p>Expire in :  </p>
                            <p>{conVertDays(Number(item?.duration), item?.durationType, item?.endTimeDuration)}</p> */}                                                                                    
                        </div>
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              width: "26%"
                            }}
                          >
                            <div
                              style={{
                                borderBottom: "1px solid #6352e8",
                                position: "relative",    
                                cursor: `${item?.initiatedCount > 0 ? "pointer" : "no-drop"}`
                              }}
                              onClick={(e) => { 
                                if (item?.initiatedCount > 0) {
                                  fetchData(3)
                                }
                                
                              }}
                            >
                              
                              <ShowCount>
                                <p>{item?.initiatedCount}</p>
                              </ShowCount>
                              <TradeText2
                                style={{
                                  cursor: `${item?.initiatedCount > 0 ? "pointer" : "no-drop"}`,
                                  opacity: `${item?.initiatedCount < 1 ? 0.7 : 1}`
                              }}
                              >Negotiation offer</TradeText2>
                              
                            </div>
                          </div>

                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              width: "20%"
                            }}
                          >
                            <TradeButton
                              onClick={(e) => {                               
                                getCardDetelist(item?.transitionType ,item)
                              }}
                            >
                              <TradeText>Edit offer</TradeText>
                            </TradeButton>
                          </div>
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              width: "10%"
                            }}
                          >
                            <DeleteIcon
                              onClick={(e) => {
                                // getCardDetelist(item?.transitionType, item)
                                handleDelete(item)
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </DeleteIcon>
                          </div>
                            {/* } */}                          
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: "100%",
                              fontWeight: "400",
                          }}>                          
                          <div
                          style={{width: "18%"}}
                            >
                              
                          </div>
                          <div style={{                            
                            width: "60%",
                            // top: "54px",
                          }}>
                            <span>Expire in : {conVertDays(Number(item?.duration), item?.durationType, item?.endTimeDuration)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                      {!isLoading && <p className="text-white">Data not Found</p>}
                  </>
                )}
              </div>
            </div>
          }
          {activeTab === 2 &&
            <div>
              <div
                className="d-flex align-items-center flex-column w-100"
                style={{
                  color: "gray",
                }}
              >

                {userInfo?.uid && allTradeList?.length > 0 ? (
                  allTradeList?.map((item: any, index: number) => {

                    return (
                      <div
                        key={index + item?.id}
                        style={{
                          width: `${window.screen.width > 767 ? "400px" : "97%"}`,
                        }}
                      >
                        <div
                          className="py-2 px-2 my-2 d-flex justify-content-center align-items-center"
                          style={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            borderRadius: "5px",
                            background: "white",
                          }}
                        >
                          <div style={{
                            width: "20%"
                          }}>
                            <div
                              className=" d-flex justify-content-center align-items-center"
                              style={{
                                borderRadius: "50px",
                                width: "43px",
                                height: "43px",
                              }}
                            >
                              <Avatar url={""} />
                            </div>
                          </div>
                          <div className="d-flex flex-column"
                            style={{
                              width: "50%",
                              fontWeight: "400",
                            }}
                          >
                            {/* @ts-ignore*/}
                            <p
                              style={{
                                color: "#6352e8",
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                if (item?.sellerUserId == userInfo?.uid) {                                  
                                  navigate(`/singlecarddetails/${item?.cardData?.cardType}/${item?.cardData?.cardId}/${"profile"}`)
                                  localStorage.setItem("singalCardData", JSON.stringify({ ...item?.cardData , myID:userInfo?.uid}));
                                } else {
                                  navigate(`/singalCard/${item?.cardData?.cardType}/${item?.cardData?.cardId}`)
                                  localStorage.setItem("singalCardData", JSON.stringify({ ...item?.cardData, myID: item?.cardData?.setId }))
                                }
                              }}
                            >{item?.cardName}</p>
                            {item?.transitionType == "GIFT" && item?.userName == userInfo?.userName ? <p>Gift By {item?.sellerUserName}</p> : <p>Gift For {item?.userName}</p>}
                            <p>Expire in : {conVertDays(Number(item?.duration), item?.durationType, item?.endTimeDuration)}</p>
                          </div>                          

                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              width: "20%"
                            }}
                          >                            
                            {item?.transitionType == "GIFT" && item?.userName==userInfo?.userName  ?<div>
                              <TradeButton
                                style={{
                                  backgroundColor: "#009344",
                                  marginBottom: "5px",
                                }}
                                onClick={(e) => {
                                  // getTradeDetelist(item, "APPROVED")
                                  getRejectAndAproved(item?.transitionType, item, "APPROVED")
                                }}
                              >
                                <TradeText>Approve</TradeText>
                              </TradeButton>                              
                              <TradeButton
                                style={{
                                  backgroundColor: "#e05858",
                                  marginTop: "5px",
                                }}
                                onClick={(e) => {
                                  // getTradeDetelist(item, "REJECTED")
                                  getRejectAndAproved(item?.transitionType, item, "REJECTED")
                                }}
                              >
                                <TradeText>Reject</TradeText>
                              </TradeButton>
                            </div> :
                              <div className="d-flex justify-content-center align-items-center flex-column"                                
                              > 
                                {/* <TradeButton
                                  style={{                                    
                                    marginBottom: "5px",
                                  }}
                              onClick={(e) => {                                
                                  getRejectAndAproved(item?.transitionType, item)
                              }}
                            >
                              <TradeText>Edit gift</TradeText>
                                </TradeButton> */}
                                
                                <TradeButton
                                  style={{
                                    // marginBottom: "5px",
                                    width: "45px",
                                  }}
                              onClick={(e) => {                                
                                    handleDelete(item)
                              }}
                            >
                              <TradeText>Cancel</TradeText>
                            </TradeButton>
                              </div>
                            }
                          </div>
                          {/* } */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                      {!isLoading && <p className="text-white">Data not Found</p>}
                  </>
                )}
              </div>
            </div>
          }
          {activeTab === 3 &&
            <div>
              <div
                className="d-flex align-items-center flex-column w-100"
                style={{
                  color: "gray",
                }}
              >                
                {userInfo?.uid && allNegotiateList?.length > 0 ? (
                  allNegotiateList?.map((cardData: any, index: number) => {

                    return (
                      <>
                        
                        <div
                          key={index + cardData?.id}
                          style={{
                            width: `${window.screen.width > 767 ? "400px" : "97%"}`,
                          }}
                        >
                          <div
                            className="py-2 px-2 my-2 d-flex justify-content-center"
                            style={{
                              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                              borderRadius: "5px",
                              background: "white",
                            }}
                          >
                              <div
                                style={{display: "flex", alignItems: "center",cursor: "pointer"}}
                                onClick={(e) => {
                                  setShowListData(showListData == cardData?.id ? "" : cardData?.id)                                  
                                  }}
                              >
                              {showListData == cardData?.id ? (
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                                </svg>
                                  ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                                </svg>
                              )}
                              </div>
                            <div style={{
                              width: "18%",
                              display:"flex",
                              alignItems: "center",
                            }}>
                              <div
                                className=" d-flex justify-content-center align-items-center"
                                style={{
                                  borderRadius: "50px",
                                  width: "43px",
                                  height: "43px",
                                }}
                              >
                                <Avatar url={""} />
                              </div>
                            </div>
                            <div className="d-flex flex-column"
                              style={{
                                width: "28%",
                                fontWeight: "400",
                              }}
                            >
                              {/* @ts-ignore*/}
                              <p
                                style={{
                                  color: "#6352e8",
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  if (userInfo?.rewardStatistics?.cards.includes(cardData?.cardName) && cardData?.sellerUserId == userInfo?.uid) {
                                    navigate(`/singlecarddetails/${cardData?.cardData?.cardType}/${cardData?.cardData?.cardId}/${"profile"}`)
                                    localStorage.setItem("singalCardData", JSON.stringify({ ...cardData?.cardData, myID: userInfo?.uid }));
                                  } else {
                                    navigate(`/singalCard/${cardData?.cardData?.cardType}/${cardData?.cardData?.cardId}`)
                                    localStorage.setItem("singalCardData", JSON.stringify({ ...cardData?.cardData, myID: cardData?.cardData?.setId }))
                                  }
                                }}
                              >{cardData?.cardName}</p>
                              <p>{cardData?.serialNumber}</p>
                              <p>{cardData?.coin} VTE</p>
                              <p>{`Offer Type : ${cardData?.transitionType === 'ASK' ? 'Sell' : cardData?.transitionType === 'BID' ? 'Buy' : ''}`}</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "24%"
                              }}
                            >
                              <div
                                style={{
                                  borderBottom: "1px solid #6352e8",
                                  position:"relative",
                                }}
                                onClick={(e) => {
                                  // SendOtherDetails(item?.id);                                
                                  // viewAllRequests(item);
                                  // setActiveTab(3)
                                  setShowListData(showListData == cardData?.id ? "" : cardData?.id)             
                                }}
                              >
                                <ShowCount>
                                  <p>{cardData?.negotiatedCount || 0}</p>
                                </ShowCount>
                                <TradeText2 
                                  style={{
                                    opacity: `${cardData?.negotiatedCount < 1 ? 0.7 : 1}`,
                                    cursor: `${cardData?.negotiatedCount > 0 ? "pointer" : "no-drop"}`,
                                }}  
                                >Negotiation offer</TradeText2>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "20%"
                              }}
                            >
                              {((cardData?.transitionType == "ASK" && cardData?.sellerUserId == userInfo?.uid) || (cardData?.transitionType == "BID" && cardData?.buyerUserId == userInfo?.uid)) &&
                                <TradeButton                                
                                onClick={(e) => {
                                  // SendOtherDetails(item?.id);  
                                  const {initiatedUsers, ...rest } = cardData                                  
                                  getCardDetelist(cardData?.transitionType, rest)
                                }}
                              >
                                <TradeText>Edit offer</TradeText>
                              </TradeButton>}
                            </div>
                            <div className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "10%"
                              }}
                            >
                              {((cardData?.transitionType == "ASK" && cardData?.sellerUserId == userInfo?.uid) || (cardData?.transitionType == "BID" && cardData?.buyerUserId == userInfo?.uid)) &&
                                <DeleteIcon
                                onClick={(e) => {                                                                    
                                  handleDelete(cardData)
                                }}
                              >
                                <i className="bi bi-trash"></i>
                              </DeleteIcon>}
                            </div>
                            {/* } */}
                          </div>
                        </div>

                        {cardData?.negotiateEntries?.length > 0 && showListData == cardData?.id && cardData?.negotiateEntries?.map((item: any, index: number) => {
                          
                          return ( 
                            <div className="NegotiateListdataBig"
                              style={{                                
                                width: `${window.screen.width > 767 ? "400px" : "97%"}`,                                
                              }}
                            >
                              <div className="NegotiateListdata"
                            style={{
                              width: `${window.screen.width > 767 ? "390px" : "97%"}`,                              
                            }}
                          >
                            {/* <div>
                              <Avatars type={item?.userIamge || ""}

                                style={{
                                  width: "45px",
                                  height: "45px",
                                }}
                              />
                            </div> */}
                            
                            <InfoDiv>
                            {/* <p>{item?.sellerUserId == userInfo?.uid ?"Buyer offer":"Seller Offer"}</p> */}
                                  <p>Username : <span className="semiblodText">{userInfo?.uid == item?.sellerUserId ? item?.buyerUserName : item?.sellerUserName}</span></p>                                                                                          
                                  {/* {item?.lastUpdatedBy == item?.sellerRequest?.userId ? <p>{item?.sellerRequest?.coin} VTE offer you</p> : <p>{item?.buyerRequest?.coin} VTE </p> }                                 */}
                                  {item.lastUpdatedBy == userInfo?.uid ? <p>You Offer : <span className="semiblodText">{item?.coin} VTE (Negotiation)</span></p> : <p>Received Offer : <span className="semiblodText">{item?.coin} VTE (Negotiation)</span></p>}
                                  <p>Expire in : <span className="semiblodText">{conVertDays(Number(item?.duration), item?.durationType, item?.endTimeDuration)}</span></p>
                              {/* <p>Last Update : {item?.coin}</p> */}
                                  <p>Last Update : <span className="semiblodText">{convertTime(item?.updatedAt || item?.timestamp)}</span></p>
                            </InfoDiv>
                            <div>
                              {item?.lastUpdatedBy !=userInfo?.uid &&<TradeButton
                                style={{
                                  backgroundColor: "#009344",
                                  marginBottom: "5px",
                                }}
                                onClick={(e) => {
                                  getTradeDetelist(item, "APPROVED")
                                }}
                              >
                                <TradeText>Approve</TradeText>
                              </TradeButton>}
                              <TradeButton
                                    onClick={(e) => {
                                      if (item?.lastUpdatedBy == userInfo?.uid) {                                         
                                        getViewDetelist(item?.transitionType, item)
                                        setEditRequest(true)
                                      } else {
                                        getViewDetelist(item?.transitionType, item)                                        
                                      }
                                }}
                              >
                                <TradeText>{item?.lastUpdatedBy == userInfo?.uid ?"Edit":"Negotiate"}</TradeText>
                              </TradeButton>
                              <TradeButton
                                style={{
                                  backgroundColor: "#e05858",
                                  marginTop: "5px",
                                }}
                                onClick={(e) => {
                                  getTradeDetelist(item, "REJECTED")
                                }}
                              >
                                <TradeText>Reject</TradeText>
                              </TradeButton>                              
                            </div>                             
                              </div>
                              <div className="text-center bottomTitle">
                                {/* {item?.lastUpdatedBy == userInfo?.uid && <p>Offer sent by you for {item?.coin} VTE</p>}
                                {item?.lastUpdatedBy != userInfo?.uid && <p> Offer received on your last offer {userInfo?.uid == item?.sellerUserId ? item?.sellerRequest?.coin || cardData?.coin : item?.buyerRequest?.coin || cardData?.coin} VTE</p>} */}

                                {item?.lastUpdatedBy == userInfo?.uid ?
                                  <p>Offer Sent : Negotiating Previous Offer of {userInfo?.uid == item?.sellerUserId ? item?.buyerRequest?.coin || cardData?.coin : item?.sellerRequest?.coin || cardData?.coin} VTE</p> :
                                  <p> Offer Received : Negotiating Previous Offer of  {userInfo?.uid == item?.sellerUserId ? item?.sellerRequest?.coin || cardData?.coin : item?.buyerRequest?.coin || cardData?.coin}</p>}
                                   {/* <p style={{color:"red"}}>{error}</p> */}
                              </div>
                            </div>
                              )
                        })}
                        
                      </>
                    );
                  })
                ) : (
                  <>
                      {!isLoading && <p className="text-white">Data not Found</p>}
                  </>
                )}
              </div>
            </div>
          }
        </div>
      </div>   
      <>
        {openModal == "saleCardDetails" && (
          <CardSalePopup
            openModal={openModal}
            setOpenModal={setOpenModal}
            tradeCrad={submitTradeData}
            leader={tradeDetails}
          />
        )}
        {openModal == "openCardDetails" && (
          <BuyerPopup
            openModal={openModal}
            setOpenModal={setOpenModal}
            tradeCrad={UpdateBuyerData}
            leader={tradeDetails}
          />
        )}
        {(openModal == "sellerDetails" || openModal == "freecard" )&& (
          <BuyerSellerPopup
            openModal={openModal}
            setOpenModal={setOpenModal}
            tradeCrad={submitViewTradeData}
            leader={tradeDetails}
            EditRequest={editRequest}
          />
        )}
        {(openModal == "giftcard")&& (
          <BuyerSellerPopup
            openModal={openModal}
            setOpenModal={setOpenModal}
            tradeCrad={submitGiftTradeData}
            leader={tradeDetails}
          />
        )}
      </>
      <div>
        <Modal
          dialogClassName="modal-35w"
          show={requestsPopup}
          onHide={handleAllRequestsClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ opacity: 1, zIndex: 9999 }}
          className="borderColor"
          // animation={false}
          backdrop="static"
          contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
        >
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleAllRequestsClose}
            ></button>
          </div>
          <Modal.Body>
            <div className="w-100 mb-4 text-center">
              <strong className="">All offers</strong>
            </div>
            <div className="viewListdataBig">
              {allRequestsList?.length > 0 ? <>
                {
                  allRequestsList.map((item:any, index:number) => {
                    return <div key={index+item?.id}>
                      <div className="viewListdata">
                        <div>
                          <Avatars type={item?.userIamge || ""}
                          
                            style={{
                              width: "45px",
                              height: "45px",
                            }}
                          />
                        </div>
                        <InfoDiv>
                          <p>User Name : {item?.userName}</p>
                          <p>Card Name : {item?.cardName}</p>
                          <p>{item?.coin} VTE</p>
                          <p>Expire in : {conVertDays(Number(item?.duration), item?.durationType, item?.endTimeDuration)}</p>
                          {/* <p>Last Update : {item?.coin}</p> */}
                          <p>Last Update : {convertTime(item?.updatedAt || item?.timestamp)}</p>
                        </InfoDiv>
                        <div>
                          <TradeButton
                          style={{
                              backgroundColor: "#009344",
                            marginBottom: "5px",
                          }}
                            onClick={(e) => {                              
                              getTradeDetelist(item,"APPROVED")                              
                            }}
                          >
                            <TradeText>Approve</TradeText>
                          </TradeButton>
                          <TradeButton
                            onClick={(e) => {                              
                              getViewDetelist(item?.transitionType, item)                              
                            }}
                          >
                            <TradeText>Negotiate</TradeText>
                          </TradeButton>
                          <TradeButton
                          style={{
                              backgroundColor: "#e05858",
                              marginTop: "5px",                            
                          }}
                            onClick={(e) => {                              
                              getTradeDetelist(item,"REJECTED")                              
                            }}
                          >
                            <TradeText>Reject</TradeText>
                          </TradeButton>
                        </div>
                      </div>
                    </div>
                  })
              }
              </> :
                <div className="text-center">
                  Offers not found
              </div>}
            </div>
            {/* <p className="text-center">Pls give me this card</p> */}
            <div className="w-100 d-flex justify-content-around mt-4">
              <TradeButtons
                style={{
                  backgroundColor: "#6458e5",
                  opacity:allRequestsList?.length < 1 ? "0.7":"1",
                }}
                disabled={allRequestsList?.length < 1}
                onClick={() => {
                  rejectAllRequests(tradeDetails?.id)
                }}
              >
                Reject all
              </TradeButtons>
              <TradeButtons
                style={{
                  backgroundColor: "#6458e5",
                }}
                onClick={() => {
                  handleAllRequestsClose()
                }}
              >
                Close
              </TradeButtons>
            </div>
          </Modal.Body>
        </Modal>        
      </div>     
      <Modal
        className=""
        show={cardModalShow}
        onHide={() => setCardModalShow(false)}
        // fullscreen="sm-down"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: "rgba(0,0,0,0.8)", zIndex: "2200" }}
        animation={false}
        contentClassName={
          window.screen.width > 767
            ? `card-content modulebackground ForBigNft ${`${approvedCard?.cardDetails?.winData?.firstRewardCardType.toLowerCase()}BG`}`
            : `card-contentMob modulebackground ForBigNft ${`${approvedCard?.cardDetails?.winData?.firstRewardCardType.toLowerCase()}BG`}`
        }
      >
        <div className="d-flex justify-content-end"></div>
        
        <Modal.Body
          style={{
            padding: "0px",
          }}
        >
          <NFTTradeCard
            rewardTimer={{ data: approvedCard?.cardDetails?.winData }}
            approvedCard={approvedCard}
            handleCardClose={handleCardClose}
            setShareModalShow={setShareModalShow}
          />
        </Modal.Body>
      </Modal>    
      <>
        <NFTTradeCoin
          modalShow={coinModelShow}
          approvedCard={approvedCard}
          handleCoinClose={handleCoinClose}
        />
        <ShareModal
          shareModalShow={shareModalShow}
          setShareModalShow={setShareModalShow}
          url={url}
          shareText={shareText}
        // setAddPaxWalletPop={setAddPaxWalletPop}
        />
      </>
    </div>
  );
}

export default TradeList;
