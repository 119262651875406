/** @format */

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Container from "react-bootstrap/Container";
import UserContext, {
  getUserInfo,
  saveDisplayName,
  saveUsername,
} from "./Contexts/User";
import { texts } from "./Components/LoginComponent/texts";
import { NotificationProps, UserProps } from "./common/models/User";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast, ToastContainer, Zoom } from "react-toastify";
import Home from "./Pages/Home";
import Profile, { ProfileTabs } from "./Pages/Profile";
import FollowerProfile, { FollowerProfileTabs } from "./Pages/FollowerProfile";
import Header from "./Components/Header";
import NotificationContext, { ToastType } from "./Contexts/Notification";
import CoinsContext, { Leader, Totals } from "./Contexts/CoinsContext";
import SingleCoin from "./Pages/SingleCoin";
import {
  Coin,
  DBCoin,
  DBPair,
  getAllCoins,
  getCoins,
  saveAllCoins,
} from "./common/models/Coin";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, firestore, functions, messaging } from "./firebase";
import Admin from "./Pages/Admin";
import {
  GetVotesResponse,
  TimeFrame,
  VoteResultProps,
} from "./common/models/Vote";
import AppContext, {
  AppStats,
  CPMSettings,
  PaxData,
  VoteRules,
} from "./Contexts/AppContext";
import isNumber from "lodash/isNumber";
import {
  defaultUserType,
  UserTypeProps,
  validateTotalShare,
} from "./common/models/UserType";
import ManagersContext from "./Contexts/ManagersContext";
import CPMSettingsManager from "./managers/CPMSettingsManager";
import VoteRulesManager from "./managers/VoteRulesManager";
import TimeframesManager from "./managers/TimeframesManager";
import UserTypeManager from "./managers/UserTypeManager";
import CoinMain from "./Pages/CoinMain";
import firebase from "firebase/compat/app";
import PairsMain from "./Pages/PairsMain";
import SinglePair from "./Pages/SinglePair";
import { ENGLISH, translations } from "./common/models/Dictionary";
import { getKeyByLang, getLangByKey } from "./common/consts/languages";
import { getToken } from "firebase/messaging";
import { Form } from "react-bootstrap";
import { rest } from "./common/models/Socket";
import { httpsCallable } from "firebase/functions";
import ContentContext, { ContentPage } from "./Contexts/ContentContext";
import Content from "./Pages/Content";
import LoginAndSignup from "./Components/LoginComponent/LoginAndSignup";
import GenericLoginSignup from "./Components/GenericSignup/GenericLoginSignup";
import {
  LoginAuthProvider,
  LoginRegular,
  Logout,
  SignupRegular,
} from "./common/models/Login";
import FirstTimeLogin from "./Components/LoginComponent/FirstTimeLogin";
import { generateUsername } from "./common/utils/strings";
import { intersection, orderBy } from "lodash";
import Logo, { Size } from "./Components/Atoms/Logo";
import {
  AppContainer,
  getSubdomain,
  HomeContainer,
  isV1,
} from "./Components/App/App";
import Influencers from "./Pages/Influencers";
import NFTGallery from "./Pages/NFTGallery";
import NFTGalleryType from "./Pages/NFTGalleryType";
import Footer from "./Components/Footer";
import PersonalInfo from "./Components/Profile/PersonalInfo";
import Security from "./Components/Profile/Security";
import Mine from "./Components/Profile/Mine";
import Follow from "./Components/Profile/Follow";
import Pool from "./Components/Profile/Pool";
import { useWindowSize } from "./hooks/useWindowSize";
import Votes from "./Components/Profile/voteHistory/Votes";
import { ToastContent, ToastOptions } from "react-toastify/dist/types";
import FAQ from "./Pages/FAQ";
import styled, { createGlobalStyle } from "styled-components";
import { myPages, quotes } from "./common/consts/contents";
import Notifications from "./Components/Profile/Notifications";
import Trade from "./Components/Profile/CardTrade/Trade";
import Background from "./Components/Background";
import Spinner from "./Components/Spinner";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Button from "./Components/Atoms/Button/Button";
import FirstTimeAvatarSelection from "./Components/LoginComponent/FirstTimeAvatarSelection";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
// import UpgradePage from "./Components/Profile/UpgradePage";
import UpgradePage from "./Components/Profile/UpgradePage/UpgradePage";
// import UpgradePageCopy from "./Components/Profile/UpgradePage";
import VotingBoosterCopy from "./Components/Profile/VotingBooster";
import ProfileNftGallery from "./Pages/ProfileNftGallery";
import Foundations from "./Pages/Foundations";
import ProfileNftGalleryType from "./Pages/ProfileNftGalleryType";
import SingalCard from "./Pages/SingalCard";
import FwMine from "./Components/FollowerProfile/FwMine";
import FwFollow from "./Components/FollowerProfile/FwFollow";
import FwVotes from "./Components/FollowerProfile/voteHistory/FwVotes";
import FwPool from "./Components/FollowerProfile/FwPool";
import FwProfileNftGallery from "./Pages/FwProfileNftGallery";
import FwProfileNftGalleryType from "./Pages/FwProfileNftGalleryType";
import Wallet from "./Components/Profile/Wallet/Wallet";
import { pwaInstallHandler } from "pwa-install-handler";
import Login2fa from "./Components/LoginComponent/Login2fa";
import TermsAndConditions from "./Pages/TermsAndConditions";
import Wheel from "Pages/wheel/wheel";
import Streak from "Pages/streakBonus/streak";
import SpinWheel from "Pages/SpinWheel";

import {
  VoteContext,
  VoteContextType,
  VoteDispatchContext,
  VoteProvider,
} from "Contexts/VoteProvider";
import { vote } from "common/models/canVote.test";
import ModalForResult from "Pages/ModalForResult";
import {
  CompletedVotesContext,
  CompletedVotesDispatchContext,
} from "Contexts/CompletedVotesProvider";
import {
  CurrentCMPContext,
  CurrentCMPDispatchContext,
} from "Contexts/CurrentCMP";
import CoinsList from "Components/Profile/Payment/CoinsList";

import PaymentFun from "Components/Profile/Payment/PaymentFun";
import PaymentHistory from "Components/Profile/Payment/PaymentHistory";
import {
  VoteEndCoinPriceContext,
  VoteEndCoinPriceType,
} from "Contexts/VoteEndCoinPrice";
import Complete100CMPModal from "Components/Complete100CMPModal";
import { request } from "http";
import VotingBooster from "Components/Profile/VotingBooster";
import {
  LessTimeVoteDetailContext,
  LessTimeVoteDetailDispatchContext,
} from "Contexts/LessTimeVoteDetails";
import Swal from "sweetalert2";
import SelectBio from "Components/LoginComponent/SelectBio";
import axios from "axios";
import {
  GetVoteDetelis,
  ReloadPop,
  UpdateLastLogin,
  afterpaxDistributionToUser,
  cardTradNotificationsCount,
  getAllNotifications,
  getPriceFirstTime,
} from "common/utils/helper";
import SingleCardDetails from "Pages/album/SingleCardDetails";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import InfluencersList from "Components/InfluencersList";
import NewUrlFram from "Components/NewUrlFram";
import CookieConsent from "react-cookie-consent";
import GameRule from "Pages/GameRule";
import Ambassador from "Pages/Ambassador/Ambassador";
import { decimal } from "Components/Profile/utils";
import LiveBanner from "Components/LiveBanner";
import InfluencerUrl from "Pages/InfluencerUrl";
import Progress from "Components/ProgressBar/index";

let wsReConnectThrottle: any;
let wsConnectRetry: number = 0;

const projectId = "1556d7953ee6f664810aacaad77addb1";
const mainnet = [
  {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://cloudflare-eth.com",
  },
  // {
  //   chainId: 11155111,
  //   name: "Sepolia",
  //   currency: "ETH",
  //   explorerUrl: "https://sepolia.etherscan.io",
  //   rpcUrl: "https://rpc.sepolia.org",
  // },
  {
    chainId: 56,
    name: "Binance",
    currency: "BNB",
    explorerUrl: "https://bscscan.com",
    rpcUrl: "https://bsc-dataseed.binance.org/",
  },
  {
    chainId: 137,
    name: "Polygon Mainnet",
    currency: "MATIC",
    explorerUrl: "https://polygonscan.com/",
    rpcUrl: "https://polygon-rpc.com/",
  },
];
const metadata = {
  name: "Coin parliament",
  description: "Coin parliament",
  url: "https://coinparliament.com",
  icons: ["https://coinparliament.com/static/media/logoiconxbig2.7f0c3f73.png"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: mainnet,
  projectId,
  themeVariables: {
    "--w3m-z-index": 1000,
  },
});
const getVotesFunc = httpsCallable<
  { start?: number; end?: number; userId: string },
  GetVotesResponse
>(functions, "getStatusOfPendingVotes");
const getPendingVotes = httpsCallable<{ userId: string }, GetVotesResponse>(
  functions,
  "getPendingVotes"
);
const getPriceCalculation = httpsCallable(
  functions,
  "getOldAndCurrentPriceAndMakeCalculation"
);
const sendPassword = httpsCallable(functions, "sendPassword");
const localhost = window.location.hostname === "localhost";
let ws: any;
let socket: any;

export const BackDiv = styled.div`
  // position:fixed;
  // border:1px solid red;
  // width:100%;
  // height:150vh;
  // background:"red";
`;

function App() {
  document.body.classList.add("bg-Change");
  // document.body.style.zIndex = "400";
  const location = useLocation();
  const search = location.search;
  const pathname = location.pathname;
  const langDetector = useRef(null);
  let navigate = useNavigate();
  const { width } = useWindowSize();
  const [voteNumberEnd, setvoteNumberEnd] = useState<any>(0);
  // const scrollPosition = useScrollPosition();
  const [modalOpen, setModalOpen] = useState(false);
  const checkAndUpdateRewardTotal = httpsCallable(
    functions,
    "checkAndUpdateRewardTotal"
  );

  const paddingTop = (() => {
    if (pathname === "/") return 160;
    if (pathname === "/progress-bar") {
      return window.innerWidth <= 600 ? 0 : 60;
    }
    return 120;
  })();
  useEffect(() => {
    const urlpath = window.location.pathname;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (
      urlpath != "/upgrade" &&
      // urlpath != "/votingbooster" &&
      urlpath != "/paymentList" &&
      urlpath != "/votepayment"
    ) {
      localStorage.removeItem("PayAmount");
    }
  }, [JSON.stringify(location.pathname)]);

  const showModal = useCallback(
    (
      content: ToastContent,
      options: ToastOptions | undefined = {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: true,
        draggable: false,
        progress: undefined,
        containerId: "modal",
        transition: Zoom,
        onOpen: () => setModalOpen(true),
        onClose: () => setModalOpen(false),
      }
    ) => {
      toast(content, options);
    },
    []
  );

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (message) => {
        const {
          notification: { body, title },
        } = message.data["firebase-messaging-msg-data"];
        const typeName = {
          ...message.data["firebase-messaging-msg-data"]?.notification,
        };
      });
    }
  }, []);

  useEffect(() => {
    const body = document.querySelector("body") as HTMLBodyElement;
    const invalidClasses = ["legendary", "rare", "epic", "common", "uncommon"];
    const classes = pathname
      .slice(1)
      .split("/")
      .filter((c) => c && !invalidClasses.includes(c.toLowerCase()));
    body.classList.remove(...Array.from(body.classList));
    if (
      intersection(classes, ["coins", "pairs"]).length &&
      classes.length > 1
    ) {
      classes.push("single");
    }
    classes.forEach((c) => body.classList.add(c.toLowerCase()));
  }, [pathname]);

  const [allCoins, setAllCoins] = useState<string[]>(getAllCoins());
  const [changePrice, setChangePrice] = useState<any>(0);
  const [allPairs, setAllPairs] = useState<Array<string[]>>([]);
  const [allPairsSetting, setAllPairsSetting] = useState<any>([]);
  const [appStats, setAppStats] = useState<AppStats>({} as AppStats);
  const [paxData, setPaxData] = useState<PaxData>({} as PaxData);
  const [authStateChanged, setAuthStateChanged] = useState(false);
  const [allButtonTime, setAllButtonTime] = useState<any>([]);
  const [allPariButtonTime, setAllPariButtonTime] = useState<any>([]);
  const [singalCardData, setSingalCardData] = useState<any>([]);
  const [nftAlbumData, setNftAlbumData] = useState<any>();
  const [forRun, setForRun] = useState<any>(0);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const [pages, setPages] = useState<ContentPage[] | undefined>(myPages);
  const [socketConnect, setSocketConnect] = useState<any>(false);
  const [backgrounHide, setBackgrounHide] = useState<any>(false);
  const [transactionId, setTransactionId] = useState({});
  const [withLoginV2e, setWithLoginV2e] = useState(false);
  const [paxDistribution, setPaxDistribution] = useState(0);
  const [addPaxWalletPop, setAddPaxWalletPop] = useState(false);
  const [walletTab, setWalletTab] = useState("Receive");
  const [historyTab, setHistoryTab] = useState("Purchase History");
  const [megaSpinData, setMegaSpinData] = useState<any>({});
  const [freeSpinData, setFreeSpinData] = useState<any>({});
  const [contDownValue, setContDownValue] = useState(0);
  const [boosterButtonBlink, setBoosterButtonBlink] = React.useState(false);
  // @ts-ignore
  const getCoinPrice = localStorage.getItem("CoinsPrice")
    ? // @ts-ignore
      JSON.parse(localStorage.getItem("CoinsPrice"))
    : {};
  const [localPrice, setLocalPrice] = useState<any>(getCoinPrice);
  const [coins, setCoins] = useState<{ [symbol: string]: Coin }>({});
  const coinsValue = useRef<{ [symbol: string]: Coin }>({});
  const [myCoins, setMyCoins] = useState<{ [symbol: string]: Coin }>(
    socketConnect ? (getCoins() as { [symbol: string]: Coin }) : localPrice
  );
  let params = useParams();
  const [symbol1, symbol2] = (params?.id || "").split("-");
  const [loader, setLoader] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const [showMenubar, setShowMenuBar] = useState(false);
  const [user, setUser] = useState<User>();
  const [userInfo, setUserInfo] = useState<UserProps>();
  const [displayName, setDisplayName] = useState<string>("");
  const [leaders, setLeaders] = useState<Leader[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [newnotification, setNewNotification] = useState(0);
  const [profileTab, setProfileTab] = useState(ProfileTabs.profile);
  const [firstTimeAvatarSlection, setFirstTimeAvatarSelection] =
    useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const [selectBioEdit, setSelectBioEdit] = useState(false);
  const [firstTimeFoundationSelection, setFirstTimeFoundationSelection] =
    useState(false);
  const [loginRedirectMessage, setLoginRedirectMessage] = useState("");
  const [userUid, setUserUid] = useState("");
  const [chosenUserType, setChosenUserType] = useState<string>("");
  const [lang, setLang] = useState<string>(
    getLangByKey(new URLSearchParams(search).get("lang") + "")
  );
  const [totals, setTotals] = useState<{ [key: string]: Totals }>(
    {} as { [key: string]: Totals }
  );
  const [timeframes, setTimeframes] = useState<TimeFrame[]>([]);
  const [upgraeShowOpen, setUpgraeShowOpen] = React.useState(false);
  const [voteRules, setVoteRules] = useState<VoteRules>({} as VoteRules);
  const [languages, setLanguages] = useState<string[]>([ENGLISH]);
  const [rtl, setRtl] = useState<string[]>([]);
  const [admin, setAdmin] = useState<boolean | undefined>(undefined);
  const [remainingTimer, setRemainingTimer] = useState(0);
  const [followerUserId, setFollowerUserId] = useState<string>("");
  const [showBack, setShowBack] = useState<any>(false);
  const [showReward, setShowReward] = useState<any>(0);
  const [inOutReward, setInOutReward] = useState<any>(0);
  const [headerExtraVote, setHeaderExtraVote] = useState<number>(0);
  const [rewardExtraVote, setRewardExtraVote] = useState<number>(0);
  const [afterVotePopup, setAfterVotePopup] = useState<any>(false);
  const [isVirtualCall, setIsVirtualCall] = useState<any>(false);
  const [avatarImage, setAvatarImage] = useState<any>(null);
  const [connectCall, setConnectCall] = useState<any>(false);
  const [albumOpen, setAlbumOpen] = useState<any>("");
  const [extraVoteModule, setExtraVoteModule] = useState<any>(false);
  const [userChildern, setUserChildern] = useState<Leader[]>([]);
  const [userLeadersData, setUserLeadersData] = useState<Leader[]>([]);  
  const [spinCallOneTime, setSpinCallOneTime] = useState<any>(false);  
  const [userSubscribersData, setUserSubscribersData] = useState<Leader[]>([]);
  const [oneVoteOnly, setOneVoteOnly] = useState<boolean>(false);
  const localID = localStorage.getItem("userId") || false;  
  const [notificationCount, setNotificationCount] = useState(0)
  const [TradeHistoryShow, setTradeHistoryShow] = useState("Openoffer");
  const [tradeHistoryTab, setTradeHistoryTab] = useState(0)
  const [openOfferTab, setOpenOfferTab] = useState(0)
  const [boosterVote, setBoosterVote] = useState(1)
  const [claimRewardStart, setClaimRewardStart] = useState(false)
  const [headerCpmStart, setHeaderCpmStart] = useState(false)
  const [lastBoosterLength, setLastBoosterLength] = useState(1)
  const [isWLDPEventRegistered, setIsWLDPEventRegistered] =
    useState<boolean>(false);
  const isFirstTimeLoginSetTimestamp = httpsCallable(
    functions,
    "isFirstTimeLoginSetTimestamp"
  );
  // const [localID, setLocalID] = useState<any>(

  // )

  const [CPMSettings, setCPMSettings] = useState<CPMSettings>(
    {} as CPMSettings
  );
  const [votesLast24Hours, setVotesLast24Hours] = useState<VoteResultProps[]>(
    []
  );
  const [userTypes, setUserTypes] = useState<UserTypeProps[]>([
    defaultUserType,
  ] as UserTypeProps[]);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [pwaPopUp, setPwaPopUp] = useState("block");
  const [seletcardHolderType, setSeletcardHolderType] = useState("Holders");
  const [mfaLogin, setMfaLogin] = useState(false);
  const [allCoinsSetting, setAllCoinsSetting] = useState([]);
  const [animateBox, setAnimateBox] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  // console.log(coins, "allcoinsCheck")

  // const Coinkeys = Object.keys(coins && coins) || []

  const updateCoinValue = (newValue: { [symbol: string]: Coin }) => {
    // console.log(newValue,"newValueupdated")
    coinsValue.current = newValue;
  };

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt: any) => {
    // evt.preventDefault();
    console.log("not supported", promptInstall);
    if (!promptInstall) {
      return;
    }
    // @ts-ignore
    else promptInstall.prompt();
  };
  if (!supportsPWA) {
    // console.log('not supported')
  }
  useEffect(() => {
    if (!!user?.email && !userInfo && !login) {
      setLoader(true);
    } else if (!Object.keys(coinsValue?.current).length && !socketConnect) {
      setLoader(true);
    }
    // else if (!!user?.email && !userInfo) {
    //   setLoader(true);
    // }
    else {
      setLoader(false);
    }
  }, [
    user,
    userInfo,
    login,
    socketConnect,
    JSON.stringify(Object.keys(coins).length),
  ]);

  const updateUser = useCallback(async (user?: User) => {
    setUser(user);
    const info = await getUserInfo(user);
    setUserInfo(info);
    setDisplayName(info.displayName + "");
  }, []);

  useEffect(() => {
    const refer = new URLSearchParams(search).get("refer");
    if (refer && !user) {
      setLogin(false);
      setSignup(false);
    } else {
      const isMFAPassed = window.localStorage.getItem("mfa_passed");
      if (!user && isMFAPassed !== "true") {
        // console.log('2faCalled3')
        setLogin(false);
        setSignup(false);
      }
    }
    if (auth?.currentUser) {
      setLogin(false);
    }
  }, [location, search, JSON.stringify(auth?.currentUser)]);

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/", {replace: true});
  //   }
  // }, [user]);

  useEffect(() => {
    // @ts-ignore

    if (
      (user && userInfo && userInfo?.displayName === "" && userUid) ||
      userInfo?.firstTimeLogin
    ) {
      if (userInfo?.firstTimeLogin == true && userInfo?.uid) {
        const sendObj = {
          userId: userInfo?.uid,
        };
        isFirstTimeLoginSetTimestamp(sendObj)
          .then((result) => {
            console.log(result, "isFirstTimeLoginSetTimestamp");
          })
          .catch((err) => {
            console.log(err, "isFirstTimeLoginSetTimestamp");
          });
      }
      setFirstTimeLogin(true);
      setShowMenuBar(true);
    }

    // For check pwa popup
    if (userInfo?.voteStatistics) {
      let prevScore: any = userInfo?.voteStatistics?.score % 100;
      localStorage.setItem(`${user?.uid}_prevScores`, prevScore);
      localStorage.setItem(`${user?.uid}_prevScoresHeader`, prevScore);
    }    
    // for get massage
    getMessageToken();
  }, [userInfo]);

  useEffect(() => {
    pwaInstallHandler.addListener((canInstall) => {
      canInstall ? setPwaPopUp("block") : setPwaPopUp("none");
    });
  }, []);

  // useEffect(() => {
  //   setMounted(true);
  // }, [firstTimeLogin]);

  const [fcmToken, setFcmToken] = useState<string>("");
  const CPMSettingsMng = new CPMSettingsManager(CPMSettings, setCPMSettings);
  const VoteRulesMng = new VoteRulesManager(voteRules, setVoteRules);
  const TimeframesMng = new TimeframesManager(timeframes, setTimeframes);
  const UserTypeMng = new UserTypeManager(userTypes, setUserTypes);

  if (langDetector.current) {
    (langDetector?.current as unknown as HTMLInputElement)?.addEventListener(
      "change",
      (event) => {
        const target = event.target as HTMLInputElement;
        setLang(
          getLangByKey(new URLSearchParams(search).get("lang") || target.value)
        );
      }
    );
  }

  // useEffect(() => {
  //   if (backgrounHide) {
  //     window.scrollTo({
  //       top: 500,
  //       behavior: 'smooth',
  //     });
  //   }
  // }, [backgrounHide])

  // useEffect(() => {

  // }, [userInfo]);

  const getMessageToken = async () => {
    const messagingResolve = await messaging;
    // console.log(messagingResolve,"messagingResolve")
    if (messagingResolve) {
      getToken(messagingResolve, {
        vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY,
      })
        .then((token) => {
          setFcmToken(token);
          // console.log('token', token);
        })
        .catch((e) => {
          console.log("token", e);
          // setLoader(false)
        });
    }
  };

  useEffect(() => {
    const localStorageLang = localStorage.getItem("lang");
    if (localStorageLang && languages.includes(localStorageLang)) {
      setMounted(true);
    }
  }, [languages]);
  // console.log('user', userInfo)

  useEffect(() => {
    if (user?.uid) {
      setDoc(doc(db, "users", user?.uid), { lang }, { merge: true }).then(
        void 0
      );

      localStorage.setItem("lang", lang);
    }
  }, [lang, user?.uid]);

  // const isAdmin = useCallback(async (uid?: string) => {
  //   if (uid) {
  //     const func = httpsCallable(functions, "isAdmin");
  //     return !!(await func({ user: uid })).data;
  //   }
  // }, []);

  // useEffect(() => {
  //   isAdmin(user?.uid).then((newAdmin) => setAdmin(newAdmin));
  // }, [user?.uid, isAdmin]);

  const getCoinData = async () => {
    const coinData = doc(db, "stats", "coins");
    try {
      const userDocSnapshot = await getDoc(coinData);

      if (userDocSnapshot.exists()) {
        // setDBCoins(userDocSnapshot.data());
        const checkLivePrice = await getPriceFirstTime(
          userDocSnapshot.data(),
          setCoins
        );
        // setCoins(userDocSnapshot.data());
        console.log(checkLivePrice, "checkLivePrice");
        console.log("i am update price ");
        coinsValue.current = checkLivePrice || userDocSnapshot.data();
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  useEffect(() => {
    onSnapshot(doc(db, "stats", "leaders"), (doc) => {
      setLeaders((doc.data() as { leaders: Leader[] })?.leaders || []);
    });

    // onSnapshot(
    //   query(
    //     collection(db, "notifications"),
    //     where("user", "==", user?.uid || ""),
    //   ),
    //   (querySnapshot) => {
    //     setNotifications(
    //       querySnapshot.docs.map((doc) => {
    //         return doc.data() as NotificationProps;
    //       })
    //     );
    //   }
    // );

    // const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //   const data:any = [];
    //   querySnapshot.forEach((doc) => {
    //     data.push({ id: doc.id, ...doc.data() });
    //   });
    //   setNotifications(data);
    // });

    onSnapshot(doc(db, "stats", "totals"), (doc) => {
      setTotals((doc.data() || {}) as { [key: string]: Totals });
    });

    onSnapshot(doc(db, "settings", "timeframes"), (doc) => {
      setTimeframes(
        ((doc.data() as { timeframes: TimeFrame[] }) || {}).timeframes || []
      );
    });

    onSnapshot(doc(db, "settings", "userTypes"), (doc) => {
      const u = (
        (doc.data() || {
          userTypes: [defaultUserType],
        }) as { userTypes: UserTypeProps[] }
      ).userTypes;

      setUserTypes(u);
    });

    onSnapshot(doc(db, "settings", "settings"), (doc) => {
      setVoteRules(
        ((doc.data() || {}) as { voteRules: VoteRules }).voteRules || {}
      );
      setCPMSettings(
        ((doc.data() || {}) as { CPMSettings: CPMSettings }).CPMSettings || {}
      );
    });

    // onSnapshot(doc(db, "settings", "languages"), (doc) => {
    //   const u = (
    //     (doc.data() || {
    //       languages: [ENGLISH],
    //     }) as { languages: string[] }
    //   ).languages;

    //   setLanguages(u);
    // });

    // onSnapshot(doc(db, "settings", "rtl"), (doc) => {
    //   const r = (
    //     (doc.data() || {
    //       rtl: [],
    //     }) as { rtl: string[] }
    //   ).rtl;

    //   setRtl(r);
    // });

    // onSnapshot(collection(db, "translations"), (querySnapshot) => {
    //   querySnapshot.docs.forEach((doc) => {
    //     translations.set(doc.id, doc.data());
    //   });
    // });
    if (user?.uid) {
      console.log("yes i am runing");
      getAllNotifications(user?.uid, setNotifications, 10 );
      cardTradNotificationsCount(user?.uid,setNotificationCount,20)
    }
    getCoinData();

    onSnapshot(doc(db, "stats", "app"), (doc) => {
      setAppStats(doc.data() as AppStats);
    });

    onSnapshot(doc(db, "settings", "paxData"), (doc) => {
      setPaxData(doc.data() as PaxData);
    });

    onSnapshot(doc(db, "settings", "coins"), (doc) => {
      const newAllCoins = (
        ((doc.data() as { coins: DBCoin[] }) || {}).coins || []
      )
        .sort((a, b) => Number(a.id) - Number(b.id))
        .map((c) => c.symbol);
      const newAllCoinsData = (
        ((doc.data() as { coins: DBCoin[] }) || {}).coins || []
      )
        .sort((a, b) => Number(a.id) - Number(b.id))
        .map((c) => c);
      saveAllCoins(newAllCoins);
      setAllCoins(newAllCoins);
      // @ts-ignore
      setAllCoinsSetting(newAllCoinsData);
    });

    onSnapshot(doc(db, "settings", "pairs"), (doc) => {
      setAllPairs(
        (((doc.data() as { pairs: DBPair[] }) || {}).pairs || [])
          .sort((a, b) => Number(a.id) - Number(b.id))
          .map((p) => {
            return [p.symbol1, p.symbol2];
          })
      );
      const newAllPairData = (
        (((doc.data() as { pairs: DBPair[] }) || {}).pairs || [])
      )
      setAllPairsSetting(newAllPairData)
    });

    if (user?.uid) {
      axios
        .post(
          "https://us-central1-votetoearn-9d9dd.cloudfunctions.net/getCurrentPaxDistribution",
          {
            data: {},
          }
        )
        .then((res) => {
          setPaxDistribution(res.data.result.paxDistribution);
        })
        .catch((err) => {
          console.log(err, "resultdata");
        });
    }
  }, [user?.uid]);

  window.onbeforeunload = function () {
    //  localStorage.clear();
    const allCoinPrice = coins;
    localStorage.setItem("CoinsPrice", JSON.stringify(allCoinPrice));
  };
  useEffect(() => {
    const auth = getAuth();
    if (!firstTimeLogin) {
      onAuthStateChanged(auth, async (user: User | null) => {
        setAuthStateChanged(true);
        if (
          user?.emailVerified ||
          user?.providerData[0]?.providerId === "facebook.com" || user?.uid.includes("telegram")
        ) {
          // setLogin(false);
          // setSignup(false);
          setLoginRedirectMessage("");
          await updateUser(user);
          setUserUid(user?.uid);

          localStorage.setItem("userId", user.uid);

          onSnapshot(doc(db, "users", user.uid), async (doc) => {
            await setUserInfo(doc.data() as UserProps);
            setDisplayName((doc.data() as UserProps).displayName + "");
          });
        } else {
          await updateUser();
        }
      });
    }
  }, [user]);

  useEffect(() => {
    const setFcmToken = async () => {
      if (fcmToken && user?.uid) {
        try {
          await setDoc(
            doc(db, "users", user.uid),
            { token: fcmToken },
            { merge: true }
          );
          // console.log("push enabled");
        } catch (e) {
          console.log(e);
        }
      }
    };
    setFcmToken();

    return () => {};
  }, [fcmToken,user?.uid]);

  // useEffect(() => {
  //   auth.signOut();
  // }, []);

  // const fetchVotesLast24Hours = async () => {
  //   if (voteNumberEnd == 0 && user?.uid && userInfo?.lastVoteTime) {
  //     // @ts-ignore
  //     let remaining =
  //       userInfo?.lastVoteTime + voteRules.timeLimit * 1000 - Date.now();
  //     // @ts-ignore
  //     setRemainingTimer(userInfo?.lastVoteTime + voteRules.timeLimit * 1000);
  //     setTimeout(() => {
  //       const userDocRef = doc(firestore, "users", user?.uid);
  //       try {
  //         setDoc(
  //           userDocRef,
  //           {
  //             voteValue: voteRules?.maxVotes,
  //             lastVoteTime: 0,
  //           },
  //           { merge: true }
  //         );
  //       } catch (error) {
  //         console.error("Error updating user data:", error);
  //       }
  //     }, remaining);

  //     // const currentTime = firebase.firestore.Timestamp.fromDate(new Date());
  //     const currentTime = Timestamp.fromDate(new Date());

  //     // const last24Hour = currentTime.toMillis() - 24  60  60 * 1000;
  //     const last24Hour = currentTime.toMillis() - voteRules.timeLimit * 1000;
  //     const votesCollectionRef = collection(firestore, "votes");
  //     try {
  //       const q = query(
  //         votesCollectionRef,
  //         where("userId", "==", user?.uid),
  //         where("voteTime", ">=", last24Hour),
  //         where("voteTime", "<=", Date.now())
  //       );

  //       const querySnapshot = await getDocs(q);

  //       setVotesLast24Hours(
  //         querySnapshot.docs.map(
  //           (doc: any) => doc.data() as unknown as VoteResultProps
  //         )
  //       );
  //       // If you need the data separately
  //       const data = querySnapshot.docs.map(
  //         (doc: any) => doc.data() as unknown as VoteResultProps
  //       );
  //       // console.log(data);
  //     } catch (error) {
  //       console.error("Error fetching votes in the last 24 hours:", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (user?.uid) {
  //     fetchVotesLast24Hours();
  //   }
  // }, [voteNumberEnd, userInfo?.lastVoteTime]);

  useEffect(() => {
    const html = document.querySelector("html") as HTMLElement;
    const key = getKeyByLang(lang);
    html.setAttribute("lang", key);
    if (rtl.includes(lang)) {
      html.setAttribute("dir", "rtl");
    } else {
      html.removeAttribute("dir");
    }
  }, [lang, rtl, user?.uid]);

  const [enabled, enable] = useState(true);
  const [password, setPassword] = useState("");
  // const [socketUrl, setSocketUrl] = useState("wss://stream.binance.com:9443/wss");
  const socketUrl = useRef("wss://stream.binance.com:9443/ws");
  const errorCount = useRef(0);
  // const [localPrice, setLocalPrice] = useState<any>(getCoinPrice)
  const livePrice = useRef<{ [symbol: string]: Coin }>(
    Object.keys(getCoinPrice).length ? getCoinPrice : coinsValue?.current
  );
  function connect() {
    // console.log('Browser window called', wsConnectRetry ,"---run count", Object.keys(coins).length,coins)
    if (Object.keys(coinsValue.current).length === 0) return;
    console.log("Browser window called");

    ws = new WebSocket(socketUrl.current);
    console.log("websocket connected first time");
    const coinTikerList = Object.keys(coinsValue.current).map(
      (item) => `${item.toLowerCase()}usdt@ticker`
    );
    ws.onopen = () => {
      if (ws.readyState === WebSocket.OPEN) {
        errorCount.current = 0;
        ws.send(
          JSON.stringify({
            method: "SUBSCRIBE",
            params: coinTikerList,
            id: 1,
          })
        );
        ws.onmessage = (event: any) => {
          const message = JSON.parse(event.data);
          const symbol = message?.s?.slice(0, -4);
          if (symbol && Object.keys(coinsValue?.current).includes(symbol)) {
            // @ts-ignore
            const dot = decimal[symbol];
            livePrice.current = {
              ...livePrice.current,
              [symbol]: {
                ...livePrice.current[symbol],
                name: livePrice.current[symbol]?.name,
                symbol: symbol,
                price: Number(message?.c).toFixed(dot?.decimal || 2),
                // randomDecimal:
                //   Number(Number(message?.c).toFixed(dot?.decimal || 2)) ==
                //   Number(livePrice.current[symbol]?.price)
                //     ? livePrice.current[symbol]?.randomDecimal
                //     : 5,
                randomDecimal: Math.floor(Math.random() * 9),                  
              },
            };
            // setCoins(livePrice.current)
            if (Object.keys(livePrice.current).length) {
              localStorage.setItem(
                "CoinsPrice",
                JSON.stringify(livePrice.current)
              );
            }
          }
        };
        setSocketConnect(true);
      }
    };
    ws.onclose = async (event: any) => {
      setSocketConnect(false);
      setTimeout(() => {
        handleError();
      }, 10000);
    };
    ws.onerror = (event: any) => {
      setSocketConnect(false);
    };
  }

  let hasReopened = false;
  const handleError = () => {
    if (!hasReopened) {
      errorCount.current++;
    }
    if (errorCount.current >= 5 && !hasReopened) {
      hasReopened = true;
      ReloadPop();
    } else if (errorCount.current < 5) {
      connect();
    } else {
      console.log("Error count exceeds 5, not reconnecting.");
    }
  };

  function croConnect() {
    const connectWebSocket = () => {
      socket = new WebSocket("wss://stream.crypto.com/v2/market");

      socket.onopen = () => {
        // Subscribe to CRO/USDT pair
        socket.send(
          JSON.stringify({
            id: 1,
            method: "subscribe",
            params: {
              channels: ["tickers"],
              instrument_name: ["CRO_USDT"],
            },
          })
        );
        setRetryCount(0);
        // setError(null);
      };
      socket.onerror = (error: any) => {
        console.error("WebSocket error:", error);
        setTimeout(() => {
          connectWebSocket();
        }, 10000);
        // setError(error);
      };

      socket.onclose = () => {
        console.log("WebSocket closed");
        if (retryCount < 5) {
          // Retry connecting after a delay (exponential backoff)
          const delay = Math.pow(2, retryCount) * 1000;
          setTimeout(connectWebSocket, delay);
          setRetryCount(retryCount + 1);
        } else {
          // setError('Max retry attempts reached');
          console.log("Max retry attempts reached");
        }
      };
    };
  }

  useEffect(() => {
    if (Object.keys(coins).length) {
      if (socketConnect) return;

      console.log("yes i am calling");
      connect();
      croConnect();
    }
    return () => {
      if (ws) ws.close();
      if (socket) socket.close();
      window.localStorage.removeItem("firstTimeloading");
    };
  }, [Object.keys(coins).length]);

  const checkprice = async (vote: any) => {
    console.log(vote, "checkAllvote");
    const voteCoins = vote?.coin.split("-");
    const coin1 = `${voteCoins[0] ? voteCoins[0].toLowerCase() || "" : ""}`;
    const coin2 = `${voteCoins[1] ? voteCoins[1].toLowerCase() || "" : ""}`;
    const data = await getPriceCalculation({
      coin1: `${coin1 != "" ? coin1 + "usdt" : ""}`,
      coin2: `${coin2 != "" ? coin2 + "usdt" : ""}`,
      voteId: vote?.id,
      voteTime: vote?.voteTime,
      valueVotingTime: vote?.valueVotingTime,
      // valueExpirationTimeOfCoin1: vote?.valueVotingTime[0] || null,
      // valueExpirationTimeOfCoin2: vote?.valueVotingTime[1] || null,
      multiplier: vote?.multiplier || 1,
      paxDistributionToUser: {
        userId: vote?.userId,
        currentPaxValue: Number(paxDistribution),
        isUserUpgraded: userInfo?.isUserUpgraded == true ? true : false,
        mintForUserAddress: userInfo?.paxAddress?.address || "",
        eligibleForMint: userInfo?.paxAddress?.address ? true : false,
      },
      expiration: vote?.expiration,
      timestamp: Date.now(),
      userId: vote?.userId,
    })
      .then((data: any) => {
        const raw = {
          userId: vote?.userId,
        };
        checkAndUpdateRewardTotal(raw)
          .then((res) => {
            // console.log(res.data, "checkAndUpdateRewardTotal");
          })
          .catch((error) => {
            console.log(error, "checkAndUpdateRewardTotal");
          });
        console.log("success");
        // if(data.data==null){
        //     getVotes(index).then(void 0);
        // }
      })
      .catch((err: any) => {
        const raw = {
          userId: vote?.userId,
        };
        checkAndUpdateRewardTotal(raw)
          .then((res) => {
            // console.log(res.data, "checkAndUpdateRewardTotal");
          })
          .catch((error) => {
            console.log(error, "checkAndUpdateRewardTotal");
          });
        if (err && err.message) {
          console.log(err.message);
        }
      });
  };


  useEffect(() => {
    const mediaQueryList = window.matchMedia("(display-mode: standalone)");

    const handleChange = (event: any) => {
      // @ts-ignore
      if (
        event.matches &&
        // @ts-ignore
        (!userInfo?.isPWAinstalled || userInfo?.isPWAinstalled == undefined)
      ) {
        // @ts-ignore
        const userRef = doc(db, "users", userInfo?.uid);
        setDoc(userRef, { isPWAinstalled: true }, { merge: true });
      }
    };

    // Initially check if the PWA is already open
    if (userInfo?.uid) {
      handleChange(mediaQueryList);
    }
    const totalValue = Number(userInfo?.voteValue || 0) +
      Number(userInfo?.rewardStatistics?.extraVote || 0)
    if (totalValue > 5) {
      setBoosterButtonBlink(true);
    }    
    // Listen for changes in display mode
    mediaQueryList.addListener(handleChange);

    // Clean up the listener
    return () => {
      mediaQueryList.removeListener(handleChange);
    };
  }, [userInfo?.uid]);

  const getVotes = useCallback(async () => {
    if (user?.uid) {
      const newVotes = await getPendingVotes({
        userId: user?.uid,
      });

      // @ts-ignore
      let result = JSON.parse(newVotes?.data);
      if (newVotes?.data) {
        const { coins, pairs } = result;

        let AllCoins = coins?.votes.filter((item: any) => {
          if (item?.expiration < Date.now() && item?.success == undefined) {
            return item;
          }
        });

        let AllPairs = pairs?.votes.filter((item: any) => {
          if (item?.expiration < Date.now() && item?.success == undefined) {
            return item;
          }
        });

        let allCoinsPair = [...AllCoins, ...AllPairs];
        let promiseArray: any = [];
        if (allCoinsPair.length > 0 && paxDistribution > 0) {
          allCoinsPair?.forEach((voteItem: any) => {
            promiseArray.push(checkprice(voteItem));
            // checkprice(voteItem);
          });
        }

        // console.log('promisearray', promiseArray, allCoinsPair)
        if (!promiseArray?.length) return;
        await Promise.all(promiseArray)
          .then(async (responses) => {
            // getVotes().then(void 0);
            await getVotesFunc({ userId: user?.uid })
              .then(async (result) => {
                await getVotes().then(void 0);
              })
              .catch(async (err) => {
                await getVotes().then(void 0);
                // console.log(err)
              });
            //  return Promise.all(responses)
          })
          .catch((error) => {
            console.error("promiseAll", error);
          });
      }
    }
  }, [user?.uid, paxDistribution]);

  useEffect(() => {
    if (user?.uid) {
      getVotes().then(void 0);
    }
  }, [user?.uid]);


  useEffect(() => {
    const totalValue = Number(userInfo?.voteValue || 0) + Number(userInfo?.rewardStatistics?.extraVote || 0)
    let boosterArray = []
    if (totalValue > 0) {
      boosterArray.push(1);
    }
    if (totalValue > 5) {
      boosterArray.push(3);
    }
    if (totalValue > 20) {
      boosterArray.push(5);
    }
    if (totalValue > 50) {
      boosterArray.push(10);
    }
    if (totalValue > 150) {
      boosterArray.push(15);
    }
    if (totalValue > 250) {
      boosterArray.push(25);
    }
    if (totalValue > 500) {
      boosterArray.push(50);
    }
    if (totalValue > 1000) {
      boosterArray.push(100);
    } 
    console.log(boosterArray?.length,lastBoosterLength,"lastBoosterLength")
    if (boosterArray?.length > lastBoosterLength) {      
      console.log(boosterArray?.length,lastBoosterLength,"lastBoosterLength")
      setLastBoosterLength(boosterArray?.length) 
      setBoosterButtonBlink(true);
    }
    if (lastBoosterLength > boosterArray?.length) {
      setLastBoosterLength(boosterArray?.length)       
    }

  }, [userInfo?.rewardStatistics?.extraVote ,userInfo?.voteValue])
  

  /// show 100 CMP complete modal
  const [completedVoteCMP, setCompletedVoteCMP] = useState<number>(0);
  const [showComplete100CMP, setShowComplete100CMP] = useState(false);
  ///show 100 CMP complete modal

  ///start vote result //
  const voteDetails = useContext(VoteContext);
  const setVoteDetails = useContext(VoteDispatchContext);

  const completedVotes = useContext(CompletedVotesContext);
  const setCompletedVotes = useContext(CompletedVotesDispatchContext);

  const getPriceCalculation = httpsCallable(
    functions,
    "getOldAndCurrentPriceAndMakeCalculation"
  );
  const getResultAfterVote = httpsCallable(functions, "getResultAfterVote");
  // const [lessTimeVoteDetails, setLessTimeVoteDetails] = useState<VoteResultProps | undefined>();
  const lessTimeVoteDetails = useContext(LessTimeVoteDetailContext);
  const setLessTimeVoteDetails = useContext(LessTimeVoteDetailDispatchContext);
  const setCurrentCMP = useContext(CurrentCMPDispatchContext);
  const currentCMP = useContext(CurrentCMPContext);
  const voteEndCoinPrice = useContext(VoteEndCoinPriceContext);
  const prevCMP = useRef();
  const latestCMP = useRef();

  // useEffect(() => {
  //   // console.log(voteDetails?.activeVotes,lessTimeVoteDetails,completedVotes,'history');

  // }, [JSON.stringify({ voteDetails, lessTimeVoteDetails, completedVotes })])
  useEffect(() => {
    if (completedVotes.length > 0 && !voteDetails.openResultModal) {
      Swal.close();
      setVoteDetails((prev: VoteContextType) => {
        return {
          ...prev,
          lessTimeVote: completedVotes[0],
          openResultModal: true,
        };
      });
      setCurrentCMP(
        (completedVotes[0]?.score || 0) +
          parseFloat(localStorage.getItem(`${user?.uid}_newScores`) || "0")
      );
      //@ts-ignore
      let prevScore: any = userInfo?.voteStatistics?.score % 100;
      prevCMP.current = prevScore.toFixed(3);
      let latestScore: any = (
        prevScore +
        (completedVotes[0]?.score || 0) +
        //@ts-ignore
        parseFloat(localStorage.getItem(`${user?.uid}_newScores`))
      ).toFixed(3);
      latestCMP.current = latestScore;
      localStorage.setItem(`${user?.uid}_prevScores`, prevScore);
      localStorage.setItem(`${user?.uid}_prevScoresHeader`, prevScore);
      // if (!pathname.toLowerCase().includes(`profile/mine`)) {
        localStorage.setItem(
          `${user?.uid}_newScores`,
          `${
            (completedVotes[0]?.score || 0) +
            parseFloat(localStorage.getItem(`${user?.uid}_newScores`) || "0")
          }`
        );

        localStorage.setItem(
          `${user?.uid}_newScoresHeader`,
          `${
            (completedVotes[0]?.score || 0) +
          parseFloat(localStorage.getItem(`${user?.uid}_newScoresHeader`) || "0")
          }`
        );

      // }
      setCompletedVoteCMP(completedVotes[0]?.score || 0);
    }
  }, [completedVotes, voteDetails.openResultModal]);

  useEffect(() => {
    let tempTessTimeVote: VoteResultProps | undefined;
    Object.keys(voteDetails?.activeVotes).map((value) => {
      if (
        !tempTessTimeVote ||
        tempTessTimeVote.expiration >
          voteDetails?.activeVotes[value]?.expiration
      ) {
        tempTessTimeVote = voteDetails?.activeVotes[value];
      }
      return {};
    });
    if (
      tempTessTimeVote &&
      lessTimeVoteDetails?.voteId !== tempTessTimeVote.voteId &&
      !pathname.includes("profile/mine")
    ) {
      setLessTimeVoteDetails(tempTessTimeVote);
      timeEndCalculation(tempTessTimeVote);
      // setCalculateVote(false);
    }
  }, [JSON.stringify(voteDetails?.activeVotes), pathname]);

  const voteImpact = useRef<{
    timeFrame: number;
    impact: null | number;
  }>({
    timeFrame: 0,
    impact: null,
  });
  const latestVote = useRef<VoteContextType>();
  const latestCoinsPrice = useRef<VoteEndCoinPriceType>({});
  const latestUserInfo = useRef<UserProps | undefined>();
  const pathNameRef = useRef<string>();

  useEffect(() => {
    pathNameRef.current = pathname;
  }, [pathname]);

  useEffect(() => {
    latestCoinsPrice.current = voteEndCoinPrice;
  }, [voteEndCoinPrice]);

  useEffect(() => {
    voteImpact.current = voteDetails.voteImpact;
    latestVote.current = voteDetails;
  }, [voteDetails]);

  const getCalculateDiffBetweenCoins = (
    valueVotingTime: any,
    valueExpirationTime: any,
    direction: number
  ) => {
    const firstCoin =
      ((valueExpirationTime[0] - valueVotingTime[0]) * 100) /
      valueVotingTime[0];
    const secondCoin =
      ((valueExpirationTime[1] - valueVotingTime[1]) * 100) /
      valueVotingTime[1];
    const difference = (
      direction === 0 ? firstCoin - secondCoin : secondCoin - firstCoin
    ).toFixed(4);
    return {
      firstCoin: firstCoin.toFixed(4) || "0",
      secondCoin: secondCoin.toFixed(4) || "0",
      difference: difference || "0",
    };
  };

  const timeEndCalculation = (lessTimeVote: VoteResultProps) => {
    if (lessTimeVote) {
      // let exSec = new Date(-).getSeconds();
      // current date
      let current = new Date();
      // voteTime date
      let voteTime = new Date(lessTimeVote?.expiration);
      // finding the difference in total seconds between two dates
      let second_diff = (voteTime.getTime() - current.getTime()) / 1000;
      console.log(lessTimeVote, latestCoinsPrice.current, "timer1");
      const timer = setTimeout(async () => {
        try {
          console.log(lessTimeVote, "timer2");
          const coin = lessTimeVote?.coin.split("-") || [];
          const coin1 = `${
            coins && lessTimeVote?.coin[0]
              ? coins[coin[0]]?.symbol?.toLowerCase() || ""
              : ""
          }`;
          const coin2 = `${
            coins && coin?.length > 1
              ? coins[coin[1]]?.symbol?.toLowerCase() || ""
              : ""
          }`;
          const ExpriTime = [
            latestCoinsPrice.current[
              `${lessTimeVote?.coin.toUpperCase()}_${
                lessTimeVote?.timeframe?.seconds
              }`
            ]?.coin1 || null,
            latestCoinsPrice.current[
              `${lessTimeVote?.coin.toUpperCase()}_${
                lessTimeVote?.timeframe?.seconds
              }`
            ]?.coin2 || null,
          ];
          const getValue =
            coin2 != "" &&
            (await getCalculateDiffBetweenCoins(
              lessTimeVote?.valueVotingTime,
              ExpriTime,
              lessTimeVote.direction
            ));
          // @ts-ignore
          var StatusValue =
            coin2 != ""
              ? // @ts-ignore
                getValue?.difference < 0
                ? 0
                : // @ts-ignore
                getValue?.difference == 0
                ? 2
                : 1
              : voteImpact.current?.impact;
          if (pathNameRef.current?.includes("profile/mine")) {
            return;
          }
          console.log(voteNumberEnd, "voteNumberEnd");
          console.log(lessTimeVote, voteImpact, voteDetails, "bothdataimpact");
          const request = {
            ...{
              coin1: `${coin1 != "" ? coin1 + "usdt" : ""}`,
              coin2: `${coin2 != "" ? coin2 + "usdt" : ""}`,
              voteId: lessTimeVote?.id,
              voteTime: lessTimeVote?.voteTime,
              valueVotingTime: lessTimeVote?.valueVotingTime,
              expiration: lessTimeVote?.expiration,
              timestamp: Date.now(),
              // multiplier:boosterVote,
              multiplier:await GetVoteDetelis(lessTimeVote?.id),
              userId: lessTimeVote?.userId,
              paxDistributionToUser: {
                userId: lessTimeVote?.userId,
                currentPaxValue: Number(paxDistribution),
                isUserUpgraded: userInfo?.isUserUpgraded == true ? true : false,
                mintForUserAddress: userInfo?.paxAddress?.address || "",
                eligibleForMint: userInfo?.paxAddress?.address ? true : false,
              },
            },
            ...(pathname.includes(lessTimeVote?.coin) &&
            lessTimeVote?.timeframe.index === voteImpact.current?.timeFrame &&
            voteImpact.current?.impact !== null
              ? {
                  status: StatusValue,
                  valueExpirationTimeOfCoin1:
                    latestCoinsPrice.current[
                      `${lessTimeVote?.coin.toUpperCase()}_${
                        lessTimeVote?.timeframe?.seconds
                      }`
                    ]?.coin1 || null,
                  valueExpirationTimeOfCoin2:
                    latestCoinsPrice.current[
                      `${lessTimeVote?.coin.toUpperCase()}_${
                        lessTimeVote?.timeframe?.seconds
                      }`
                    ]?.coin2 || null,
                }
              : {}),
          };
          console.log(lessTimeVote, "timer3");
          console.log(
            pathname,
            lessTimeVote?.coin,
            lessTimeVote?.timeframe.index,
            voteImpact.current?.timeFrame,
            voteImpact.current?.impact,
            "get check all conditions"
          );
          await getResultAfterVote(request)
            .then(async (response) => {
              console.log(getResultAfterVote, "getvote");
              const raw = {
                userId: lessTimeVote?.userId,
              };
              checkAndUpdateRewardTotal(raw)
                .then((res) => {
                  // console.log(res.data, "checkAndUpdateRewardTotal");
                })
                .catch((error) => {
                  console.log(error, "checkAndUpdateRewardTotal");
                });
              // @ts-ignore
              // if (response?.data?.paxDistributionToUser && response?.data?.paxDistributionToUser?.status == true) {
              // afterpaxDistributionToUser(paxDistribution)
              // }

              // @ts-ignore
              // getPriceCalculation(request)
              getPriceCalculation({ ...request, score: response?.data?.score })
                .then(() => {
                  const raw = {
                    userId: lessTimeVote?.userId,
                  };
                  checkAndUpdateRewardTotal(raw)
                    .then((res) => {
                      // console.log(res.data, "checkAndUpdateRewardTotal");
                    })
                    .catch((error) => {
                      console.log(error, "checkAndUpdateRewardTotal");
                    });
                })
                .catch(() => {});

              if (response?.data && Object.keys(response.data).length > 0) {
                const res: VoteResultProps = response!.data as VoteResultProps;
                // @ts-ignore
                if (
                  !!latestVote?.current?.activeVotes[
                    `${res?.coin}_${res?.timeframe.seconds}`
                  ]
                ) {
                  setCompletedVotes((prev: VoteResultProps[]) => {
                    return [
                      ...prev.filter((value) => value.voteId != res.voteId),
                      { ...res, voteType: coin.length > 1 ? "pair" : "coin" },
                    ];
                  });
                }
              }
            })
            .catch((err) => {
              if (err && err.message) {
                console.log(err.message);
              }
            });
        } catch (error) {
          console.log(error, "timerError");
        }
      }, (second_diff || 0) * 1000);
      return () => clearTimeout(timer);
      // }
    }
  };
  const claimReward = httpsCallable(functions, "claimReward");

  useEffect(() => {
    if (
      (userInfo?.rewardStatistics?.total || 0) >
        (userInfo?.rewardStatistics?.claimed || 0) &&
      !isVirtualCall
    ) {
      claimReward({
        uid: user?.uid,
        isVirtual: true,
      })
        .then(() => {})
        .catch(() => {});
    }
    latestUserInfo.current = userInfo;
  }, [
    JSON.stringify(userInfo?.rewardStatistics?.total),
    JSON.stringify(userInfo?.rewardStatistics?.claimed),
    isVirtualCall,
  ]);

  ///END vote result //

  // login user using token
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let token = searchParams.get("token");
    if (token) {
      firebase
        .auth()
        .signInWithCustomToken(token)
        .then((userCredential) => {
          // User is signed in
          const user = userCredential.user;
          if (user && !user?.emailVerified) {
            auth.signOut();
            showToast("Please verify your email address.", ToastType.ERROR);
          }
          UpdateLastLogin(user);
          console.log("Custom token sign-in success: authenticated", user);
          navigate("/");
        })
        .catch((error) => {
          // Handle sign-in errors
          console.error("Custom token sign-in error: authenticated", error);
        });
    }
  }, [searchParams]);

  const isIPhone = /iPhone/i.test(navigator.userAgent);
  const isPWA = window.matchMedia("(display-mode: standalone)").matches;
  useEffect(() => {
    if (isIPhone && !isPWA) {
      setPwaPopUp("block");
    } else {
      setPwaPopUp("none");
    }
  }, []);

  useEffect(() => {
    const acceptedCookies = localStorage.getItem("acceptedCookies");
    if (acceptedCookies || pathname =="/progress-bar") {
      setShowPopup(false);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem("acceptedCookies", "true");
    setShowPopup(false);
  };

  const getSpinData = (userID: any) => {
    if (!spinCallOneTime) {      
      setSpinCallOneTime(true)
      axios
        .get(`/luckySpin/updateFreeSpinAvailable/${userID}`)
        .then((response) => {
          let data = response?.data?.result;
          setSpinCallOneTime(false);
          // data.sort((a: any, b: any) => (a.value - b.value))
          // setPaytypeValue(data)
          if (data?.freeSpinData) {
            setFreeSpinData(data?.freeSpinData);
            if (data?.freeSpinData?.freeSpinAvailable < 1) {
              const date = new Date(
                data?.freeSpinData?.lastSpinTime._seconds * 1000
              );
              const endTime = date.getTime() + 1 * 60 * 60 * 1000;
              // const endTime = date.getTime() + (3 * 60 * 60 * 1000);
              setContDownValue(endTime);              
            }
          }
          if (data?.megaSpinData) {
            setMegaSpinData(data?.megaSpinData);
          }
          console.log(data, "response.data.result");
        })
        .catch((error) => {
          console.log("getPaymentList not found", error);
          setSpinCallOneTime(false);
        });
    }
  };

  return loader ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", width: "100vw", color: "white" }}
    >
      <Spinner />
    </div>
  ) : (
    <BackDiv
      style={
        {
          // transform: "scale(4.3)",
          // backgroundColor: "rgba(0,0,0,0.5)",
        }
      }
    >
      {showPopup && (
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              position: "fixed",
              zIndex: "99995",
            }}
          ></div>
          <CookieConsent
            debug={true}
            cookieName="myAwesomeCookieName2"
            style={{
              background: "white",
              color: "#7767f7",
              alignItems: "center",
              padding: "16px",
              margin: "about",
              zIndex: 99999,
              justifyContent: "center",
              flex: "none",
            }}
            buttonText="Accept All"
            buttonStyle={{
              width: "100px",
              borderRadius: "50px",
              background: "#7767f7",
              color: "white",
            }}
            onAccept={handleAcceptAll}
          >
            <span
              className="CookieConsent"
              style={{ justifyContent: "center", fontSize: "15px" }}
            >
              We use cookies to personalise content and ads, to provide social
              media features. <Link to="/terms-and-condition">Learn More</Link>
            </span>
          </CookieConsent>
        </>
      )}

      {!login &&
        !firstTimeAvatarSlection &&
        !firstTimeFoundationSelection &&
        !selectBioEdit &&
        pathname !== "/Livebanner" &&
        pathname !== "/influencerUrl" &&
        pathname !== "/progress-bar" &&
        localStorage.getItem("mfa_passed") != "true" && (
          <div className="pwaPopup" style={{ display: pwaPopUp }}>
            <span>
              {isIPhone && (
                <>
                  Click on{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    id="upload"
                    fill="blue"
                  >
                    <path d="M9.71,6.71,11,5.41V17a1,1,0,0,0,2,0V5.41l1.29,1.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-3-3a1,1,0,0,0-1.42,0l-3,3A1,1,0,0,0,9.71,6.71ZM18,9H16a1,1,0,0,0,0,2h2a1,1,0,0,1,1,1v7a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H8A1,1,0,0,0,8,9H6a3,3,0,0,0-3,3v7a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V12A3,3,0,0,0,18,9Z"></path>
                  </svg>{" "}
                  Add to Home Screen
                </>
              )}
              {!isIPhone && texts.InstallCoinParliament}
            </span>

            {/* "Add to Home Screen" : texts.InstallCoinParliament}</span> */}
            {!isIPhone && (
              <button
                className="link-button"
                id="setup_button"
                aria-label="Install app"
                title="Install app"
                onClick={onClick}
                style={{ zIndex: 99991 }}
              >
                {texts.Install}
              </button>
            )}
            <span
              className="link-button"
              id="setup_button"
              aria-label="Install app"
              title="Install app"
              onClick={(e) => setPwaPopUp("none")}
              style={{
                zIndex: 99991,
                position: "absolute",
                top: "5px",
                right: "10px",
                fontSize: "18px",
                cursor: "pointer",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#7767f7"
                height="25"
                width="25"
              >
                <title>window-close</title>
                <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
              </svg>
            </span>
          </div>
        )}
      <div>
        {enabled && (
          <NotificationContext.Provider
            value={{
              showToast,
              showModal,
            }}
          >
            <Form.Control
              type="hidden"
              id="lang-detector"
              ref={langDetector}
              onChange={(e) => {
                // console.log(e.target.value)
              }}
            />
            <ManagersContext.Provider
              value={{
                CPMSettingsMng,
                VoteRulesMng,
                TimeframesMng,
                UserTypeMng,
              }}
            >
              <AppContext.Provider
                  value={{
                    boosterButtonBlink,
                    setBoosterButtonBlink,
                    headerCpmStart,
                    setHeaderCpmStart,
                    claimRewardStart,
                    setClaimRewardStart,
                    boosterVote,
                    setBoosterVote,
                    oneVoteOnly,
                    setOneVoteOnly,
                  getSpinData,
                  seletcardHolderType,
                  setSeletcardHolderType,
                  notificationCount,
                  setNotificationCount,
                  megaSpinData,
                  setMegaSpinData,
                  freeSpinData,
                  setFreeSpinData,
                  contDownValue,
                  setContDownValue,
                  prevCMP,
                  latestCMP,
                  extraVoteModule,
                  setExtraVoteModule,
                  userChildern,
                  setUserChildern,
                  userLeadersData,
                  setUserLeadersData,
                  userSubscribersData,
                  setUserSubscribersData,
                  loader,
                  setLoader,
                  addPaxWalletPop,
                  setAddPaxWalletPop,
                  walletTab,
                  setWalletTab,
                  historyTab,
                  setHistoryTab,
                  avatarImage,
                  setAvatarImage,
                  selectBioEdit,
                  setSelectBioEdit,
                  isVirtualCall,
                  setIsVirtualCall,
                  withLoginV2e,
                  setWithLoginV2e,
                  transactionId,
                  setTransactionId,
                  setBackgrounHide,
                  backgrounHide,
                  voteNumberEnd,
                  setvoteNumberEnd,
                  upgraeShowOpen,
                    setUpgraeShowOpen,
                  albumOpen,
                  setAlbumOpen,
                  afterVotePopup,
                  setAfterVotePopup,
                  rewardExtraVote,
                  setRewardExtraVote,
                  headerExtraVote,
                  setHeaderExtraVote,
                  inOutReward,
                  setInOutReward,
                  showReward,
                  setShowReward,
                  showBack,
                  setShowBack,
                  followerUserId,
                  setFollowerUserId,
                  singalCardData,
                  setSingalCardData,
                  remainingTimer,
                  setNftAlbumData,
                  nftAlbumData,
                  setAllPariButtonTime,
                  allPariButtonTime,
                  allButtonTime,
                  forRun,
                  setForRun,
                  setAllButtonTime,
                  chosenUserType,
                  setChosenUserType,
                  setLoginRedirectMessage,
                  loginRedirectMessage,
                  paxData,
                  setPaxData,
                  appStats,
                  setAppStats,
                  authStateChanged,
                  login,
                  setLogin,
                  profileTab,
                  setProfileTab,
                  signup,
                  setSignup,
                  firstTimeLogin,
                  setFirstTimeLogin,
                  TradeHistoryShow, 
                  setTradeHistoryShow,
                  tradeHistoryTab, 
                  setTradeHistoryTab,
                  openOfferTab, 
                  setOpenOfferTab,
                  showMenubar,
                  setShowMenuBar,
                  firstTimeAvatarSlection,
                  setFirstTimeAvatarSelection,
                  menuOpen,
                  setMenuOpen,
                  fcmToken,
                  setFcmToken,
                  lang,
                  animateBox,
                  setAnimateBox,
                  setLang,
                  rtl,
                  setRtl,
                  languages,
                  setLanguages,
                  translations,
                  isWLDPEventRegistered,
                  setIsWLDPEventRegistered,
                  setTranslations: (newTranslations) => {
                    newTranslations = new Map(newTranslations);
                    translations.clear();
                    newTranslations.forEach(async (value, key) => {
                      translations.set(key, value);
                      await setDoc(doc(db, "translations", key), value);
                    });
                  },
                  userTypes,
                  setUserTypes: async (u: UserTypeProps[]) => {
                    u = UserTypeMng.setter(u);
                    if (validateTotalShare(u)) {
                      await setDoc(
                        doc(db, "settings", "userTypes"),
                        {
                          userTypes: u,
                        },
                        { merge: true }
                      );
                    } else {
                      if (u.every((uu) => uu.share)) {
                        showToast(texts.Total100, ToastType.ERROR);
                      }
                    }
                  },
                  CPMSettings,
                  setCPMSettings: async (settings: CPMSettings) => {
                    settings = CPMSettingsMng.setter(settings);
                    await setDoc(
                      doc(db, "settings", "settings"),
                      {
                        voteRules: voteRules || {},
                        CPMSettings: settings,
                      },
                      { merge: true }
                    );
                  },
                  voteRules,
                  setVoteRules: async (rules: VoteRules) => {
                    rules = VoteRulesMng.setter(rules);
                    await setDoc(
                      doc(db, "settings", "settings"),
                      {
                        voteRules: rules,
                        CPMSettings: CPMSettings || {},
                      },
                      { merge: true }
                    );
                  },
                  timeframes,
                  setTimeframes: async (t: TimeFrame[]) => {
                    t = TimeframesMng.setter(t);
                    await setDoc(
                      doc(db, "settings", "timeframes"),
                      {
                        timeframes: t.filter(
                          (tt) => isNumber(tt.index) && tt.seconds && tt.name
                        ),
                      },
                      { merge: true }
                    );
                  },
                }}
              >
                <ContentContext.Provider
                  value={{
                    pages,
                    setPages,
                    quotes,
                  }}
                >
                  <CoinsContext.Provider
                    value={{
                      allCoinsSetting,
                      changePrice,
                      setChangePrice,
                      ws,
                      socket,
                      socketConnect,
                      rest,
                      coins,
                      setCoins,
                      updateCoinValue,
                      coinsValue,
                      myCoins,
                      setMyCoins,
                      leaders,
                      setLeaders,
                      totals,
                      setTotals,
                      allCoins,
                        allPairs,
                        allPairsSetting
                    }}
                  >
                    <UserContext.Provider
                      value={{
                        notifications,
                        setNotifications,
                        admin,
                        setAdmin,
                        user,
                        userInfo,
                        displayName,
                        setDisplayName,
                        setUser: updateUser,
                        setUserInfo,
                        votesLast24Hours,
                        setVotesLast24Hours,
                      }}
                    >
                      {/* <VoteProvider> */}
                      {getSubdomain() === "admin" && user && <Admin />}
                      {(getSubdomain() !== "admin" ||
                        (getSubdomain() === "admin" && !user)) && (
                        <>
                          <Background pathname={pathname} />
                          <AppContainer
                            fluid
                            pathname={pathname}
                            login={login || firstTimeLogin ? "true" : "false"}
                          >
                            {pathname !== "/Livebanner" &&
                              pathname !== "/influencerUrl" &&
                              pathname !== "/progress-bar" && (
                                <Header
                                  remainingTimer={remainingTimer}
                                  setMfaLogin={setMfaLogin}
                                  logo={
                                    (login && window.screen.width > 979) ||
                                    window.screen.width > 979
                                  }
                                  pathname={pathname}
                                  title={
                                    // pathname !== "/" && !login ? (
                                    //   <H1 style={{color: "var(--white)"}}>
                                    //     {pathname !== "/login" && pathname !== "/signup" && fixTitle(
                                    //       getTitle(
                                    //         pages?.find((p) => p.slug === pathname.slice(1))
                                    //           ?.title || pathname.slice(1),
                                    //         lang,
                                    //       ),
                                    //       pathname,
                                    //     )}
                                    //   </H1>
                                    // ) : (
                                    <HomeContainer
                                      className="d-flex flex-column justify-content-center align-items-center p-0"
                                      width={width}
                                    >
                                      <div
                                        className="mb-2 d-flex align-items-center"
                                        style={{
                                          flexFlow:
                                            width && width > 979
                                              ? "row"
                                              : "column",
                                          justifyContent:
                                            width && width > 979
                                              ? "center"
                                              : "center",
                                          width:
                                            width && width > 979 ? 233 : "auto",
                                        }}
                                      >
                                        <Link to={"/"} className="">
                                          {window.screen.width < 979 && (
                                            <Logo
                                              size={
                                                width && width > 979
                                                  ? Size.XSMALL
                                                  : Size.XSMALL
                                              }
                                            />
                                          )}
                                        </Link>
                                      </div>
                                    </HomeContainer>
                                  }
                                />
                              )}

                            {user && firstTimeLogin && (
                              <FirstTimeLogin
                                setFirstTimeAvatarSelection={
                                  setFirstTimeAvatarSelection
                                }
                                generate={generateUsername}
                                saveUsername={async (
                                  username: any,
                                  DisplayName: any,
                                  country: any
                                ) => {
                                  if (user?.uid) {
                                    await saveUsername(user?.uid, username, "");
                                    await saveDisplayName(
                                      user?.uid,
                                      DisplayName,
                                      country,
                                      ""
                                    );
                                    setFirstTimeAvatarSelection(true);
                                    // setFirstTimeFoundationSelection(true);
                                    setFirstTimeLogin(false);
                                  }
                                }}
                              />
                            )}

                            {!firstTimeLogin && firstTimeAvatarSlection && (
                              <FirstTimeAvatarSelection
                                user={user}
                                setFirstTimeAvatarSelection={
                                  setFirstTimeAvatarSelection
                                }
                                setSelectBioEdit={setSelectBioEdit}
                              />
                            )}

                            {!firstTimeLogin &&
                              !firstTimeAvatarSlection &&
                              selectBioEdit && (
                                <SelectBio
                                  userData={user}
                                  setSelectBioEdit={setSelectBioEdit}
                                  // setFirstTimeAvatarSelection={
                                  //   setFirstTimeAvatarSelection
                                  // }
                                />
                              )}
                            {/* {!firstTimeAvatarSlection &&
                            firstTimeFoundationSelection && (
                              <FirstTimeFoundationSelection
                                user={user}
                                setFirstTimeFoundationSelection={
                                  setFirstTimeFoundationSelection
                                }
                              />
                            )} */}
                            {!firstTimeLogin && (
                              <>
                                {!user && login && !mfaLogin && (
                                  <LoginAndSignup
                                    {...{
                                      authProvider: LoginAuthProvider,
                                      loginAction: LoginRegular,
                                      signupAction: SignupRegular,
                                    }}
                                  />
                                )}
                                {/* {(user || userInfo?.uid) && login && (
                                    <Login2fa
                                      setLogin={setLogin}
                                      setMfaLogin={setMfaLogin}
                                    />
                                  )} */}
                                {(user || userInfo?.uid) &&
                                  localStorage.getItem("mfa_passed") ===
                                    "true" && (
                                    <Login2fa
                                      setLogin={setLogin}
                                      setMfaLogin={setMfaLogin}
                                    />
                                  )}
                                {!login &&
                                  !firstTimeAvatarSlection &&
                                  !firstTimeFoundationSelection &&
                                  !selectBioEdit &&
                                  localStorage.getItem("mfa_passed") !=
                                    "true" && (
                                    <>
                                      <Container
                                        className={
                                          pathname == "/progress-bar" &&
                                          window.innerWidth <= 600
                                            ? "bg-theme-parent d-flex align-items-center"
                                            : "bg-theme-parent"
                                        }
                                        fluid
                                        style={{
                                          background:
                                            pathname == "/" ? "#160133" : "",
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                          minHeight:
                                            window.screen.width < 979
                                              ? "89vh"
                                              : "92vh",
                                          // padding: `${pathname === "/" ? 120 : 84}px 0 109px`,
                                          padding: `${paddingTop}px 0 0`,
                                          // transformStyle: "flat",
                                          // transform: `${backgrounHide ? "scale(1.5)" : "scale(1)"}`,
                                          transform: `${
                                            backgrounHide
                                              ? `${
                                                  window.screen.width > 767
                                                    ? "scale(3)"
                                                    : "scale(1.5)"
                                                }`
                                              : "scale(1)"
                                          }`,
                                          transformOrigin: `${
                                            backgrounHide
                                              ? `${
                                                  window.screen.width > 767
                                                    ? "35% 50%"
                                                    : "50% 90%"
                                                }`
                                              : ""
                                          }`,
                                          transition: `${
                                            backgrounHide ? "all 3s" : ""
                                          }`,
                                        }}
                                      >
                                        <Routes>
                                          <Route path="/" element={<Home />} />
                                          <Route
                                            path="/firebase-messaging-sw.js"
                                            element={<Home />}
                                          />
                                          <Route
                                            path="coins"
                                            element={<CoinMain />}
                                          />
                                          {/* <Route
                                              path='nftAlbum'
                                              element={<NFTGallery />}
                                            /> */}
                                          <Route
                                            path="WallOfFame"
                                            element={<NFTGallery />}
                                          />
                                          <Route
                                            path="iframeUrl"
                                            element={<NewUrlFram />}
                                          />
                                          <Route
                                            path="WallOfFame/:type"
                                            element={<NFTGalleryType />}
                                          />
                                          <Route
                                            path="singalCard/:type/:id"
                                            element={<SingalCard />}
                                          />
                                          <Route
                                            path="singlecarddetails/:type/:id/:userType"
                                            element={<SingleCardDetails />}
                                          />
                                          <Route
                                            path="coins/:id"
                                            element={<SingleCoin />}
                                          />
                                          <Route
                                            path="pairs"
                                            element={<PairsMain />}
                                          />
                                          <Route
                                            path="pairs/:id"
                                            element={<SinglePair />}
                                          />
                                          <Route
                                            path={ProfileTabs.profile}
                                            element={<Profile />}
                                          >
                                            <Route
                                              path={ProfileTabs.edit}
                                              element={<PersonalInfo />}
                                            />
                                            <Route
                                              path={ProfileTabs.history}
                                              element={<PaymentHistory />}
                                            />
                                            {/* <Route
                                              path={ProfileTabs.foundationshow}

                                                element={<FoundationData />}
                                              /> */}
                                            <Route
                                              path={ProfileTabs.password}
                                              element={<Security />}
                                            />
                                            <Route
                                              path={ProfileTabs.wallet}
                                              element={<Wallet />}
                                            />

                                            {!isV1() && (
                                              <Route
                                                path={ProfileTabs.mine}
                                                element={<Mine />}
                                              />
                                            )}
                                            <Route
                                              path={ProfileTabs.followers}
                                              element={<Follow />}
                                            />
                                            <Route
                                              path={ProfileTabs.votes}
                                              element={<Votes />}
                                            />
                                            <Route
                                              path={ProfileTabs.share}
                                              element={<Pool />}
                                            />
                                            <Route
                                              path={ProfileTabs.notifications}
                                              element={<Notifications />}
                                            />
                                            <Route
                                              path={ProfileTabs.trade}
                                              element={<Trade />}
                                            />
                                            <Route
                                              path={
                                                ProfileTabs.ProfileNftGallery
                                              }
                                              element={<ProfileNftGallery />}
                                            />
                                            <Route
                                              path={
                                                ProfileTabs.ProfileNftGalleryType
                                              }
                                              element={
                                                <ProfileNftGalleryType />
                                              }
                                            />
                                          </Route>
                                          {/* Fowller component  start*/}
                                          <Route
                                            path={
                                              FollowerProfileTabs.FollowerProfile
                                            }
                                            element={<FollowerProfile />}
                                          >
                                            {!isV1() && (
                                              <Route
                                                path={FollowerProfileTabs.mine}
                                                element={<FwMine />}
                                              />
                                            )}

                                            <Route
                                              path={
                                                FollowerProfileTabs.followers
                                              }
                                              element={<FwFollow />}
                                            />
                                            <Route
                                              path={FollowerProfileTabs.votes}
                                              element={<FwVotes />}
                                            />
                                            <Route
                                              path={FollowerProfileTabs.share}
                                              element={<FwPool />}
                                            />
                                            <Route
                                              path={
                                                FollowerProfileTabs.ProfileNftGallery
                                              }
                                              element={<FwProfileNftGallery />}
                                            />
                                            <Route
                                              path={
                                                FollowerProfileTabs.ProfileNftGalleryType
                                              }
                                              element={
                                                <FwProfileNftGalleryType />
                                              }
                                            />
                                          </Route>

                                          {/* Fowller component  end*/}
                                          <Route
                                            path="/upgrade"
                                            element={<UpgradePage />}
                                          />
                                          {/* <Route
                                              path='/paymentList'
                                              element={<CoinsList />}
                                          /> */}

                                          {/* <Route path='/paymentList'
                                              // element={user && userInfo?.uid ? <CoinsList /> : <Navigate to="/" />}
                                              element={

                                                <PaymentFun
                                                  isVotingPayment={false}
                                                />
                                              }
                                            /> */}
                                          <Route
                                            path="/VotePayment"
                                            // element={user && userInfo?.uid ? <CoinsList /> : <Navigate to="/" />}
                                            element={
                                              <PaymentFun
                                                isVotingPayment={false}
                                              />
                                            }
                                          />
                                          <Route
                                            path="/votingbooster"
                                            element={<VotingBooster />}
                                          />
                                          <Route
                                            path="/Livebanner"
                                            element={<LiveBanner />}
                                          />
                                          <Route
                                            path="/progress-bar"
                                            element={<Progress />}
                                          />
                                          <Route
                                            path="/influencerUrl"
                                            element={<InfluencerUrl />}
                                          />
                                          <Route
                                            path="influencers"
                                            element={<Influencers />}
                                          />
                                          <Route
                                            path="influencers/:type"
                                            element={<InfluencersList />}
                                          />

                                          {/* <Route path="signup" element={<LoginAndSignup/>}/> */}
                                          <Route
                                            path="knowledgehub"
                                            element={<FAQ />}
                                          />
                                          <Route
                                            path="about"
                                            element={<About />}
                                          />
                                          <Route
                                            path="gamerule"
                                            element={<GameRule />}
                                          />
                                          <Route
                                            path="Ambassador"
                                            element={<Ambassador />}
                                          />
                                          <Route
                                            path="foundations"
                                            element={<Foundations />}
                                          />
                                          <Route
                                            path="contact"
                                            element={<Contact />}
                                          />
                                          <Route
                                            path="privacy"
                                            element={<PrivacyPolicy />}
                                          />
                                          <Route
                                            path="/terms-and-condition"
                                            element={<TermsAndConditions />}
                                          />
                                          <Route
                                            path="wheel"
                                            element={<SpinWheel />}
                                          />
                                          {/* <Route
                                            path="streak"
                                            element={<Streak />}
                                          /> */}
                                          {localhost && user && (
                                            <Route
                                              path="admin"
                                              element={<Admin />}
                                            />
                                          )}
                                          {pages
                                            ?.filter((p) => p.title !== "x")
                                            .map((page, u) => (
                                              <Route
                                                key={u}
                                                path={page.slug}
                                                element={<Content />}
                                              />
                                            ))}

                                          <Route
                                            path="*"
                                            element={<Content />}
                                          />

                                          {
                                            <Route
                                              path="/generic-signup"
                                              element={
                                                !user && !localID ? (
                                                  <GenericLoginSignup />
                                                ) : (
                                                  <Navigate to="/" />
                                                )
                                              }
                                            />
                                          }
                                        </Routes>
                                      </Container>
                                      {pathname !== "/Livebanner" &&
                                        pathname !== "/influencerUrl" &&
                                        pathname !== "/progress-bar" && (
                                          <Footer />
                                        )}
                                    </>
                                  )}
                              </>
                            )}
                          </AppContainer>
                        </>
                      )}
                      <ToastContainer
                        enableMultiContainer
                        containerId="toast"
                        limit={1}
                      />
                      <ToastContainer
                        enableMultiContainer
                        containerId="modal"
                      />
                      {modalOpen && (
                        <div className="fade modal-backdrop show" />
                      )}
                      {/* //vote result modal */}
                      {/* @ts-ignore */}
                      {voteDetails?.lessTimeVote &&
                        !pathname.toLowerCase().includes(`profile/mine`) &&
                          !pathname.toLowerCase().includes(`/wheel`) &&
                        !showComplete100CMP &&
                        user && (
                          <ModalForResult
                            popUpOpen={voteDetails.openResultModal}
                            vote={voteDetails?.lessTimeVote}
                            type={voteDetails?.lessTimeVote?.voteType || "coin"}
                            setLessTimeVoteDetails={setLessTimeVoteDetails}
                            setShowComplete100CMP={setShowComplete100CMP}
                            currentCMP={completedVoteCMP}
                          />
                        )}
                      {/* complete 100 cmp notify  */}
                      <Complete100CMPModal
                        setCurrentCMP={setCompletedVoteCMP}
                        showComplete100CMP={showComplete100CMP}
                        setShowComplete100CMP={setShowComplete100CMP}
                      />
                    </UserContext.Provider>
                  </CoinsContext.Provider>
                </ContentContext.Provider>
              </AppContext.Provider>
            </ManagersContext.Provider>
          </NotificationContext.Provider>
        )}
        {!enabled && (
          <Container>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                const resp = await sendPassword({ password });
                // console.log(resp.data);
                if (resp.data === true) {
                  enable(true);
                }
              }}
            >
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button type="submit">Submit</Button>
            </Form>
          </Container>
        )}
      </div>
    </BackDiv>
  );
}

export default App;
export const showToast = (
  content: ToastContent,
  type?: ToastType,
  options: ToastOptions | undefined = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    containerId: "toast",
  }
) => {
  toast.dismiss();
  toast.clearWaitingQueue();
  switch (type) {
    case ToastType.ERROR:
      toast.error(content, options);
      break;
    case ToastType.INFO:
      toast.info(content, options);
      break;
    default:
      toast.success(content, options);
  }
};
