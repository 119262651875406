import React from "react";

const PaymentHitory = () => (  
  <svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_44_2)">
      <path d="M0 15.0105C0 10.9617 0 6.91427 0.00268398 2.86683C0.00402597 0.988041 0.978312 0.00436147 2.84771 0.00301948C9.28792 -0.00100649 15.7295 -0.00100649 22.1697 0.00301948C24.0512 0.00301948 25.0348 0.977305 25.0348 2.84536C25.0375 10.9631 25.0308 19.0808 25.0442 27.1998C25.0456 28.2721 24.7248 29.143 23.76 29.6731C22.7776 30.2126 21.8342 30.0717 20.9297 29.4275C20.3594 29.0222 19.8024 29.0732 19.275 29.5215C19.0885 29.6798 18.9234 29.865 18.7423 30.0314C17.3573 31.305 15.3202 31.2996 13.9352 30.022C13.8534 29.9469 13.7782 29.8637 13.6977 29.7858C12.82 28.9337 12.2309 28.9364 11.3492 29.7966C10.1307 30.9842 8.6961 31.3264 7.31385 30.6756C6.84147 30.4528 6.42009 30.0918 6.02688 29.7362C5.24853 29.0316 4.8258 28.9699 3.95351 29.5443C3.1161 30.0945 2.23576 30.1831 1.34736 29.7214C0.432121 29.245 0 28.4586 0 27.4226C0.00402597 23.2852 0.00134199 19.1479 0 15.0105ZM2.14987 14.9662C2.14987 18.2085 2.14987 21.4507 2.14987 24.693C2.14987 25.5653 2.13779 26.4376 2.15524 27.3085C2.16597 27.8467 2.3713 27.958 2.81281 27.7084C4.71442 26.6348 6.06043 26.7892 7.59164 28.2586C8.42502 29.0585 8.99537 29.0451 9.84887 28.2586C10.2072 27.9285 10.587 27.593 11.0164 27.3743C12.3906 26.6738 13.9151 26.9891 15.1269 28.1634C16.0905 29.0974 16.6031 29.096 17.568 28.1553C17.6794 28.0466 17.7894 27.9339 17.9102 27.8346C19.1019 26.8455 20.7297 26.7462 22.0355 27.5608C22.2274 27.6802 22.4703 27.7192 22.689 27.7957C22.7548 27.5528 22.8796 27.3099 22.8796 27.0656C22.8903 19.0378 22.881 11.01 22.8997 2.98224C22.9011 2.33272 22.6984 2.13679 22.057 2.13947C15.7067 2.15826 9.35636 2.15021 3.00606 2.15155C2.16866 2.15155 2.15255 2.16899 2.15121 3.02384C2.15121 7.00419 2.15121 10.9845 2.15121 14.9649L2.14987 14.9662Z" fill="url(#paint0_linear_44_2)" />
      <path d="M6.73544 6.88828C7.48964 6.09248 8.09085 5.43893 8.71488 4.80685C9.26778 4.24724 9.90388 4.19491 10.3669 4.63776C10.8446 5.09404 10.7896 5.7543 10.2112 6.33941C9.39527 7.16473 8.57531 7.98603 7.74999 8.80196C7.05752 9.48638 6.53951 9.48906 5.85912 8.82344C5.46055 8.43292 5.05795 8.04508 4.67817 7.63711C4.21384 7.13924 4.20713 6.50984 4.63522 6.08309C5.0754 5.64426 5.67929 5.64694 6.18522 6.12737C6.37713 6.30854 6.50193 6.5595 6.7341 6.88694L6.73544 6.88828Z" fill="url(#paint1_linear_44_2)" />
      <path d="M6.75979 21.1246C7.50057 20.3637 8.1152 19.7155 8.74996 19.0875C9.31225 18.5305 9.93762 18.5024 10.3993 18.9828C10.826 19.427 10.7898 20.0631 10.2731 20.5905C9.4129 21.4682 8.54464 22.3391 7.66563 23.198C7.06576 23.7844 6.52091 23.7764 5.91433 23.1926C5.47953 22.7739 5.04875 22.3512 4.63542 21.911C4.16035 21.4064 4.14425 20.7891 4.56966 20.3543C4.99507 19.9195 5.62446 19.9195 6.12637 20.3825C6.33706 20.5771 6.50347 20.8213 6.75845 21.1246H6.75979Z" fill="url(#paint2_linear_44_2)" />
      <path d="M16.7964 7.88384C15.9026 7.88384 15.0102 7.89189 14.1164 7.88116C13.3407 7.87176 12.8724 7.4383 12.8912 6.77536C12.91 6.1406 13.3636 5.74202 14.1164 5.738C15.9254 5.72995 17.7344 5.7286 19.5434 5.738C20.2815 5.74202 20.7351 6.16878 20.7351 6.80891C20.7351 7.44904 20.2829 7.86908 19.5421 7.87982C18.6268 7.89324 17.7103 7.8825 16.795 7.8825L16.7964 7.88384Z" fill="url(#paint3_linear_44_2)" />
      <path d="M6.74098 14.0074C7.50055 13.2183 8.10981 12.5674 8.74055 11.9394C9.29211 11.3905 9.93224 11.3556 10.3899 11.8172C10.8381 12.2695 10.7871 12.9123 10.2382 13.4692C9.39276 14.3268 8.54328 15.1816 7.6844 16.0244C7.05233 16.6444 6.53432 16.635 5.89016 16.011C5.48891 15.6231 5.09168 15.2313 4.70921 14.826C4.21133 14.2986 4.19791 13.6611 4.65284 13.2236C5.08094 12.8117 5.7157 12.8331 6.20821 13.3015C6.38401 13.4692 6.50748 13.6907 6.74233 14.006L6.74098 14.0074Z" fill="url(#paint4_linear_44_2)" />
      <path d="M16.7748 15.0328C15.881 15.0328 14.9886 15.0422 14.0948 15.0301C13.346 15.0194 12.8951 14.6141 12.8897 13.978C12.8843 13.3406 13.3326 12.8964 14.0706 12.891C15.9011 12.8749 17.7329 12.8749 19.5634 12.891C20.2948 12.8977 20.7471 13.3513 20.7363 13.9874C20.7256 14.6222 20.276 15.0207 19.5205 15.0301C18.6052 15.0422 17.69 15.0328 16.7748 15.0328Z" fill="url(#paint5_linear_44_2)" />
      <path d="M16.7789 22.2069C15.9307 22.2069 15.0813 22.2122 14.2331 22.2055C13.3716 22.1975 12.8992 21.8164 12.8898 21.14C12.8804 20.4583 13.3501 20.0624 14.1996 20.0597C15.9415 20.0543 17.6834 20.0543 19.4239 20.0597C20.2707 20.0624 20.7445 20.4609 20.7337 21.1413C20.7243 21.8164 20.2506 22.1988 19.3904 22.2055C18.5194 22.2122 17.6485 22.2069 16.7775 22.2069H16.7789Z" fill="url(#paint6_linear_44_2)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_44_2" x1="95.9985" y1="145.471" x2="-40.1322" y2="99.3348" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6318FF" />
        <stop offset="1" stop-color="#FFF6FF" />
      </linearGradient>
      <linearGradient id="paint1_linear_44_2" x1="28.7284" y1="27.6973" x2="-1.22828" y2="11.6199" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6318FF" />
        <stop offset="1" stop-color="#FFF6FF" />
      </linearGradient>
      <linearGradient id="paint2_linear_44_2" x1="28.9054" y1="42.0583" x2="-1.24319" y2="25.7631" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6318FF" />
        <stop offset="1" stop-color="#FFF6FF" />
      </linearGradient>
      <linearGradient id="paint3_linear_44_2" x1="42.9598" y1="15.8451" x2="28.6905" y2="-5.94267" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6318FF" />
        <stop offset="1" stop-color="#FFF6FF" />
      </linearGradient>
      <linearGradient id="paint4_linear_44_2" x1="28.735" y1="34.8992" x2="-1.25192" y2="18.8344" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6318FF" />
        <stop offset="1" stop-color="#FFF6FF" />
      </linearGradient>
      <linearGradient id="paint5_linear_44_2" x1="42.9679" y1="23.0024" x2="28.6811" y2="1.2022" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6318FF" />
        <stop offset="1" stop-color="#FFF6FF" />
      </linearGradient>
      <linearGradient id="paint6_linear_44_2" x1="42.9579" y1="30.1597" x2="28.7075" y2="8.3806" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6318FF" />
        <stop offset="1" stop-color="#FFF6FF" />
      </linearGradient>
      <clipPath id="clip0_44_2">
        <rect width="25.0442" height="31" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default PaymentHitory;
