import React from 'react'

function NewUrlFram() {
  return (
      <div className='border'
          style={{
              width: "100%",
              height:"100vh"
      }}
      >
          <iframe src="https://staging.app.acme.am/claim/238f4a1d-4be0-4920-a403-ec0d3ef9629a" width={"100%"} height={"100%"}></iframe>
    </div>
  )
}

export default NewUrlFram