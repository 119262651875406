/** @format */

import React, { useContext, useEffect, useRef } from "react";
import { Carousel, Image } from "react-bootstrap";
import { useTranslation } from "../common/models/Dictionary";
import Pairs from "../Components/Pairs/Pairs";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import UserContext from "../Contexts/User";
import Coins from "../Components/Coins/Coins";
import { calcFavorites } from "../common/utils/coins";
import AppContext from "../Contexts/AppContext";
import { HomeContainer } from "../Components/App/App";
import NotificationContext from "../Contexts/Notification";
import NotLoggedInPopup from "../Components/App/NotLoggedInPopup";
import Quotes from "../Components/Quotes";
import ContentContext from "../Contexts/ContentContext";
import { useWindowSize } from "../hooks/useWindowSize";
import InfluencersCarousel from "../Components/Users/InfluencersCarousel";
import { texts } from "../Components/LoginComponent/texts";
import { Buttons } from "../Components/Atoms/Button/Button";
import { firestore } from "../firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
  limit,
} from "firebase/firestore";
import LatestVoteBanner from "../Components/LatestVoteBanner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GreenAnimation from "../assets/animation/Green-animation.json";
import lottie from "lottie-web";
import ProgressBar from "Components/ProgressBar/progress-bar";

const H2 = styled.h2`
  font-size: var(--font-size-xxl);
  text-align: center;
`;

const TextContainer = styled.div`
  max-width: 284px;
  margin: 0 auto;

  & p {
    font-size: 17px;
  }
`;
const Home = () => {
  const translate = useTranslation();
  const { user, userInfo } = useContext(UserContext);
  const {
    login,
    firstTimeLogin,
    setLogin,
    setLoginRedirectMessage,
    setSignup,
    userChildern,
    setUserChildern,
  } = useContext(AppContext);
  const { showModal } = useContext(NotificationContext);
  const { quotes } = useContext(ContentContext);
  const { width } = useWindowSize();
  // const src = `/hpbanner${width && width > 979 ? "" : ""}.png`;
  const src1 = `/slider1.gif`;
  // const src1 = `/linearH-banner-bg.jpg`;
  const src2 = `/linearH-banner-content.png`;
  const src2a = `/linearH-banner-main.jpg`;

  // const updateAllUsers = async () => {
  //   try {
  //     const usersCollectionRef = collection(firestore, 'users');
  //     const userQuerySnapshot = await getDocs(query(usersCollectionRef, where('referalReceiveType.name', '!=', 'ONDEMAND')));

  //     const getAllUsers = userQuerySnapshot.docs.map(user => {
  //       const userData = user.data();
  //       return { userId: user.id};
  //     });

  //     console.log("getAllUsers length: " + getAllUsers);

  //     const updateUserList = getAllUsers.map(async (user) => {
  //       // const getRefrealType = user.referalType;
  //       console.log(user.userId,"user.userId")
  //       await setDoc(doc(usersCollectionRef, user.userId), { referalReceiveType: { amount: "", days: "", limitType:"", name: "ONDEMAND" }},{ merge: true });
  //     });

  //     await Promise.all(updateUserList);
  //     console.log('All updates completed successfully');
  //   } catch (error) {
  //     console.error("scriptToUpdateAllUsers ERROR: " + error);
  //   }
  // };

  function SampleNextArrow() {
    return <div style={{ display: "none" }} />;
  }

  function SamplePrevArrow() {
    return <div style={{ display: "none" }} />;
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // waitForAnimate: false,
    // fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const animationContainer = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
      var anim = lottie.loadAnimation({
        container: animationContainer.current,
        animationData: GreenAnimation,
        loop: true, // Whether animation should loop
        autoplay: true, // Whether animation should start automatically
      });
    }

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <>
      <div className="p-0 w-100" style={{ background: "#160133" }}>
        {/* <button type="button"
          onClick={() => {
            updateAllUsers()
        }}
        >Run Croin</button> */}
        <div className="mt-0" style={{ background: "#160133" }}>
          <div className="p-0  container-slider">
            {!(login || firstTimeLogin) && (
              <>
                <Slider {...settings} className="w-100">
                  <div>
                    <img src={src1} width="100%" />
                  </div>
                  <div>
                    <div className="bannerTwo bannerMainDiv">
                      <img src={src2} className="desktopImage" width="100%" />
                      <img src={src2a} className="mobileImage" width="100%" />
                    </div>
                  </div>
                </Slider>
              </>
            )}
          </div>
        </div>
        <div className="mb-4 mx-0 HomeText pt-6">
          <Pairs
            onFavClick={async (...args) => {
              if (user) {
                await calcFavorites(...args);
              } else {
                showModal(<NotLoggedInPopup />);
              }
            }}
          />
        </div>
        <div className="mb-4 mx-0">
          <H2
            style={{
              zIndex: 1,
              fontWeight: "400",
              position: "relative",
              marginBottom: "20px",
            }}
          >
            {translate("")}
          </H2>
          {/* <Pairs
            onFavClick={async (...args) => {
              if (user) {
                await calcFavorites(...args);
              } else {
                showModal(<NotLoggedInPopup />);
              }
            }}
          /> */}
          <Coins
            onFavClick={async (...args) => {
              if (user) {
                await calcFavorites(...args);
              } else {
                setLoginRedirectMessage("add coin to favorites");
                setLogin(true);
                // showModal(<NotLoggedInPopup/>);
              }
            }}
          />
        </div>
        <div>
          <div className="w-100 text-center mb-2 d-flex justify-content-center">
            <div
              ref={animationContainer}
              style={{
                marginRight: "4px",
                width: "20px",
                height: "20px",
              }}
            />
            <p className="mr-2">Live Votes </p>
          </div>
          <LatestVoteBanner />
          {/* <div> */}
          {/* <ProgressBar /> */}
          {/* </div> */}
        </div>
        <div className="mb-5 mx-0">
          <div className="mb-4">
            {/* <H2 style={{ zIndex: 0, fontWeight: "400", position: "relative" }}>
              {texts.Influencers}
            </H2> */}
            {!user?.uid ? (
              <div className="d-sx-none">
                {" "}
                {window.screen.width > 979 && (
                  <>
                    <Buttons.Primary
                      style={{
                        margin: "auto",
                        marginTop: "4rem",
                        fontSize: "2rem",
                        padding: "2rem",
                      }}
                      onClick={(e) => {
                        setLogin(true);
                        setSignup(true);
                      }}
                    >
                      {texts.signUp}
                    </Buttons.Primary>
                    <H2
                      className="mt-3"
                      style={{
                        margin: "auto",
                        textAlign: "center",
                        fontSize: "1.5rem",
                      }}
                    >
                      {"Join now and start earning rewards before anyone else".toUpperCase()}
                    </H2>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}

            {!user?.uid ? (
              <div className="d-xl-none">
                {" "}
                {window.screen.width < 979 && (
                  <>
                    <Buttons.Primary
                      style={{
                        margin: "auto",
                        marginTop: "2rem",
                        fontSize: "1rem",
                        padding: "1rem",
                      }}
                      onClick={(e) => {
                        setLogin(true);
                        setSignup(true);
                      }}
                    >
                      {texts.signUp}
                    </Buttons.Primary>
                    <H2
                      className="mt-3"
                      style={{
                        margin: "auto",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      {"Join now and start earning rewards before anyone else".toUpperCase()}
                    </H2>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          <InfluencersCarousel />
        </div>
        <div className='mx-0'>
          <div className='mb-4'>
            <H2 style={{ zIndex: 1, fontWeight: "400", position: "relative" }}>
              {translate("")}
            </H2>
          </div>
          <div className="pb-3">
            <Quotes quotes={quotes} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
