import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ProgressBar.css";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import BigLogo from "../../assets/svg/logoiconx2.png";
import cplogo from "../../assets/images/logosAllParliament/CPLogo.png";
import sportlogo from "../../assets/images/logosAllParliament/sportLogo.png";
import stocklogo from "../../assets/images/logosAllParliament/stockLogo.png";
import votingLogo from "../../assets/images/logosAllParliament/votingLogo.png";
import v2eLogo from "../../assets/images/logosAllParliament/VTElogo.png";
import { useLocation } from "react-router-dom";
// logoiconx2

interface ProgressBarProps {
  totalVotes?: number;
  targetVotes?: number;
}

const Progress = ({ done }: any) => {
  const [style, setStyle] = React.useState({});  
  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${done >= 6.5 ? done : 6.5}%`,
    };

    if (window.innerWidth <= 360) {
      newStyle.width = "16%";
    }

    if (window.innerWidth <= 600 && window.innerWidth > 360) {
      newStyle.width = "13%";
    }

    setStyle(newStyle);
  }, 200);

  return (
    <div className="progress">
      <div className="progress-done" style={style}>
        {done}%
      </div>
    </div>
  );
};

const ProgressBar: React.FC<ProgressBarProps> = () => {
  const [percentage, setPercentage] = useState(0);
  const [liveTotalVote, setLiveTotalVote] = useState<any>({});
  const location = useLocation();
  const pathname = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  // const [textColor, setTextColor] = useState('white');
  const title = searchParams.get("title");
  const logo = searchParams.get("logo");  
  const textColor = searchParams.get('textColor');

  const getLogo = () => {
    const logo = searchParams.get("logo");
    if (!logo) return v2eLogo;
    if (logo == "coinparliament") {
      return cplogo;
    } else if (logo == "votetoearn") {
      return v2eLogo;
    } else if (logo == "sportparliament") {
      return sportlogo;
    } else if (logo == "votingparliament") {
      return votingLogo;
    } else if (logo == "stockparliament") {
      return stocklogo;
    } else if (logo) {
      return v2eLogo;
    }
  };

  const handleClick = () => {
    if (pathname == "/progress-bar") {
      const logo = searchParams.get("logo");
      if (!logo) return window.open(`https://app.votetoearn.net/`);
      if (logo == "coinparliament") {
        return window.open(`https://coinparliament.com/`);
      } else if (logo == "votetoearn") {
        return window.open(`https://app.votetoearn.net/`);
      } else if (logo == "sportparliament") {
        return window.open(`https://sportparliament.com/`);
      } else if (logo == "votingparliament") {
        return window.open(`https://votingparliament.com/`);
      } else if (logo == "stockparliament") {
        return window.open(`https://stockparliament.com/`);
      } else {
        return window.open(`https://app.votetoearn.net/`);
      }
    }
  };

  useEffect(() => {
    onSnapshot(doc(db, "stats", "voteStatistics"), (doc) => {
      let getVoteObj: any = (doc.data() || {}) as { voteStatistics: any };
      setLiveTotalVote(getVoteObj);
      setPercentage(getVoteObj.totalVotePercentage);
    });
    // const color = getTextColorBasedOnBackground();
    // setTextColor(color);
  }, []);
  const urlPath = window.location.pathname;

  // const getTextColorBasedOnBackground = () => {
  //   const bodyStyle = window.getComputedStyle(document.body);
  //   const bgColor = bodyStyle.backgroundColor;

  //   if (bgColor === 'transparent' || bgColor === 'rgba(0, 0, 0, 0)') {
  //     return 'black';
  //   }

  //   const rgb = bgColor.match(/\d+/g);
  //   if (!rgb) return 'black';

  //   const [r, g, b] = rgb.map(Number);

  //   // Calculate luminance
  //   const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  //   // Return white for dark backgrounds and black for light backgrounds
  //   return luminance > 0.5 ? 'black' : 'white';
  // };


  return (
    <div
      onClick={handleClick}
      className="container"
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        cursor: pathname == "/progress-bar" ? "pointer" : "",
      }}
    >
      <div className="progress-bar-container" style={{ width: "100%" }}>
        {pathname == "/progress-bar" && (
          <div style={{ cursor: "pointer" }}>
            {logo && (
              <img
                src={getLogo()}
                alt="No Image Found"
                width={
                  pathname === "/progress-bar" && window.innerWidth <= 600
                    ? "60px"
                    : "80px"
                }
                style={{ cursor: "pointer" }}
              />
            )}
            {title && (
              <p
                style={{
                  marginBottom: "0rem",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  paddingTop: !logo ? "40px" : "0",
                  cursor: "pointer",
                  fontSize:
                    pathname === "/progress-bar" && window.innerWidth <= 600
                      ? "12px"
                      : "16px",
                }}
              >
                {title}
              </p>
            )}
          </div>
        )}
        <div
          style={{
            width: "100% !important",
            padding: "0px",
            maxWidth: "600px",
            margin: "0px auto",
          }}
        >
          <Progress done={percentage} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between", // Adjusted to space between columns
            alignItems: "flex-start",
            width: "100%",
            maxWidth: "600px",
            margin: "0px auto",
          }}
        >
          <div
            className="total-votes"
            style={{
              display: "inline-block",
              width: "49%",
              padding: "0 10px",
              fontSize:
                window.innerWidth <= 600
                  ? "12px"
                  : "16px",
              textAlign: "center",
              boxSizing: "border-box",
              verticalAlign: "top",
              color: `${pathname == "/progress-bar" ? textColor ? textColor : "#fffff" : "#fffff"}`,              
            }}
          >
            Total votes:{" "}
            {liveTotalVote?.totalVoteCount
              ? liveTotalVote?.totalVoteCount.toLocaleString()
              : 0}
          </div>
          <div
            className="target-votes"
            style={{
              display: "inline-block",
              width: "49%",
              padding: "0 10px",
              fontSize:
                window.innerWidth <= 600
                  ? "12px"
                  : "16px",
              textAlign: "center",
              boxSizing: "border-box",
              verticalAlign: "top",
              color: `${pathname == "/progress-bar" ? textColor ? textColor : "#fffff" : "#fffff"}`,
            }}
          >
            Target:{" "}
            {liveTotalVote?.totalVoteLimit
              ? liveTotalVote?.totalVoteLimit.toLocaleString()
              : 0}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
