import { showToast } from 'App';
import { Buttons } from 'Components/Atoms/Button/Button';
import { Label } from 'Components/Forms/Textfield';
import { ToastType } from 'Contexts/Notification';
import UserContext from 'Contexts/User';
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { Col, Container, Form, FormControl, Row } from 'react-bootstrap'


const WalletValidation: React.FC<{ setMfaLogin: React.Dispatch<React.SetStateAction<boolean>>, UpdateFunction?:any, modalOpen?: boolean }> = ({ setMfaLogin, UpdateFunction, modalOpen }) => {
    const [textData, setTextData] = useState<string>('');
    const [buttonShow, setButtonShow] = useState<boolean>(false);
    const { userInfo } = useContext(UserContext);
    const url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/v1/admin/auth/generateGoogleAuthOTP`
    const otpurl = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/v1/admin/auth/verifyGoogleAuthOTP`



    const verifyOtp = async (token: string) => {
        try {            
            const response = await axios.post(otpurl, {
                "userId": userInfo?.uid,
                "token": token,
                "userType": "USER"
            });
            window.localStorage.setItem('mfa_passed', 'false')
            setMfaLogin(true)
            setButtonShow(false)
            UpdateFunction()
            console.log("i am working verify",response)
        } catch (error: any) {
            showToast(
                error.response.data.message, ToastType.ERROR
            );
            setButtonShow(false)
            console.error(error.response, "checkresponse");
        }
    };
    return (
        <>
            <div
            // gap={2}
            // className=' justify-content-center'
            // style={{ height: "100vh", background: "var(--light-purple)" }}
            >
                <div className=''>
                    <div className=''>
                        <Container
                            style={{
                                minHeight: modalOpen ? `${window.screen.width < 979 ? "" : ""}` : `${window.screen.width < 979 ? "59vh" : "67vh"}`
                            }}
                        >
                            <Row style={{ justifyContent: "center" }}>
                                <Col sm={modalOpen ?12:6}>
                                    <Form.Group controlId="MFA">
                                        <Form.Check>
                                            <> <Row style={{ marginTop: '150px' }}>
                                                <Col className="mt-2">
                                                    <Label>Please enter the 6-digit code generated by your authenticator app below to verify your identity and complete the 2FA process.
                                                    </Label>

                                                </Col>
                                            </Row>
                                                <Row>
                                                    <Col>
                                                        <Container className="p-0">

                                                            <div className="mb-5"
                                                                style={{
                                                                    width: modalOpen ? `${window.screen.width > 767 ? "300px" : "100%"}` : `${window.screen.width > 767 ? "620px" : "100%"}`
                                                                    
                                                                }}
                                                            >
                                                                <div className='d-flex justify-content-between'
                                                                    style={{ marginTop: '30px' }}
                                                                >
                                                                    <FormControl
                                                                        className=''
                                                                        style={{
                                                                            width:"80%"
                                                                        }}
                                                                        value={textData}
                                                                        onChange={(e) => setTextData(e.target.value)}
                                                                    />
                                                                    <Buttons.Primary className='border'
                                                                        disabled={textData=="" || buttonShow}
                                                                        onClick={(e) => {
                                                                        verifyOtp(textData)
                                                                        setButtonShow(true)
                                                                    }}>{!buttonShow ?
                                                                            "Verify" :"Verify..."
                                                                        }</Buttons.Primary>
                                                                </div>
                                                            </div>
                                                        </Container>
                                                    </Col>
                                                </Row>
                                            </>
                                        </Form.Check>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div >
        </>

    )
}

export default WalletValidation