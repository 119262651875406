import React from "react";

const BackArrow = () => {
  return (
   <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" fill="#fff" className="bi bi-chevron-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg>
  );
};

export default BackArrow;
