import React from 'react'
import styled, { css } from 'styled-components';
import { Buttons, Props } from "../../Atoms/Button/Button";
import { VoteResultProps } from 'common/models/Vote';
import { Coin } from 'common/models/Coin';
import bull from '../../../assets/svg/bull.svg';
import bear from '../../../assets/svg/bear.svg';
import Bull from 'Components/icons/Bull';
import Bear from 'Components/icons/Bear';


export const VS = styled.div`
  flex-grow: 0;
  flex-basis: 10px;
  min-width: 0;
  color: red;
  left: 0;
  font-family: var(--font-family-poppins);
  font-weight: 700;
  color: var(--blue-violet);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  line-height: 0px;
  white-space: nowrap;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 40px;
    left: 9px;
    width: 0;
    height: 30px;
    border: 1px solid var(--blue-violet);
    opacity: 1;
  }

  &::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 0;
    border: 1px solid var(--blue-violet);
    opacity: 1;
    bottom: flex-end;
    top: 40px;
    bottom: -2px;
    left: 6px;
  }
`;

const size = css`
  width: 15px;
  height: 40px;
`;

const Option = css`
  border: 1px solid var(--blue-violet);
//   flex-basis: 0;
  // box-shadow: 0 3px 6px #00000029;
  // box-shadow: rgb(67 47 229) 0px 4px 1px, rgba(0,0,0,0.22) 0px 6px 12px;
  // transition: all .2s ease;

  & svg g path {    
  }
`;

// &:hover {
//     background: #6352e8;
//     color: white;
//     box - shadow: rgb(67 47 229) 0px 4px 1px, rgb(170 164 220) 0px 8px 6px;
// }
//   &: hover.svg - path {
//     stroke: white!important;
// }

const Option0 = styled(Buttons.RadiusTopRight)`
  ${size};
  ${Option};
  flex-direction: column;
//   &:not([disabled]) {
//     animation: bull_shake_left 2s ease 2s infinite;
//   }

  `;


const Option1 = styled(Buttons.RadiusBottomLeft)`
  ${size};
  ${Option};
  flex-direction: column;
//   &:not([disabled]) {
//     animation: bull_shake_right 2s ease 2s infinite ;
//   } 
`;
function BullBearButton({
    pairCoinResult,
    vote,
    coins,
    symbol1,
    symbol2
}: {
    pairCoinResult?: any,
    vote?: VoteResultProps;
    coins?: { [symbol: string]: Coin };
    symbol1: string;
    symbol2: string;
}) {  

  return (
      <div className='w-100 d-flex justify-content-between'>
          <Option0
              style={{
              width: '40%',
              }}
              className={vote?.direction == 0 ? "DarkCoin" :""}
          >                    

              {symbol2 == undefined?                  
                  <Bull /> : <>                      
                      <p>{symbol1}</p>
                  </>
              }              
          </Option0>
          <div className="vs">
              <div className='vs3'>VS</div>
              <div className='vs2'></div>
              <div className='vs1'></div>
          </div>
          <Option1
              style={{
                  width: '40%',
              }}
              className={vote?.direction == 1?"DarkCoin":""}
          >
              {symbol2 == undefined ?
                    <Bear /> : <>                    
            <p>{symbol2 == "MATIC" ? "POL": symbol2}</p>
                  </>
              }           
                </Option1>
    </div>
  )
}

export default BullBearButton