import React from 'react'

function Gallerynew() {
  return (
  <svg width="29" height="26" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1847_23365)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.09256 3.35959H19.493L25.4885 3.35712C25.986 3.35712 26.4843 3.35849 26.9832 3.35986C27.9826 3.36262 28.9843 3.36538 29.986 3.35712C30.3565 3.35672 30.7137 3.2202 30.9883 2.97401C31.2629 2.72781 31.4354 2.38949 31.4724 2.0246C31.5093 1.65971 31.408 1.29425 31.1882 0.999041C30.9684 0.703832 30.6457 0.499902 30.2826 0.426777C30.0822 0.396555 29.8793 0.386183 29.6769 0.395814H9.29904C9.15846 0.390438 9.01768 0.392505 8.87732 0.402007C8.59431 0.419297 8.32262 0.518238 8.09578 0.686614C7.86895 0.854989 7.6969 1.08543 7.60089 1.34948C7.20169 2.34896 7.94627 3.35959 9.09256 3.35959ZM19.4905 4.84344H32.8418C34.0068 4.84344 34.7614 5.80329 34.3922 6.81268C34.1745 7.40593 33.729 7.73043 33.0983 7.8035C32.9584 7.81402 32.818 7.8165 32.6778 7.81093H6.30198C6.08403 7.81938 5.8658 7.80401 5.65125 7.76511C5.29735 7.6841 4.98548 7.47807 4.77414 7.18567C4.56281 6.89327 4.46655 6.5346 4.5034 6.17696C4.54026 5.81931 4.70771 5.48726 4.97434 5.24311C5.24097 4.99897 5.58845 4.85949 5.95159 4.85087C6.46197 4.83679 6.97234 4.83959 7.48272 4.84239C7.69065 4.84353 7.89858 4.84468 8.1065 4.84468L19.4905 4.84344ZM23.8731 9.30325H23.8731C27.3944 9.31326 30.9158 9.32327 34.4359 9.27512C37.0639 9.23921 39.0236 11.1973 38.9998 13.7685C38.9593 17.9268 38.9671 22.0858 38.9748 26.2449C38.9781 28.0045 38.9814 29.7642 38.981 31.5239C38.981 34.2486 37.2203 35.9987 34.4835 35.9987H4.35717C2.10465 35.9987 0.402735 34.5806 0.0448334 32.407C0.0099508 32.1471 -0.00427499 31.885 0.00228614 31.623V13.6805C0.00228614 11.0673 1.79805 9.30113 4.45228 9.29989L19.4904 9.29494C20.9512 9.29494 22.4121 9.29909 23.8731 9.30325Z" fill="url(#paint0_linear_1847_23365)"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_1847_23365" x1="149.491" y1="167.475" x2="-45.6425" y2="77.811" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6318FF"/>
      <stop offset="1" stop-color="#FFF6FF"/>
    </linearGradient>
    <clipPath id="clip0_1847_23365">
      <rect width="39" height="35.6087" fill="white" transform="translate(0 0.390625)"/>
    </clipPath>
  </defs>
</svg>
  )
}

export default Gallerynew